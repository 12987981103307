import React from 'react'
import './CSS/resetpasswordnewpassword.css' 
import { Link,useLocation, useNavigate } from 'react-router-dom'
import { Formik, Form } from 'formik'
import * as YUP from 'yup' 
import PasswordInput from '../components/PasswordInput' 
import SEO from '../components/SEO'  
import { forgetPassword } from '../security/AuthService' 

const ResetPasswordNewPassword = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { email } = location.state || {};
  return (
    <>
        <SEO title={'PayTaps - Your Crypto Payment Solution'} 
          description={'PayTaps Crypto MasterCard: Free, secure virtual card. Works with Apple Pay, Samsung Pay, and Google Pay. Spend crypto as fiat globally, hassle-free.'} 
          ogTitle={'PayTaps Crypto MasterCard – Spend Crypto With A Simple Tap'} 
          ogDescription={'PayTaps Crypto MasterCard: Free, secure virtual card. Works with Apple Pay, Samsung Pay, and Google Pay. Spend crypto as fiat globally, hassle-free.'} 
          ogUrl={'https://paytaps.com'}  
          twitterTitle={'PayTaps Crypto MasterCard – Spend Crypto With A Simple Tap'} 
          twitterDescription={'PayTaps Crypto MasterCard: Free, secure virtual card. Works with Apple Pay, Samsung Pay, and Google Pay. Spend crypto as fiat globally, hassle-free.'} 
          canonicalLink={'https://paytaps.com'}    
        /> 
        <section className='newPasswordContainer'>             
            <div className='resetPasswordEmailWrapper'>
                <div className='resetPasswordEmailContent'>
                    <Link to='/'>
                        <img src='/images/registration/Group 1.svg' alt='paytabs logo' draggable='false' className='paytapsLogo' />
                    </Link>
                    <div className='resetPassword'>New password
                    </div>
                    <div className='resetPasswordEnterCodeSentEmail'>Set your new password</div> 
                    <Formik
                        initialValues={{
                            password: '',
                            confirm_password: '' 
                        }}

                        validationSchema={YUP.object({
                            password: YUP.string().required('Required!').min(5, 'Password must contain at least 5 characters')
                            .matches(/[A-Z]+/, { message: <span>Password must contain at least 1 uppercase character</span>})
                            .matches(/[a-z]+/, { message: <span>Password must contain at least 1 lowercase character </span>})
                            .matches(/[0-9]+/, { message: <span>Password must contain at least 1 number </span>}),
                        confirm_password: YUP.string().required('Required!').oneOf([YUP.ref('password'), ''], 'Please enter the correct password again')
                        })}

                        onSubmit={ async (values, {setSubmitting, resetForm})=>{
                            try {
                                let result = await forgetPassword({password: values.password,email: email})  
                                const reponse = await result.json()

                                if(result.ok && reponse.message === 'Successfully') {
                                    navigate('/login')
                                }
                                setSubmitting(false)
                                resetForm()
                            } catch(err) {

                            }
                        }}
                    > 
                        {(formik) => {
                        
                        return (

                            <Form className='form' onSubmit={formik.handleSubmit}>

                                <PasswordInput name='password' id='password' label='Password' passwordName='password' />
                                <PasswordInput name='confirm_password' id='confirmpassword' label='Confirm Password'  />

                                <button type='submit' id='updatepassword' disabled={!(formik.dirty && formik.isValid) || formik.isSubmitting}>Update</button> 

                            </Form>
                        )}}
                    </Formik>

                    <div className='already-have-account' style={{textAlign: 'center'}}>
                        Remember It? 
                        <Link to='/login' style={{
                            fontWeight: '700',
                            color: '#0E0E0E',
                            textDecoration: 'underline'  
                        }}>  Sign in here </Link>
                    </div>

                </div>
            </div>
        </section>
    </>
  )
}

export default ResetPasswordNewPassword 