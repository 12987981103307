import React, { createContext, useCallback, useContext, useReducer } from "react";
import { MenuList } from "../jsx/layouts/nav/Menu";
import { useToggle } from "./ToggleContext";

export const ActiveContext = createContext(null);

  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1]  

  function initialActiveLink () {

    // console.log(MenuList.flatMap((item)=>{return item?.content ? item.content : item })) 
    const newList =  MenuList.flatMap((item)=>{return item?.content ? item.content : item }) 
    let index_active_link = newList.findIndex(menulink=> (menulink.to==='/'+path)) 
    if(index_active_link === -1) {
      if(path === 'ordercard') {
        // return 'My Card' 
        const decoded_payload = JSON.parse(atob(localStorage.getItem('u_t_p')))
        if(decoded_payload['req'] === 'S') {
          return 'My Card'
        } else {
          return ''
        } 
      } else if(path === 'referredusers') {
        return 'Referral'  
      } else if(path === 'getstarted') {
        return 'Dashboard' 
      }
    } else {
      // console.log(MenuList)
      // console.log(newList[index_active_link].title) 
      // MenuList.findIndex(function(item) {
      //   if(item.title === newList[index_active_link].title) {
      //     return newList[index_active_link].title
      //   } else  {
      //     // const fetchParentIndex = item?.content.findIndex((subItem)=> subItem.title === newList[index_active_link].title)
      //     // console.log(fetchParentIndex) 
      //     // console.log(item?.content?.findIndex((subItem)=> subItem.title === newList[index_active_link].title)) 
      //     const i = item?.content?.findIndex((subItem)=> subItem.title === newList[index_active_link].title) 
      //     console.log(i) 
      //     if(i >= 0) {
      //       console.log(item?.content[i], i, item?.content[i]['id'])   
      //       console.log(document?.querySelectorAll('a[hiddn'))     
      //     } 
      //   }
      // } ) 
      return newList[index_active_link].title
    }
  } 

 const reducer = (previousState, updatedState) => ({
    ...previousState,
    ...updatedState,
  });

 const initialState = {
    active : initialActiveLink(),
    activeSubmenu : "",
    activeParent: ''
  }

const ActiveContextProvider = (props) => {
  const { toggleHandler } = useToggle()
    const [state, setState] = useReducer(reducer, initialState);

    const handleMenuActive = useCallback((status) => {
      setState({...state, active : status})  
      if(window.innerWidth < 1024) {
        toggleHandler()
      }
    }, [toggleHandler])    

    const handleSubmenuActive = (status) => {		
      setState({activeSubmenu : status})
      if(state.activeSubmenu === status){
        setState({activeSubmenu : ""})			
      }    
    }

    const handleParentActive = (status) => {		
      setState({...state, activeParent: status})    
    }
    const handleMenuParentActive = (activeMenu, activeParent) =>{
      setState({...state, active: activeMenu, activeParent: activeParent}) 
      if(window.innerWidth < 1024) {
        toggleHandler()
      }
    }

     return (
        <ActiveContext.Provider
          value={{
            state,
            setState,
            handleMenuActive,
            handleParentActive,
            handleMenuParentActive
        }}
        >
          {props.children}
        </ActiveContext.Provider>
     )
}

export default ActiveContextProvider

export function useActive () {
    const {state, setState, handleMenuActive, handleParentActive, handleMenuParentActive} = useContext(ActiveContext)
    return {state, setState, handleMenuActive, handleParentActive, handleMenuParentActive}
}







