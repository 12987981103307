import React  from 'react';
// import {connect, useDispatch } from 'react-redux';
import {  useLocation, useNavigate, useParams } from 'react-router-dom';
import { killToken, logout, refreshToken, saveToken  } from '../../../security/AuthService';
import { useToggle } from '../../../context/ToggleContext'; 
// import { Logout } from '../../../store/actions/AuthActions';
// import { isAuthenticated } from '../../../store/selectors/AuthSelectors';

// function withRouter(Component) {
//     function ComponentWithRouterProp(props) {
//       let location = useLocation();
//       let navigate = useNavigate();
//       let params = useParams();
//       return (
//         <Component
//           {...props}
//           router={{ location, navigate, params }}
//         />
//       );
//     }
  
//     return ComponentWithRouterProp;
// }

function LogoutPage(props){
    // const dispatch = useDispatch();
    const navigate = useNavigate();
    // function onLogout() {
    //    dispatch(Logout(navigate));
    const {initToggle} = useToggle()
    async function onLogout () {
      try {
        let response = await logout()
        // If fetch returns 401 
        if(response.status === 401) {
          const refreshTokenResult = await refreshToken({refresh_token: localStorage.getItem('u_r_t')})

          // If refresh token returns 401
          if(refreshTokenResult.status === 401) {
            killToken()
            return navigate('/login')
          } 

          // If refresh token is successfull 
          const payloadRefreshToken = await refreshTokenResult.json()   

          let tokenPayload = payloadRefreshToken.token.split('.')[1] 

          // Payload of new generated token 
          let new_decoded_payload = JSON.parse(atob(tokenPayload)) 

          if(new_decoded_payload['exp']*1000 < new Date().valueOf())  {
            killToken()
            return navigate('/login') 
          } else {
            saveToken(payloadRefreshToken.token, payloadRefreshToken.refresh_token)
            response = await logout() 
          }

        }
        const messageLogout = await response.json()
        if(response.ok && messageLogout.message === 'Successfully logged out.') {
          if(killToken().kill) {
            navigate('/login')
            initToggle()
          }
        }
      } catch (err) {
        console.log(err)
      }
      
    }
    
    return(
        <>
            <button  className="dropdown-item ai-icon" onClick={onLogout}>
                <svg
                  id="icon-logout" xmlns="http://www.w3.org/2000/svg"
                  className="text-danger" width={18} height={18} viewBox="0 0 24 24" 
                  fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round"
                >
                    <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4" />
                    <polyline points="16 17 21 12 16 7" />
                    <line x1={21} y1={12} x2={9} y2={12} />
                </svg>
                <span className="ms-2" >Logout </span>
            </button>
        </>
    )
} 
// const mapStateToProps = (state) => {
//     return {
//         isAuthenticated: isAuthenticated(state),
//     };
// };

// export default withRouter(connect(mapStateToProps)(LogoutPage));
export default LogoutPage