export const MenuList = [
    {
        title: 'Order Card',
        to: '/ordercard',
        active_icon: '/images/dashboard/sidebar/dashboard_a.svg',
        normal_icon: '/images/dashboard/sidebar/dashboard_na.svg',
        id: 'ordercard' 					
    },
    // {
    //     title: 'Wallet',
    //     normal_icon: '/images/dashboard/sidebar/wallet_na.svg',                                                         
    //     active_icon_blue: '/images/dashboard/sidebar/wallet_ab.svg',
    //     active_icon_white: '/images/dashboard/sidebar/wallet_aw.svg',
    //     id: 'walletSec',
    //     content: [
    //         {
    //             title: 'Deposit Fund',
    //             to: '/depositfund',
    //             active_icon: '/images/dashboard/sidebar/depositfund_a.svg',
    //             normal_icon: '/images/dashboard/sidebar/depositfund_na.svg', 
    //             id:'depositFund'					
    //         },
    //         {
    //             title: 'Transactions History',
    //             to: '/transactionshistory',
    //             active_icon: '/images/dashboard/sidebar/transactionshistory_a.svg',
    //             normal_icon: '/images/dashboard/sidebar/transactionshistory_na.svg',
    //             id:'transactions_history'					
    //         },

    //     ]
    // },
    {
        title: 'Card',
        normal_icon: '/images/dashboard/sidebar/cardv2_na.svg',                                   
        active_icon_blue: '/images/dashboard/sidebar/cardv2_ab.svg',
        active_icon_white: '/images/dashboard/sidebar/cardv2_aw.svg',
        id: 'cardSec',
        content: [
            {
                title: 'My Card',
                to: '/mycard',	
                active_icon: '/images/dashboard/sidebar/card_a.svg',
                normal_icon: '/images/dashboard/sidebar/card_na.svg',
                id: 'card'				
            }, 
            {
                title: 'Top-up Card', 
                to: '/topupcard',	
                active_icon: '/images/dashboard/sidebar/topup_a.svg',
                normal_icon: '/images/dashboard/sidebar/topup_na.svg',
                id: 'topupCard' 				
            },

        ]
    },
    {
        title: 'Referral',
        to: '/welcomereferral',
        active_icon: '/images/dashboard/sidebar/referral_a.svg',
        normal_icon: '/images/dashboard/sidebar/referral_na.svg',
        id:'welcomeReferral' 					
    },
    {
        title: 'Support and Ressources',
        normal_icon: '/images/dashboard/sidebar/support_na.svg',
        active_icon_blue: '/images/dashboard/sidebar/support_ab.svg',
        active_icon_white: '/images/dashboard/sidebar/support_aw.svg',
        id: 'supportRessourcesSec',
        content: [
            {
                title: 'Profile',
                to: '/profile',
                active_icon: '/images/dashboard/sidebar/tabler-user-circle_a.svg',
                normal_icon: '/images/dashboard/sidebar/tabler-user-circle_na.svg',
                id:'profile'					
            },
            {
                title: 'Google Authentication',
                to: '/bindgoogleauthentication', 
                active_icon: '/images/dashboard/sidebar/tabler_brand-google_a.svg',
                normal_icon: '/images/dashboard/sidebar/tabler_brand-google_na.svg',
                id:'googleAuthentication'	 				
            },         
            {
                title: 'Customer Support',
                to: '/contactus',
                active_icon: '/images/dashboard/sidebar/customersupport_a.svg',
                normal_icon: '/images/dashboard/sidebar/customersupport_na.svg',
                id:'contactUs'					
            },
            {
                title: 'User Guide',
                to: '/userguide',
                active_icon: '/images/dashboard/sidebar/userguide_a.svg',
                normal_icon: '/images/dashboard/sidebar/userguide_na.svg',
                id:'user_guide'					
            },

        ]
    },
]







