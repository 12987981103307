import React from 'react'
import { Link } from 'react-router-dom'
import './CSS/experience.css'
import SignUpDashboard from '../../../components/SignUpDashboard' 
const Experience = () => {
  return (
    <section id='experience'>
        <div className='experience-container'>
            <div className='experience-wrapper'>
                <div className='content-wrapper'>
                  <div className='ready-to-experience'>Ready to Experience Financial Freedom?</div>
                  <p className='get-your-paytabs'>Get your PAYTAPS Mastercard Card today and start managing your  finances with ease and security.</p>
                  <SignUpDashboard classStyles='sign-up' linkLabel='Sign Up Now!' />          
                </div>
            </div>
        </div>
    </section>
  )
}

export default Experience