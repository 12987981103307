import React from 'react'

const BalanceDataDetail = ({imagesrc, label, value, altText}) => {
  return (
    <div className={`balance-detail-item ${(label==='Wallet balance' || label === 'CIX balance') ? 'bg-F4F2FE' : '' }`}> 
        <figure className={`rounded-icon-wrapper ${label==='Wallet balance' ? 'm2' : ''}`}>
            <img src={imagesrc} alt={altText} draggable='false' style={label === 'CIX balance' ? {width: '22px'} : {}} /> 
        </figure>
        <div>
            <span>{label}</span>
            <span>{value}</span>
        </div>
    </div>
  )
}

export default BalanceDataDetail