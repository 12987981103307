// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.allTransactionsHistory .transactions-wrapper-body {
    height: 460px; 
}

 `, "",{"version":3,"sources":["webpack://./src/dashboard/CSS/transactionshistory.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB","sourcesContent":[".allTransactionsHistory .transactions-wrapper-body {\n    height: 460px; \n}\n\n "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
