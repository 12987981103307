import React, { useEffect } from 'react'
import { ErrorMessage, Field } from 'formik'
import ErrorInput from './ErrorInput'
import './CSS/dateinput.css'
import 'react-datepicker/dist/react-datepicker.css'

import 'react-datepicker/dist/react-datepicker.css'


const DateInput = ({label, id, name}) => {
  useEffect(()=>{
    document.getElementById('dateofbirth').style.color = '#7e7e7e'
    document.getElementById('dateofbirth').style.opacity = '0.8'
    document.getElementById('dateofbirth').addEventListener('change', ()=>{
      if(document.getElementById('dateofbirth').value === '') {
        document.getElementById('dateofbirth').style.color = '#7e7e7e'
        document.getElementById('dateofbirth').style.opacity = '0.8'
      } else {
        document.getElementById('dateofbirth').style.color = '#000000'
        document.getElementById('dateofbirth').style.opacity = '1'
      }
    })
    if(document.getElementById('dateofbirth').value !== '') {
      document.getElementById('dateofbirth').style.color = '#000000'
      document.getElementById('dateofbirth').style.opacity = '1'
    }
  }, [])
  return (
    <div className='form_control'>
      <label htmlFor={id} className='form_label'>{label}</label>
      <Field name={name}  >
             {({
               field
             }) => (
               <div style={{marginBottom: '20px'}}>
                 <input type='date' placeholder="Hello" {...field} id={id} className='inputForm' />  
               </div> )} 
           </Field>
           <ErrorMessage  name={name} component={ErrorInput} />
    </div>
  )
}

export default DateInput



