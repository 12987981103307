import React from 'react'
import { Modal } from 'react-bootstrap'
import Carousel from 'react-bootstrap/Carousel';                           

const DepositWithCiex = ({show, handleCloseInstruction, id}) => {
  return (
    <Modal show={show} onHide={handleCloseInstruction} className='showCiexAccount' id={id}>  
        <Modal.Header closeButton>
        <Modal.Title>CIEx account</Modal.Title>
        <img src='/images/dashboard/userguide/Frame 20.svg' alt='6 screenshots showing steps to withdraw crypto on ciex app' />   
        <Carousel fade interval={null} id='withdrawSteps'>
            <Carousel.Item>
            <img src='/images/dashboard/userguide/1.svg' alt='an interface with 5 menus, with a blue line under overview, deposit button and a rectangle with yellow borders around withdraw button ' />
            </Carousel.Item>
            <Carousel.Item>
            <img src='/images/dashboard/userguide/2.svg' alt="an interface with 5 menus, with a blue line under overview, total balance and today\'s PNL values are hidden with asterisks and a rectangle with yellow borders around USDT " />  

            </Carousel.Item>
            <Carousel.Item>
            <img src='/images/dashboard/userguide/3.svg' alt='an interface with 5 menus, with a blue line under overview, at the bottom of it; an area with title withdraw and cancel button and a rectangle with yellow borders around the rectangle with title send via crypto network, below it a rectangle with title send via email/phone/UID' /> 

            </Carousel.Item> 
            <Carousel.Item>
            <img src='/images/dashboard/userguide/4.svg' alt='an interface with main title withdraw, containing a form, into it a label of chain name underneath of it erc-20, trc-20 surrounded by a rectangle with yellow borders , bep-20 and with other fields; address, amount, ... below it other details about the withdraw and a confirm button' />
            </Carousel.Item>
            <Carousel.Item>
            <img src='/images/dashboard/userguide/5.svg' alt='an interface with main title withdraw, containing a form, into it a label of chain name underneath of it erc-20, trc-20, bep-20, a filled adrress field below it a rectangle with yellow borders containing UID:32235592, below it other details about the withdraw and a confirm button' />

            </Carousel.Item>
            <Carousel.Item>
            <img src='/images/dashboard/userguide/6.svg' alt='an interface with main title withdraw, containing a form, within it a field with label send mode and value UID, below it a field with label UID and value 32235592 surrounded by a rectangle with yellow borders, below this field a message starting with this is the UID corresponding to the address..., and in the bottom a confirm button surrounded by a recatngle with yellow borders' /> 

            </Carousel.Item>
        </Carousel> 
        </Modal.Header>
    </Modal>
  )
}

export default DepositWithCiex
