import React from 'react'
import './lpfooter.css'

const LpFooter = () => {
  return (
    <footer>
      <div className='footer-wrapper'>
        <figure>
          <img src='/images/landingpage/footer/Layer 3.svg' alt='paytabs' draggable='false' />
        </figure>
        <div className='footer-row-1'>
          <div className='footer-row-1-part-1'>
            <div className='quicklinks'>Quicklinks</div>
            <ul className='footer-row-1-part-1-list'>
              <li><a href='/'>Home</a></li>
              <li><a href='/'>Services</a></li>
              <li><a href='/'>Career</a></li>
              <li><a href='/'>About</a></li>
              <li><a href='/'>Blog</a></li>
            </ul>
          </div>
          <div className='footer-row-1-part-2'>
            <div className='contact-info'>Contact Info</div>
            <div className='email-phone-container'>
              <a href='mailto:customercare@paytaps.com' id='email_address'>Email: customercare@paytaps.com</a>  
              <a href='tel:+37052140176' id='phone_number'>Phone: +370 5 214 0176</a>  
            </div>
          </div>
        </div>
        <div className='footer-row-2'>
          <figure>
            <a href='/'><img src='/images/landingpage/footer/Layer 1.svg' alt='paytabs mini logo' draggable='false' /></a>
          </figure>
          <ul>
            <li>
              <a href='https://www.facebook.com/people/Paytaps/61565936985315/'><img src='/images/landingpage/footer/637654835a18de974a8d4441_ic_facebook.svg.svg' alt='facebook logo' draggable='false' /></a></li>
            <li>
              <a href='https://www.instagram.com/paytaps/'><img src='/images/landingpage/footer/63765483303970c874ba3961_ic_instagram.svg.svg' alt='instagram logo' draggable='false' /></a>
            </li>
            <li>
              <a href='http://x.com/Paytaps14305'><img src='/images/landingpage/footer/Link.svg' alt='x logo' draggable='false' /></a>  
            </li>
          </ul>
        </div>
        <hr />
        <div className='footer-row-3'>
          <div className='footer-row-3-content'>Paytraps. © 2024  All Rights Reserved.</div>
          <ul className='legal-privacy-policy-terms-of-use'>
            <li><a href='/' className='footer-row-3-content'>Legal</a></li>
            <li><a href='/' className='footer-row-3-content'>Privacy Policy</a></li>
            <li><a href='/' className='footer-row-3-content'>Terms of Use</a></li>
          </ul>
        </div>
      </div>
    </footer>
  )
}

export default LpFooter