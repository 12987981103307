import React, {useState} from 'react'
import { Link,useLocation, useNavigate  } from 'react-router-dom'
import { Formik, Form } from 'formik'
import * as YUP from 'yup' 
import OtpInput from '../components/OtpInput'   
import SEO from '../components/SEO' 
import { verifyOTPForgetPassword, generateOTPForgetPassword } from '../security/AuthService' 
import './CSS/resetpasswordverification.css'   

const ResetPasswordOTP = () => {
    const [otp, setOtp] = useState('');
    const [verificationError, setVerificationError] = useState(null)
    const navigate = useNavigate();
    const location = useLocation();
    const { email, loginType } = location.state || {};

    const handleOtpChange = (value) => {
        setOtp(value);
    };

    async function sendOtpAgain () {  
      try {
        setVerificationError('') 
        const resend_otp = await generateOTPForgetPassword({email: email}) 
        const resend_otp_result = await resend_otp.json() 
      } catch(err) {

      }
    } 
     
  return (
    <>
      <SEO title={'PayTaps - Your Crypto Payment Solution'} 
          description={'PayTaps Crypto MasterCard: Free, secure virtual card. Works with Apple Pay, Samsung Pay, and Google Pay. Spend crypto as fiat globally, hassle-free.'} 
          ogTitle={'PayTaps Crypto MasterCard – Spend Crypto With A Simple Tap'} 
          ogDescription={'PayTaps Crypto MasterCard: Free, secure virtual card. Works with Apple Pay, Samsung Pay, and Google Pay. Spend crypto as fiat globally, hassle-free.'} 
          ogUrl={'https://paytaps.com'}  
          twitterTitle={'PayTaps Crypto MasterCard – Spend Crypto With A Simple Tap'} 
          twitterDescription={'PayTaps Crypto MasterCard: Free, secure virtual card. Works with Apple Pay, Samsung Pay, and Google Pay. Spend crypto as fiat globally, hassle-free.'} 
          canonicalLink={'https://paytaps.com'}    
      />
      <section className='resetPasswordOTPContainer'> 
        <div className='resetPasswordEmailWrapper'>
          <div className='resetPasswordEmailContent pt-20 pb-65'>
              <Link to='/'>
                  <img src='/images/registration/Group 1.svg' alt='paytabs logo' draggable='false' className='paytapsLogo' />
              </Link>
              <div className='resetPassword'>{ loginType === 'simple' ? 'Enter your code ' : 'Enter Google Authenticator Code'}  </div> 
              <div className='resetPasswordEnterCodeSentEmail' style={(loginType === 'google') ? {marginTop: '11px'} : {}}>{loginType === 'simple' ? 'Please enter your OTP code here' : 'Please enter the 6-digit code from Google Authenticator application to proceed.'}</div>  

              <Formik
                initialValues={{
                    otp: '',
                }} 

                validationSchema={YUP.object({
                    otp: YUP.string().required('Required!'),
                })}

                onSubmit={ async (values, {setSubmitting, resetForm})=>{
                  try {
                    let result = await verifyOTPForgetPassword({otp: values.otp,email: email}) 
                    const reponse = await result.json()
                    
                    if(result.ok && (reponse.message === 'OTP is valid' || reponse.message === 'code is valid')) {                           
                      navigate('/newpassword', { state: { email: email } })
                    } 
                    if(!result.ok && (reponse.message === 'OTP has expired' || reponse.message === 'Invalid OTP' || reponse.message === 'Invalid code')) { 
                      setVerificationError(reponse.message) 
                    } 
                      
                    setSubmitting(false)
                    resetForm() 
                  } catch(err) {

                  } 
                }}
              >
                            
                {(formik) => (
                <Form className='form' onSubmit={formik.handleSubmit}>
                  <OtpInput length={6} 
                      onChange={(otp) => formik.setFieldValue('otp', otp)} 
                  />

                  {verificationError && <div className='forgetPasswordOtpError'>{verificationError}</div> }
                  <button
                    type='submit'
                    id='verifyNowOTP'
                    className={`${verificationError ? 'mt-0' : 'mt-50'}`} 
                    disabled={!(formik.dirty && formik.isValid) || formik.isSubmitting || formik.values.otp.length !== 6}  
                  > 
                    Verify Now
                  </button> 
                </Form>  
            )}
              </Formik>

              <div className='signInHereSendOtpAgainWrapper' style={(loginType === 'google') ? {justifyContent: 'center'}: {}}> 
                  <div className='already-have-account'>
                      Remember It? 
                      <Link to='/login' style={{
                          fontWeight: '700',
                          color: '#0E0E0E',
                          textDecoration: 'underline', 
                          fontSize: '13px'
                      }}>  Sign in here </Link>
                  </div>
                  {loginType === 'simple' && <span className='sendOtpAgain' onClick={sendOtpAgain}>Send OTP again </span>}   
              </div>

          </div>
        </div>
      </section> 
    </>
  )
}

export default ResetPasswordOTP
