import React from 'react'
import './CSS/bindgoogleauthentication.css' 
import Button from './Button' 
import { useNavigate } from 'react-router-dom' 
import { useActive } from '../context/ActiveContext' 
const BindGoogleAuthentication = () => {
  const navigate = useNavigate()
  const { handleMenuActive, handleMenuParentActive } = useActive()  
  function redirectToGoogleAuthentication () {
    handleMenuParentActive('Google Authentication', 'Support and Ressources') 
    navigate('/bindgoogleauthentication')  
  }
  return (
    <div className='bindGoogleAuthenticationContainer'>
      <div className='bindGoogleAuthenticationInnerContainer'>
        <img src='/images/dashboard/2fa/tabler_brand-google.svg' alt='a blue google icon' draggable='false' /> 
        <div className='d-flex flex-column'> 
          <div className='bindGoogleAuthenticationTitle'>Google Authenticator</div>
          <p className='bindGoogleAuthenticationDescription'>Enhance your account security by enabling Google Authenticator.</p> 
        </div>
      </div>
      <Button click={redirectToGoogleAuthentication}>Bind</Button>
    </div>
  )
}

export default BindGoogleAuthentication 
 

  