import React, { useCallback, useState } from 'react'
import { Formik, Form } from 'formik'  
import * as YUP from 'yup' 
import TextInput from '../components/TextInput' 
import PasswordInput from '../components/PasswordInput'
import './CSS/resetpasswordpage.css' 
import CurrentPassword from '../components/CurrentPassword' 
import ConfirmPasswordChange from '../Modals/ConfirmPasswordChange' 
import { updatePassword, refreshToken, killToken, saveToken } from '../security/AuthService'  
import ConfirmPasswordError from '../Modals/ConfirmPasswordError'
import { useNavigate } from 'react-router-dom' 
const ResetPasswordPage = () => {
    const [show, setShow] = useState(false) 
    const [showError, setShowError] = useState(false) 
    const [payload, setPayload] = useState(null) 
    const handleShow = useCallback(function() { 
        setShow(true)
    }, []) 
    const handleClose = useCallback(function() {
        setShow(false) 
    }, []) 
    const handleShowError = useCallback(function() { 
        setShowError(true)
    }, []) 
    const handleCloseError = useCallback(function() {
        setShowError(false)  
    }, [])  
    const navigate = useNavigate() 
    const submitUpdatePassword = useCallback(async function() {
        
        try {
            let update_password = await updatePassword({
                "password": payload.currentpassword, 
                "newPassword": payload.newpassword  
            })
            // If fetch returns 401 
            if(update_password.status === 401) {
                const refreshTokenResult = await refreshToken({refresh_token: localStorage.getItem('u_r_t')})

                // If refresh token returns 401
                if(refreshTokenResult.status === 401) {
                killToken()
                return navigate('/login')
                } 

                // If refresh token is successfull 
                const payloadRefreshToken = await refreshTokenResult.json()   

                let tokenPayload = payloadRefreshToken.token.split('.')[1] 

                // Payload of new generated token 
                let new_decoded_payload = JSON.parse(atob(tokenPayload)) 

                if(new_decoded_payload['exp']*1000 < new Date().valueOf())  {
                    killToken()
                    return navigate('/login') 
                } else {
                    saveToken(payloadRefreshToken.token, payloadRefreshToken.refresh_token)                                                       
                    update_password = await updatePassword({
                        "password": payload.currentpassword, 
                        "newPassword": payload.newpassword  
                    }) 

                }

            }
             
            if(update_password.ok) {
                navigate('/successresetpassword') 
                 
            } else {
                
                handleShowError() 
                handleClose()
            } 
        } catch(err) {

        }     
    }, [payload]) 
    let state
    const keyUpHandler = (e)=>{
        state.setFieldValue(e.target['name'], e.target.value); 
        state.setFieldTouched(e.target['name'])
    }
  return (
    <>
        <section className='loggedResetPasswordContainer'>
            <div className='resetPasswordFormWrapper'>
                <div className='resetPasswordTitle'>Reset password</div> 
                <Formik
                        initialValues={{
                        currentpassword: '',
                        newpassword: '',
                        confirmnewpassword: ''
                        }}

                        validationSchema={YUP.object({
                            currentpassword: YUP.string().required('Required!'), 
                            newpassword: YUP.string().required('Required!').min(5, 'Password must contain at least 5 characters')
                            .matches(/[A-Z]+/, { message: <span>Password must contain at least 1 uppercase character</span>})
                            .matches(/[a-z]+/, { message: <span>Password must contain at least 1 lowercase character </span>})
                            .matches(/[0-9]+/, { message: <span>Password must contain at least 1 number </span>}),
                            confirmnewpassword: YUP.string().required('Required!').oneOf([YUP.ref('newpassword'), ''], 'Please enter the correct new password again') 
                        })}

                        onSubmit={async (values, {setSubmitting, resetForm})=>{
                                //console.log(values)
                                setPayload(values) 
                                handleShow() 
                                // handleShowError() 
                        }}
                    >
                        {(formik) => {
                        state = formik
                        return (
                            <Form className='form' onSubmit={formik.handleSubmit}>
                                <CurrentPassword name='currentpassword' id='currentpassword' label='Current password'  error={formik.errors.currentpassword} touched={formik.touched.currentpassword} onKeyUp={keyUpHandler} /> 
                                <PasswordInput name='newpassword' id='newpassword' label='New password' passwordName='newpassword' error={formik.errors.newpassword} touched={formik.touched.newpassword} onKeyUp={keyUpHandler} />
                                <PasswordInput name='confirmnewpassword' id='confirmnewpassword' label='Confirm New password' passwordName='newpassword'  error={formik.errors.confirmnewpassword} touched={formik.touched.confirmnewpassword} onKeyUp={keyUpHandler} />
                                <button type='submit' id='updatePassword' disabled={!(formik.dirty && formik.isValid) || formik.isSubmitting}>Update</button> 
                            </Form>)}}
                </Formik>
            </div>
        </section>
        <ConfirmPasswordChange show={show} handleClose={handleClose} payload={payload} submitUpdatePassword={submitUpdatePassword} handleShowError={handleShowError} />  
        <ConfirmPasswordError show={showError} handleCloseError={handleCloseError} />    
    </>
  )
}

export default ResetPasswordPage 