import { useState, Fragment, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Collapse from 'react-bootstrap/Collapse';
import { Link } from 'react-router-dom'; 
import { useToggle } from '../../../context/ToggleContext';
import { useActive } from '../../../context/ActiveContext';
import './CSS/linkscollection.css'
import { useReferral } from '../../../context/ReferralContext'; 
import { useGoogleAuthentication } from '../../../context/GoogleAuthenticationContext';

function LinksCollection({data, title, normal_icon, active_icon_blue, active_icon_white, id}) {                                                                                          
  const [open, setOpen] = useState(false);
  const { toggle } = useToggle() 
  const { state, handleParentActive, handleMenuParentActive  } = useActive()
  const { showReferral } = useReferral() 
  const { hasEnabledGoogleAuthentication } = useGoogleAuthentication() 

  useEffect(()=>{
    if(fetchActive() === -1 || state.activeParent !== title) {
        setOpen(false)
    }
    if(state.activeParent === title) {
        setOpen(true) 
    }
    
  }, [state.active, state.activeParent])   

  
  function fetchActive () {
    return data.findIndex((item)=> item.title === state.active ) 
  }
      
                                                                                              

  return (
    <>
      <Button
        onClick={(e) => {
          e.stopPropagation();
          setOpen(!open); 
          handleParentActive(title)}
        } 
        aria-controls={`${title}`} 
        aria-expanded={open} 
        style={
          (fetchActive() !== -1 && !toggle && window.innerWidth >= 1024) || (fetchActive() !== -1 && window.innerWidth < 1024) ? 
          { background: '#5ca4f8'} : 
          {} 
        }
        id={id} 
        hidden={title === 'Card' && !showReferral} 
      >   
          <span 
            className={`link-logo-label-container ${toggle ? 'toggeled-icons-containers' : ''} has-arrow`} 
            style={ 
              (fetchActive() !== -1 && !toggle && window.innerWidth >= 1024) || (fetchActive() !== -1 && window.innerWidth < 1024) ? 
              { color: '#f7fbff'} : {}}
          > 
            {
              data[fetchActive()]?.title === 'Profile' && !showReferral ?
              <span style={{marginRight: '2px'}}><img src={normal_icon} /></span> :
              fetchActive() !== -1 && !toggle && window.innerWidth >= 1024 ||  fetchActive() !== -1 && window.innerWidth < 1024 ?   
              <span style={{marginRight: '2px'}}><img src={active_icon_white} /></span> :
              fetchActive() !== -1 && toggle && window.innerWidth >= 1024 ?
              <span style={{marginRight: '2px'}}><img src={active_icon_blue} /></span> :
              <span style={{marginRight: '2px'}}><img src={normal_icon} /></span> 
            } 
          <span className={`nav-text link-label ml-2 text-start`} >{title} </span>               
      </span>
      </Button> 

      {(title === 'Card' && !showReferral) ? null : <Collapse in={open}> 
        <ul className='linksCollectionContainer' id={`${title}`}>                                                                   
        {data?.map((dataItem, index)=>{
            return (
                <li className={` ${ state.active === dataItem.title ? 'mmm-active' : ''}`} key={index}> 
                  <Link        
                    to={dataItem.to}  
                    onClick={() => {handleMenuParentActive(dataItem.title, title)}}        
                    id={dataItem.id}  
                    hidden={
                      dataItem.title === 'Profile' && !showReferral ?
                      true : 
                      dataItem.title === 'Google Authentication' && hasEnabledGoogleAuthentication ?
                      true : 
                      false
                    }   
                  >   
                  <span className={`link-logo-label-container ${toggle ? 'toggeled-icons-containers' : ''}`}>
                      {
                      state.active === dataItem.title ? 
                      <span style={{marginRight: '2px'}}><img style={{width: '15px'}} src={dataItem.active_icon} /></span> : 
                      <span style={{marginRight: '2px'}}><img style={{width: '15px'}} src={dataItem.normal_icon} /></span> 

                      }
                      <span className={`nav-text link-label ${dataItem.title === 'Profile' ? 'ml-2' : ''}`} style={{fontSize: '16px'}} >{dataItem.title} </span> 
                  </span>
                  </Link>
                </li> 
            
            ) 
            
        })
        } 
        </ul>
      </Collapse>} 
    </>
  );
}

export default LinksCollection





















