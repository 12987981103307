import React, { useState, useEffect } from 'react'
import './CSS/inviteeslist.css' 
import Accordion from 'react-bootstrap/Accordion';
import Loader from '../jsx/pages/Loader/Loader';
import { getGlobalReferralDataActivities } from '../security/AuthService';
import moment from 'moment'     
import { refreshTokenWithAPICallHandler } from '../utils/refresh' 
import { useNavigate } from 'react-router-dom'

const InviteesList = () => {
    const navigate = useNavigate() 
    const [loadingState, setLoadingState] = useState(true)
    const [referralActivities, setReferralActivities] = useState({data: []})
    useEffect(()=>{
        try {
            getReferralActivities() 
        } catch(err) {

        } 
    }, []) 
    async function getReferralActivities () {
        let fetch_all_activities = {
            api_call: await getGlobalReferralDataActivities()
        }
        // If fetch returns 401
        if(fetch_all_activities.api_call.status === 401) {
            const renewGRA = async ()=>{
                return await getGlobalReferralDataActivities()
            }
            if(await refreshTokenWithAPICallHandler(fetch_all_activities, renewGRA) === 'REDIRECT_TO_LOGIN') {
                return navigate('/login')  
            }
        }
        const result_fetch_all_activities = await fetch_all_activities.api_call.json() 

        if(fetch_all_activities.api_call.status === 200 && result_fetch_all_activities['message'] === 'Success') {  
            setReferralActivities({...referralActivities, data: result_fetch_all_activities['data']}) 
            setLoadingState(false)  
        }
    }

  return (
    <>
        {!loadingState && <div className='inviteesListContainer'>
            <div className='inviteesListHeader'>
                <div className='inviteesListHeaderTitle'>Invitees List</div>
                <div className='inviteesListHeaderDescription'>Check out the friends you've invited to our community!</div>                                                           
            </div>
            {referralActivities.data.length > 0 && <ul className='inviteesListBody'>
                <div className='inviteesDataLabelsWrapper'> 
                    <figure className='referralStatusImage v-hidden'>                                                                                                                                                                                                   
                        <img src='/images/dashboard/referral/SVG (6).svg' alt='green arrow: credited' />   
                    </figure>
                    <div className='inviteesDataLabels'>
                        <span className='wd-flexible-12'>Invitee Email</span> 
                        <span className='wd-10'>Rewards Earned</span>
                        <span className='wd-12'>Invitation date</span>
                        <span className='wd-9'></span>
                    </div> 
                </div>    
                <div className='inviteesList'>
                    {
                        referralActivities.data.map((item, index)=>{
                            return (
                                <Accordion key={index}>
                                    <Accordion.Item eventKey="0"> 
                                        <Accordion.Header>
                                            <li  className='inviteeData'>                                                    
                                                <figure className='referralStatusImage'>
                                                    {   ((item['status'] === 'Success')   ?
                                                        <img src='/images/dashboard/referral/SVG (6).svg' alt='a circle with green border and inside of it an icon of a person with green color' draggable='false' /> :
                                                        (
                                                            item['status'] === 'Pending' ? 
                                                            <img src='/images/dashboard/referral/SVG (7).svg' alt='a cicrle with orange border and inside of it an icon of a person with orange color' draggable='false' /> : 
                                                            <img src='/images/dashboard/referral/SVG (10).svg' alt='a circle with red border and inside of it an icon of a person with red color' draggable='false' /> 
                                                        ))                               
                                                    }
                                                </figure>
                                                <div>
                                                    <span className='inviteeName wd-flexible-12'>{item['inviteEmail']}</span>  
                                                    <span className='rewardsAmount wd-10 d-none-small'>{`${item['rewardEarned'] === 0 ? '--' : '+$' + (item['rewardEarned'].toFixed(1))}`}</span> 
                                                    <div className='invitationDate wd-12 d-none-small'><span>{`${moment(item['dateInvite']['date']).format("MMM DD, YYYY")}`}</span><span>{`${moment(item['dateInvite']['date']).format("HH:mm")}`}</span></div> 
                                                    <span className={`referralStatus d-none-xsmall wd-9 ${item['status'] !== 'Success' ? (item['status'] === 'Pending' ? 'pendingColor' : 'declinedColor') : ''}`}>{item['status'] === 'Success' ? 'Completed' : item['status']}</span>                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            
                                                </div>
                                            </li>
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <div className={`referralStatus d-xsmall text-align-center ${item['status'] !== 'Success' ? (item['status'] === 'Pending' ? 'pendingColor' : 'declinedColor') : ''}`}>{item['status'] === 'Success' ? 'Completed' : item['status']}</div>                        
                                            <div className='referralDataResponsive'>
                                                <div className='d-flex flex-column'>
                                                    <span className='referralDataLabelsResponsive'>Rewards Earned</span>
                                                    <span className='rewardsAmount'>{`${item['rewardEarned'] === 0 ? '--' : '+$' + (item['rewardEarned'].toFixed(1))}`}</span>                                       
                                                </div>
                                                <div className='d-flex flex-column'>
                                                    <span className='referralDataLabelsResponsive'>Invitation date</span>
                                                    <span className='invitationDate wd-12'><span>{`${moment(item['dateInvite']['date']).format("MMM DD, YYYY")}`}</span><span>{`${moment(item['dateInvite']['date']).format("HH:mm")}`}</span></span>  
                                                </div>
                                            </div>
                                            <ul className='referralStagesWrapper'>
                                                {item['activeActivities']['activitySignup'] && <li className='referralStage'>
                                                    {item['activity']['activitySignup'] === true ? 
                                                    <img src='/images/dashboard/referral/tabler_circle-check-filled (1).svg' alt='a green circle inside of it a white checkmark' draggable='false' /> :
                                                    (item['activity']['activitySignup'] === false ? 
                                                        <img src='/images/dashboard/referral/tabler_circle-x-filled.svg' alt='a red circle inside of it a white cross mark' draggble='false' /> :
                                                        <img src='/images/dashboard/referral/tabler_exclamation-circle-filled.svg' alt='a grey circle inside it number 1' draggable='false' /> 
                                                    )
                                                    }
                                                    <span className='referralStageTextualContent'>
                                                        <span className='referralStageTitle'>Sign up with your link</span>
                                                        <span className='referralStageDescription'>Your friend clicks on your referral link or uses your referral code to sign up for their Paytaps account</span>
                                                    </span>
                                                </li>}
                                                {item['activeActivities']['activityDeposit'] && <li className='referralStage'>
                                                    {item['activity']['activityDeposit'] === true ? 
                                                    <img src='/images/dashboard/referral/tabler_circle-check-filled (1).svg' alt='a green circle inside of it a white checkmark' draggable='false' /> :
                                                    ((item['activity']['activityDeposit'] === false && item['status'] === 'Time Out') ? 
                                                        <img src='/images/dashboard/referral/tabler_circle-x-filled.svg' alt='a red circle inside of it a white cross mark' draggable='false' /> :
                                                        <img src='/images/dashboard/referral/tabler_exclamation-circle-filled (3).svg' alt='a grey circle inside it number 2' draggable='false'  />
                                                    )
                                                    }
                                                    <span className='referralStageTextualContent'>
                                                        <span className='referralStageTitle'>Deposit money to their account</span>
                                                        <span className='referralStageDescription'>Once they’ve signed up, your friend deposits money into their Paytaps account.</span>
                                                    </span>
                                                </li>} 
                                                {item['activeActivities']['activityCardCreation'] && <li className='referralStage'>
                                                    {item['activity']['activityCardCreation'] === true ? 
                                                    <img src='/images/dashboard/referral/tabler_circle-check-filled (1).svg' alt='a green circle inside of it a white checkmark' draggable='false' /> :
                                                    ((item['activity']['activityCardCreation'] === false && item['status'] === 'Time Out') ? 
                                                        <img src='/images/dashboard/referral/tabler_circle-x-filled.svg' alt='a red circle inside of it a white cross mark' draggable='false' /> :
                                                        (item['activeActivities']['activityDeposit'] === true ? 
                                                            <img src='/images/dashboard/referral/tabler_exclamation-circle-filled (2).svg' alt='a grey circle inside it number 3' draggable='false' />  :
                                                            <img src='/images/dashboard/referral/tabler_exclamation-circle-filled (3).svg' alt='a grey circle inside it number 2' draggable='false'  /> 
                                                        )
                                                    )
                                                    }
                                                    <span className='referralStageTextualContent'>
                                                        <span className='referralStageTitle'>Order a Card</span>
                                                        <span className='referralStageDescription'>After depositing, your friend orders their Paytaps card. Once they complete this step, you’ll receive your reward!</span> 
                                                    </span> 
                                                </li>}  
                                            </ul> 
                                        </Accordion.Body> 
                                    </Accordion.Item>
                                </Accordion>
                            ) 
                        })
                    } 
                </div> 
            </ul>}
            {
                (referralActivities.data.length === 0 ) && <div className='noRecords'>No Records</div> 
            } 
        </div>
        }
        {loadingState && <div className='waitLoadingProfileData h-50vh'><div>Please wait...</div><Loader /></div>}                          
    </>

  )
}

export default InviteesList 

