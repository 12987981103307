import React, { createContext, useState, useCallback, useContext } from "react";
import { NavMenuToggle } from "../jsx/layouts/nav/NavHader";
export const ToggleContext = createContext(null);


const ToggleContextProvider = (props) => {
    const [toggle, setToggle] = useState(false)
    const toggleHandler = useCallback(function toggleHandler () {
        setToggle(!toggle)
        NavMenuToggle()
     }, [toggle])
     const initToggle = useCallback(()=>{
      setToggle(false)
     }, [])
     return (
        <ToggleContext.Provider
          value={{
            toggle,
            toggleHandler,
            initToggle
        }}
        >
          {props.children}
        </ToggleContext.Provider>
     )
}

export default ToggleContextProvider

export function useToggle () {
    const {toggle, toggleHandler, initToggle} = useContext(ToggleContext)
    return {toggle, toggleHandler, initToggle} 
}

