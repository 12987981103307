import React, { useState, useEffect, useRef, useCallback} from 'react'
import WalletDeposit from '../Modals/WalletDeposit'
import UnsufficientBalance from '../Modals/UnsufficientBalance' 
import { useLoaderData } from 'react-router-dom' 
import { useStateCountDown } from '../context/EnableCountDown'   
import { useActive } from '../context/ActiveContext';

const DepositFund = () => {
    const {stateCountDown, setStateCountDown} = useStateCountDown()
    const [normalDeposit, setNormalDeposit] = useState(false)
    const result = useLoaderData() 
    const [unsufficientBalanceState, setUnsufficientBalanceState] = useState(false)
    const refAllow = useRef(false) 
    const [clickDepositStatus, setClickDepositStatus] = useState(false) 
    const { handleMenuActive } = useActive()  

    useEffect(()=>{ 

        handleShowNormalDeposit() 
        if(typeof result === 'number') {
          if(result >= parseFloat(localStorage.getItem('m_f_d'))) {
            refAllow.current = false
            handleShowNormalDeposit()
          }
          const decoded_payload = JSON.parse(atob(localStorage.getItem('u_t_p')))
          if(decoded_payload['req'] === 'N' && result < 120) {
                  handleShowUnsBalance()
                  refAllow.current = true
          }
        } else if(typeof result === 'string') {
            refAllow.current = false
        } else if((typeof result === 'object')) {
          refAllow.current = false 
        }

      }, [handleShowNormalDeposit])

    const handleCloseNormalDeposit = () => {setNormalDeposit(false); setStateCountDown(false)} 

    function handleShowNormalDeposit(){
      if(result.normal_deposit) {
        setNormalDeposit(true); setStateCountDown(true)
      } else if(!result.normal_deposit) {
        handleShowUnsBalance() 
        refAllow.current = true 
      } 
    }

    function setRefFalse() {
      setStateCountDown(false)
    }

    function setRefTrue() {
      setStateCountDown(true)
    }

    const handleShowUnsBalance = useCallback(function() {
        setUnsufficientBalanceState(true)
    }, [])

    const handleCloseUnsBalance = useCallback(function() {
      setUnsufficientBalanceState(false)
    }, [])

    const handleclickDepositStatusOff = useCallback(function() {
      setClickDepositStatus(false) 
    }, []) 
  return (
    <>
    {(result?.normal_deposit) && <WalletDeposit show={normalDeposit} handleClose={handleCloseNormalDeposit} stateCountDown={stateCountDown} setRefFalse={setRefFalse} setRefTrue={setRefTrue} handleclickDepositStatusOff={handleclickDepositStatusOff} /> }   
    {(!result?.normal_deposit) && <UnsufficientBalance show={unsufficientBalanceState} handleClose={handleCloseUnsBalance} a={refAllow.current} handleclickDepositStatusOff={handleclickDepositStatusOff} />    }                                  
    </>
  )
}

export default DepositFund
