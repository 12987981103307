import React from 'react'
import Slider from "react-slick";
import './CSS/testimonial.css'

const Testimonial = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <section id='testimonial'>
        <div className='testimonial-container'>
            <div className='testimonial-title'>Testimonials Section</div>
            <h2 className='testimonial-subtitle'>What Our Users Say</h2>
            <Slider {...settings}>
                <div className='testimonial-text-wrapper'>
                      
                      <div>"Using Paytaps has completely transformed how I manage my payments. Whether I’m shopping online or paying in-store, the tap-and-pay feature is seamless and fast. It's my go-to card for everything!"</div>
                      <div style={{width: '100%', display: 'flex', justifyContent: 'space-between'}}>
                        <span className='testimonial-author'><span><img src='/images/landingpage/body/Image-94.svg' alt='avatar' draggable='false' /></span>Emily R</span>
                        <span className='quoteIcon'><img src='/images/landingpage/body/Icon.svg' alt='icon' draggable='false' /></span>
                      </div>
                </div>
                <div className='testimonial-text-wrapper'>
                    
                    <div>"I love the flexibility of Paytaps! I can pay for anything, from groceries to travel, with just a tap. The digital card is so easy to use, and I never worry about transaction limits or hidden fees."</div>
                    <div style={{width: '100%', display: 'flex', justifyContent: 'space-between'}}>
                      <span className='testimonial-author'><span><img src='/images/landingpage/body/Image-94_.svg' alt='avatar' draggable='false' /></span>James T</span>
                      <span className='quoteIcon'><img src='/images/landingpage/body/Icon.svg' alt='icon' draggable='false' /></span>
                    </div>
                </div>
                <div className='testimonial-text-wrapper'>
                    
                    <div>"With Paytaps, managing crypto has never been easier. I can spend my crypto on daily purchases, from fueling my car to dining out, all with one simple tap. Highly recommended!"</div> 
                    <div style={{width: '100%', display: 'flex', justifyContent: 'space-between'}}>
                      <span className='testimonial-author'><span><img src='/images/landingpage/body/Image-94__.svg' alt='avatar' draggable='false' /></span>Rachel B</span>
                      <span className='quoteIcon'><img src='/images/landingpage/body/Icon.svg' alt='icon' draggable='false' /></span>          
                    </div> 
                </div>
            </Slider>
        </div>
    </section>
  )
}

export default Testimonial