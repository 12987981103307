import React, { useState, useEffect } from 'react' 
import './CSS/myinfo.css'
import ProfileChunkData from '../components/ProfileChunkData'
import { getProfileData, killToken, saveToken, refreshToken } from '../security/AuthService'  
import Loader from '../jsx/pages/Loader/Loader' 
import { Link } from 'react-router-dom' 
import { useActive } from '../context/ActiveContext';  
import { useNavigate, redirect } from 'react-router-dom' 
const MyInfo = () => {
    const [profile, setProfile] = useState({data: {}}) 
    const [error, setError] = useState('') 
    const [profileLoadingState, setProfileLoadingState] = useState(false)   
    const { handleMenuActive } = useActive() 
 
    const navigate = useNavigate() 
    useEffect(()=>{
        
            handleProfileData() 
        
    }, []) 
    async function handleProfileData() {
        try {
            setProfileLoadingState(true) 
            let fetchProfileData =  await getProfileData() 
            // If fetch returns 401 
            if(fetchProfileData.status === 401) {
                const refreshTokenResult = await refreshToken({refresh_token: localStorage.getItem('u_r_t')})

                // If refresh token returns 401
                if(refreshTokenResult.status === 401) {
                  killToken()
                  return navigate('/login')
                } 

                // If refresh token is successfull 
                const payloadRefreshToken = await refreshTokenResult.json()   

                let tokenPayload = payloadRefreshToken.token.split('.')[1] 

                // Payload of new generated token 
                let new_decoded_payload = JSON.parse(atob(tokenPayload)) 

                if(new_decoded_payload['exp']*1000 < new Date().valueOf())  {
                  killToken()
                  return navigate('/login') 
                } else {
                  saveToken(payloadRefreshToken.token, payloadRefreshToken.refresh_token) 
                  fetchProfileData = await getProfileData()   
                }

            } 

            const finalData =  await fetchProfileData.json() 
            if(fetchProfileData.ok && (finalData.message ==="successfully")) { 
                setProfile({...profile, data: finalData.data})    
                setProfileLoadingState(false) 
                setError('') 
            } else {
                setError(finalData.message)  
                setProfileLoadingState(false) 
            } 
        } catch(err) {
            setError('Error') 
        }
    } 
    function redirectToCS() { 
        navigate('/contactus')  
        handleMenuActive('Customer Support') 
    }
    if(error) {
       return  <>{error && <div className='errorLoadingProfile'>{error}</div>}</>   
    }
  return (
    <>
    {(!profileLoadingState && !error) && <div className='myinfo-container'> 
        <>
        { Object.keys(profile.data).length > 0 && <>
        <section className='profile-wrapper'>
            <div className='profile-header'>
                <figure>
                    <img src='/images/dashboard/profile/User-120.svg' alt='person/ user' draggable='false' />
                </figure>
                <div>
                    <div className='profile-info-title'>My profile info </div>
                    <p className='profile-info-bio'>Here is the information related to your PayTaps card.</p>    
                </div>
                
            </div>
            <div className='profile-body'>
                <ProfileChunkData imagesrc='/images/dashboard/profile/tabler_user.svg' imagealt='User' detailLabel='Full name' detailValue={profile.data.firstName} />
            
            <ProfileChunkData imagesrc='/images/dashboard/profile/tabler_brand-mailgun.svg' imagealt='Mailing brand' detailLabel='Email Address' detailValue={profile.data.emailAddress} />

            
            <ProfileChunkData imagesrc='/images/dashboard/profile/tabler_calendar-month.svg' imagealt='Calendar' detailLabel='Date of birth' 
            detailValue={`${(new Date(profile.data.dateOfBirth.date).getMonth()+1 > 9) ? new Date(profile.data.dateOfBirth.date).getMonth()+1: '0' + (new Date(profile.data.dateOfBirth.date).getMonth()+1)}/${(new Date(profile.data.dateOfBirth.date).getDate() > 9) ? new Date(profile.data.dateOfBirth.date).getDate() > 9 : '0' + (new Date(profile.data.dateOfBirth.date).getDate()) }/${new Date(profile.data.dateOfBirth.date).getFullYear()}`} />       

            
            <ProfileChunkData imagesrc='/images/dashboard/profile/tabler_phone.svg' imagealt='Phone' detailLabel='Phone number' detailValue={profile.data.mobile} /> 
            
            <ProfileChunkData imagesrc='/images/dashboard/profile/tabler_user-circle.svg' imagealt='User surrounded with circle frame' detailLabel='Prefered name' detailValue={profile.data.preferredName} />
            
            <ProfileChunkData imagesrc='/images/dashboard/profile/tabler_flag.svg' imagealt='Flag' detailLabel='Nationality' detailValue={profile.data.nationality} />
            
            <ProfileChunkData imagesrc='/images/dashboard/profile/ic_outline-language.svg' imagealt='Globe' detailLabel='Country of residence' detailValue={profile.data.countryCode} /> 
            
            <ProfileChunkData imagesrc='/images/dashboard/profile/ic_outline-transgender.svg' imagealt='Gender' detailLabel='Gender' detailValue={`${profile.data.gender}`} />  
            
            <ProfileChunkData imagesrc='/images/dashboard/profile/ic_outline-location-on.svg' imagealt='Location' detailLabel='Billing Address' detailValue={profile.data.buillingAddress} /> 
            
            <ProfileChunkData imagesrc='/images/dashboard/profile/ic_baseline-corporate-fare.svg' imagealt='Building' detailLabel='Billing City' detailValue={profile.data.buillingCity} /> 
            
            <ProfileChunkData imagesrc='/images/dashboard/profile/tabler_current-location.svg' imagealt='Current location' detailLabel='Billing Country' detailValue={profile.data.buillingCountry} /> 
            
            <ProfileChunkData imagesrc='/images/dashboard/profile/ic_outline-markunread-mailbox.svg' imagealt='Postal box' detailLabel='Billing Zip Code' detailValue={profile.data.buillingZipCode} /> 
            
            <ProfileChunkData imagesrc='/images/dashboard/profile/ic_outline-account-balance.svg' imagealt='Building from Roman era' detailLabel='Billing State' detailValue={profile.data.buillingState} /> 
            
            <ProfileChunkData imagesrc='/images/dashboard/profile/ic_outline-check.svg' imagealt='Check mark' detailLabel='Delivery State' detailValue={profile.data.deliveryState} />  
            
            </div>
            

        </section>
        <div className='profile-notification'>
            Please note that this information is view-only. For changes, contact <span onClick={redirectToCS}>customer support</span>. 
        </div></>}</> 
    </div>}
    {(profileLoadingState && !error) && <div className='waitLoadingProfileData'><div>Please wait...</div><Loader /></div>}
     
    </>
  )
}

export default MyInfo

 