import React, { useState } from 'react'
import './CSS/instructions.css'                          
import Accordion from 'react-bootstrap/Accordion'; 
import { Modal } from 'react-bootstrap';   
import Carousel from 'react-bootstrap/Carousel';                           
const Instruction = () => { 
  const [show, setShow] = useState(false)  
  const handleClose = () => {setShow(false)}  
  const handleShow = () => setShow(true)                      
     
  return (
    <>
      <section className='userGuideContainer'>
        <div className='userGuideTitle'>User Guide</div>
        <p className='userGuideDescription'>Your step-by-step guide to activating, using, and managing your PayTaps card effortlessly.</p>        

        <div className='userGuideContentWrapper'>
          <div className='userGuideTextuelContent'>
            <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="0">
                <Accordion.Header>How to Deposit Funds?</Accordion.Header>
                <Accordion.Body>
                  <ol>
                    <li>Navigate to the <span>Deposit Fund</span> section on the dashboard.</li>
                    <li>Enter the amount, ensuring it covers the required card creation fees.</li> 
                    <li>Choose your deposit method (Blockchain, <span id='ciexAccount' aria-describedby='depositThroughCIEx' onClick={handleShow}>CIEx account</span>).</li>   
                    <li>Enter your CIEx UID or the transaction hash.</li>
                    <li>Confirm the deposit.</li>
                  </ol>
                  <div className='additionalInfo'>
                    <strong>Additional Info:</strong> 
                    <p><strong>Note:</strong> Depositing funds is necessary to cover your card creation fees. All deposits will be reviewed and approved by an admin before becoming available in your account.</p>
                  </div> 
                  <div className='additionalInfo'>
                    <strong>Email Notification:</strong>  
                    <p>You will receive an email confirming your deposit request and its approval status.
                    </p> 
                  </div> 
                  <img src='/images/dashboard/userguide/deposit phone.png' alt='screenshot taken from an iphone containing QR code, an address, a checkbox to agree terms and conditions and 2 buttons cancel and confirm' />     
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header> How to Request Your Paytaps Card?</Accordion.Header>
                <Accordion.Body>
                  <ol>
                    <li>After making a deposit, go to the <span>My Card</span> section.</li> 
                    <li>Click <span>Request Card</span> and enter your details.</li>  
                    <li>Your deposit will be used to cover the card creation fees.</li>             
                    <li>Wait for admin approval of your card request (you’ll receive a notification once the card is ready).</li>    
                  </ol>
                  <div className='additionalInfo'>
                    <strong>Additional Info:</strong> 
                    <p>Ensure you have deposited enough funds to cover the card creation fee before making a request.
                    All card requests will be subject to admin approval.</p>
                  </div> 
                  <div className='additionalInfo'>
                    <strong>Email Notification:</strong>  
                    <p>You will receive an email notification regarding your card request and its approval status. 
                    </p> 
                  </div> 
                  <img src='/images/dashboard/userguide/request phone.png' alt='a form with 6 textual inputs, 1 checkbox button for confirming terms and conditions and button with inner text order now ' />          
                </Accordion.Body>
              </Accordion.Item> 
              <Accordion.Item eventKey="2">
                <Accordion.Header>How to View Your Card Information?</Accordion.Header>
                <Accordion.Body>
                  <ol>
                    <li>Navigate to the <span>My Card</span> section.                  </li> 
                    <li>Click on <span>Show details</span> then click on <span>Send OTP</span>.                  </li>  
                    <li>Enter the OTP you received in your email.                  </li>             
                    <li>Here you can view your card number, expiration date, and CVV.                  </li>      
                  </ol> 
                  <img src='/images/dashboard/userguide/info iphone.png' alt='a paytaps card below it its details and 2 buttons cancel and copy card number' />              
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3">
                <Accordion.Header>How to Top Up Your Card?</Accordion.Header>
                <Accordion.Body>
                  <ol>
                    <li>Navigate to the <span>My Card</span> section.
                    </li> 
                    <li>Click on <span>Top Up</span> on your dashboard.
                    </li>  
                    <li>Enter the amount you want to add to your card.
                    </li>             
                    <li>Confirm the top-up request.                  </li>    
                  </ol>
                  <div className='additionalInfo'>
                    <strong>Additional Info:</strong> 
                    <p>All top-up requests will be reviewed and approved by an admin before the funds are available on your
  card.
  </p>
                  </div> 
                  <div className='additionalInfo'>
                    <strong>Email Notification:</strong>  
                    <p>You will receive an email confirming your top-up request and its approval status.  
                    </p> 
                  </div> 
                  <img src='/images/dashboard/userguide/topup phone.png' alt='an interface with main header of top-up, an input field, a table displaying some data and 2 buttons cancel and confirm' />  
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="4">
                <Accordion.Header>How to Add Your Card to Apple Pay or Samsung Pay?</Accordion.Header>
                <Accordion.Body>
                  <ol>
                    <li>Navigate to the <span>My Card</span> section.
                    </li> 
                    <li>Click on <span>Add to Wallet</span>.  
                    </li>  
                    <li>Follow the steps to add your Paytaps card to your mobile wallet.
                    </li>             
                    <li>Confirm the top-up request.                  </li>    
                  </ol>
                  <img src='/images/dashboard/userguide/add wallet.svg' alt='an interface showing a paytaps card with 4 buttons: approve transaction, add to wallet, show details, top up and 2 areas showing the card balance and wallet balance' />  
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="5">
                <Accordion.Header> How to Tap and Pay? </Accordion.Header>
                <Accordion.Body>
                  <ol>
                    <li>Ensure your phone has your Paytaps card added to the digital wallet.
                    </li> 
                    <li>Use contactless payment by tapping your phone at the terminal. 
                    </li>     
                  </ol>
                  <img src='/images/dashboard/userguide/tap to pay.svg' alt='2 hands each hold a phone and they are near to each other ' />   
                </Accordion.Body>
              </Accordion.Item>      
            </Accordion>
          </div>
          <figure className='userGuideImageContent'>  
          </figure>
        </div>
      </section>

      <Modal show={show} onHide={handleClose} className='showCiexAccount' id='depositThroughCIEx'>
        <Modal.Header closeButton>
          <Modal.Title>CIEx account</Modal.Title>
          <img src='/images/dashboard/userguide/Frame 20.svg' alt='6 screenshots showing steps to withdraw crypto on ciex app' />   
          <Carousel fade interval={null} id='withdrawSteps'>
            <Carousel.Item>
              <img src='/images/dashboard/userguide/1.svg' alt='an interface with 5 menus, with a blue line under overview, deposit button and a rectangle with yellow borders around withdraw button ' />
            </Carousel.Item>
            <Carousel.Item>
            <img src='/images/dashboard/userguide/2.svg' alt="an interface with 5 menus, with a blue line under overview, total balance and today\'s PNL values are hidden with asterisks and a rectangle with yellow borders around USDT " />  

            </Carousel.Item>
            <Carousel.Item>
            <img src='/images/dashboard/userguide/3.svg' alt='an interface with 5 menus, with a blue line under overview, at the bottom of it; an area with title withdraw and cancel button and a rectangle with yellow borders around the rectangle with title send via crypto network, below it a rectangle with title send via email/phone/UID' /> 

            </Carousel.Item> 
            <Carousel.Item>
              <img src='/images/dashboard/userguide/4.svg' alt='an interface with main title withdraw, containing a form, into it a label of chain name underneath of it erc-20, trc-20 surrounded by a rectangle with yellow borders , bep-20 and with other fields; address, amount, ... below it other details about the withdraw and a confirm button' />
            </Carousel.Item>
            <Carousel.Item>
            <img src='/images/dashboard/userguide/5.svg' alt='an interface with main title withdraw, containing a form, into it a label of chain name underneath of it erc-20, trc-20, bep-20, a filled adrress field below it a rectangle with yellow borders containing UID:32235592, below it other details about the withdraw and a confirm button' />

            </Carousel.Item>
            <Carousel.Item>
            <img src='/images/dashboard/userguide/6.svg' alt='an interface with main title withdraw, containing a form, within it a field with label send mode and value UID, below it a field with label UID and value 32235592 surrounded by a rectangle with yellow borders, below this field a message starting with this is the UID corresponding to the address..., and in the bottom a confirm button surrounded by a recatngle with yellow borders' /> 

            </Carousel.Item>
          </Carousel> 
        </Modal.Header>
      </Modal>
    </>  
  )
}

export default Instruction 
  