import React, { useState, useEffect, useCallback, useRef, memo } from 'react' 
import './CSS/topup.css'
import Modal from 'react-bootstrap/Modal'
import { Formik, Form, Field } from 'formik' 
import TextInput from '../components/TextInput'
import * as YUP from 'yup'
import Button from '../components/Button' 
import { convertToUSDT, requestTopUp, getAddressBasedOnChain } from '../security/AuthService'  
import { useNavigate } from 'react-router-dom' 
import { useActive } from '../context/ActiveContext' 
import { refreshTokenWithAPICallHandler } from '../utils/refresh' 
import Loader from '../jsx/pages/Loader/Loader'
import { generateQRCode } from '../utils/utils' 
import ConfirmTermsConditions from './ConfirmTermsConditions'

const Topup = memo((props) => {
    const [amount, setAmount] = useState('--')
    const [totalAmount, setTotalAmount] = useState('--')
    const [feesCIX, setFeesCIX] = useState(null)  
    const navigate = useNavigate() 
    const { handleMenuParentActive } = useActive()                                                                          
    const [isCopied, setIsCopied] = useState(false)  
    const [termsConditions, setTermsConditions] = useState(false)   
    const [confirm_TC, setConfirm_TC] = useState(false)
    const [choosedChainAndAddress, setChoosedChainAndAddress] = useState({
        loadAddress: false,     
        addressValue: props.btcAddress,
        chain: 'BTC'   
    })
    const activeChain = useRef('BTC')
    const [hasAgreedTermsConditions, setHasAgreedTermsConditions] = useState(false)

    const CHAIN_NAMES = ['BTC', 'TRC20', 'ERC20', 'SOL', 'TON'] 

    let state
    const keyUpHandler = (e)=>{
        state.setFieldValue(e.target['name'], e.target.value) 
        state.setFieldTouched(e.target['name'])
    }

    useEffect(()=>{
        try {
            if(document.getElementById('topup_amount')) {
                document.getElementById('topup_amount').addEventListener('keyup', ()=>{
                    handleChange()  
                })}
        } catch(err) {

        }
    } )  

    async function handleChange() {
        
       try {
        
            let topup_fee = parseFloat(localStorage.getItem('t_u_f'))
            if(document.getElementById('topup_amount').value === '') {
                localStorage.setItem('t_u_f', 0)   
                setAmount('--')
                setTotalAmount('--') 
                setFeesCIX(null) 
            }
            if( /^[0-9\.]+$/.test(document.getElementById('topup_amount').value)) {
                      
                let convert = {
                    api_call: await convertToUSDT({
                        amount: parseFloat(document.getElementById('topup_amount').value) 
                    })
                }
                const renew = async () =>{
                    return await convertToUSDT({
                        amount: parseFloat(document.getElementById('topup_amount').value) 
                    })
                }
                // If fetch returns 401 
                if(convert.api_call.status === 401) {  
                    if(await refreshTokenWithAPICallHandler(convert, renew) === 'REDIRECT_TO_LOGIN') {
                        return navigate('/login') 
                    }

                }
                const convert_data = await convert.api_call.json()

                if(document.getElementById('topup_amount').value === '') {
                    setAmount('--')
                    setTotalAmount('--')
                    setFeesCIX(null)                                                                                        
                } else {
                    if(!/^[0-9\.]+$/.test(document.getElementById('topup_amount').value)) {
                        setAmount('--')
                        setTotalAmount('--')
                        setFeesCIX(null)
                        return
                    }

                    if(document.getElementById('topup_amount').value >= 100) { 
                        localStorage.setItem('t_u_f', localStorage.getItem('t_u_f_100')) 
                        topup_fee = parseFloat(localStorage.getItem('t_u_f')) 
                    } else if((document.getElementById('topup_amount').value >= 50) && (document.getElementById('topup_amount').value < 100)) {
                        localStorage.setItem('t_u_f', localStorage.getItem('t_u_f_50_100'))  
                        topup_fee = parseFloat(localStorage.getItem('t_u_f'))  
                    } else if ((document.getElementById('topup_amount').value >= 10) && (document.getElementById('topup_amount').value < 50)) {
                        localStorage.setItem('t_u_f', localStorage.getItem('t_u_f_10_50'))
                        topup_fee = parseFloat(localStorage.getItem('t_u_f'))  
                    }
                        else {
                        localStorage.setItem('t_u_f', 0)
                        topup_fee = 0  
                    }

                    setAmount((parseFloat(document.getElementById('topup_amount').value))) 
                    setTotalAmount((((parseFloat(convert_data['data']['result'])*topup_fee)/100) + parseFloat(convert_data['data']['result'])))    

                    
                    if(localStorage.getItem('a_f_c') === 'true') { 
                        let topup_fee_amount_usdt = (parseFloat(convert_data['data']['result'])*topup_fee)/100
                        const conversion_rate_cix_usdt = parseFloat(localStorage.getItem('t_c_r'))
                        const fees_usdt_to_cix = topup_fee_amount_usdt / conversion_rate_cix_usdt
                        if(Math.sign(props.balanceCix - fees_usdt_to_cix) === 1) {
                            setFeesCIX(fees_usdt_to_cix)
                        } else if(Math.sign(props.balanceCix - fees_usdt_to_cix) === -1) {
                            setFeesCIX(null) 
                        }

                    } else {
                        if(feesCIX) {
                            setFeesCIX(null)
                        }
                    }

                }
            } else {
                localStorage.setItem('t_u_f', 0)   
                setAmount('--')
                setTotalAmount('--')
                setFeesCIX(null) 

            } 
       } catch(err) {
       }
    } 

    useEffect(()=>{    
        try {
            if(props.show) {
                setChoosedChainAndAddress({...choosedChainAndAddress, addressValue: props.btcAddress}) 
                generateQRCode(props.btcAddress, 'qrCodeDepositAddress')
            }  

        } catch(err) {

        }                                                                                                       
    }, [props.show])     

    useEffect(()=>{  
        try {
            if(choosedChainAndAddress.chain) {
                generateQRCode(choosedChainAndAddress.addressValue, 'qrCodeDepositAddress')                                                           
            }
        } catch(err) {

        }
    }, [choosedChainAndAddress.chain])   
    
    useEffect(()=>{    
        try {
            if(!termsConditions && !confirm_TC) { 
                setHasAgreedTermsConditions(false)
            } else { 
                setHasAgreedTermsConditions(true)   
            }
        } catch(err) {

        }
    }, [termsConditions, confirm_TC])

    const handleCancel = useCallback(async function() {
        let path = window.location.pathname;
        path = path.split("/");
        path = path[path.length - 1] 

        if(path === 'topupcard') {
            navigate('/mycard')      
            handleMenuParentActive('My Card', 'Card')     
            handleMenuParentActive('My Card', 'Card') 
        }
        setAmount('--')
        setTotalAmount('--')
        setFeesCIX(null)
        props.handleClose()
        handleCloseTermsConditions() 
        await new Promise((resolve, reject)=>{
            setTimeout(()=>{
                resolve(localStorage.setItem('t_u_f', 0))
            }, 100)
        })
        setConfirm_TC(false)
        await new Promise((resolve, reject)=>{
            setTimeout(()=>{
                resolve(resetChainAndAddressValues()) 
            }, 100)
        }) 
        
    }, [confirm_TC, choosedChainAndAddress.chain, choosedChainAndAddress.addressValue]) 

    async function copyAddress () {
        try {
            setIsCopied(true)
            await navigator.clipboard.writeText(document.getElementById('wallet-address-value1').innerHTML)
            await new Promise((resolve, reject)=>{
                setTimeout(()=>{
                    resolve(setIsCopied(false))
                }, 2000)
            })
        } catch (err) {
            setIsCopied(false)
        }
    }

    const handleShowTermsConditions = useCallback(function() {
        setTermsConditions(true) 
    }, [])
        
    const handleCloseTermsConditions = useCallback(function() {
        setTermsConditions(false)   
    }, []) 
    
    const handleConfirmTermsConditions = function(e) { 
        
        if(!termsConditions) {
            if(confirm_TC) {
                return setConfirm_TC(false) 
            }
            handleShowTermsConditions()    
        } else {
            if(confirm_TC) {
                return setConfirm_TC(false) 
            }
            handleCloseTermsConditions()    
        }

    }
    const chainSelectHandler = useCallback(async (e)=>{
        try {
            activeChain.current = e.currentTarget.innerHTML 

            if(e.currentTarget.innerHTML === choosedChainAndAddress.chain) {
                return;
            }

            setChoosedChainAndAddress({...choosedChainAndAddress, loadAddress: true})
            
            const fetch_address = await getAddressBasedOnChain({network:  e.currentTarget.innerHTML})                                                                     
            const result_fetch_address = await fetch_address.json()
             
            if(fetch_address.ok && result_fetch_address['message'] === 'success') {
                setChoosedChainAndAddress({...choosedChainAndAddress, 
                    addressValue: result_fetch_address['data'][0]['wallet_address'], 
                    loadAddress: false, 
                    chain: result_fetch_address['data'][0]['wallet_network']
                })     
                                                                
            }
        } catch(err) {

        }                                                                                 
    }, [choosedChainAndAddress.chain])   
    
    const resetChainAndAddressValues = useCallback(function () {
        setChoosedChainAndAddress({...choosedChainAndAddress, chain: 'BTC', addressValue: props.btcAddress}) 
        activeChain.current = 'BTC'
    }, []) 
      
  return (<>
    <Modal show={props.show} onHide={props.handleClose} className='topup-modal topup-scroll' backdrop='static' keyboard='false' > 
        <div className='modal-header'>
            <div className='topup-title'>Top-up</div>
            <div className='topup-description'>Enter the amount in USD you want to add. This amount will be available on your card in USD.</div>
        </div> 
    
        <Formik
                initialValues={{
                    topup_amount: ''
                }}
                validateOnChange={ true }
                validateOnBlur={ true } 
                validationSchema={YUP.object({
                    topup_amount: YUP.string().required('Required!').matches(/^[0-9\.]+$/, { message: <span>Please enter a valid top-up amount</span>}).test('topup', `Please enter at least ${parseFloat(localStorage.getItem('m_t_u'))}`,  (value, context) =>(parseInt(value))>=parseFloat(localStorage.getItem('m_t_u'))),   
                })}

                onSubmit={async (values)=>{ 
                    try {
                        let path = window.location.pathname;
                        path = path.split("/");
                        path = path[path.length - 1]   
                        
                        let topup = {
                            api_call: await requestTopUp({
                            amountFiat: parseFloat(values.topup_amount),
                            amountCryptoTotal: parseFloat(totalAmount),
                            topupFeeCrypto: feesCIX ? parseFloat(feesCIX) : (parseFloat(totalAmount) - parseFloat(values.topup_amount)), 
                            currencyCrypto: feesCIX ? 'cix' : 'usdt' 
                        })
                        }
                        const renew = async () => {
                            return await requestTopUp({
                            amountFiat: parseFloat(values.topup_amount),
                            amountCryptoTotal: parseFloat(totalAmount),
                            topupFeeCrypto: feesCIX ? parseFloat(feesCIX) : (parseFloat(totalAmount) - parseFloat(values.topup_amount)), 
                            currencyCrypto: feesCIX ? 'cix' : 'usdt'
                        })
                        }
                        // If fetch returns 401  
                        if(topup.api_call.status === 401) {
                            if(await refreshTokenWithAPICallHandler(topup, renew) === 'REDIRECT_TO_LOGIN') {
                                return navigate('/login') 
                            } 
                        }

                        if(topup.api_call.ok) {
                            const result = await topup.api_call.json()
                             
                            if(path === 'topupcard') {    
                                props.handleClose() 
                            } 
                            if(path === 'mycard') {
                                props.handleClose()
                                props.updateBalance() 
                            }
                            
                            props.handleShowTopupSuccess()  
                            if(result['message'] === 'Your request is under review') {
                                props.showTopupMsgHandler(result['message']) 
                            }
                            
                            await new Promise((resolve, reject)=>{
                                setTimeout(()=>{
                                    resolve(localStorage.setItem('t_u_f', 0))
                                }, 100)
                            })

                            setHasAgreedTermsConditions(false)
                            setTermsConditions(false) 
                            setConfirm_TC(false)   
                            resetChainAndAddressValues()                                    
                        }

                        if(!topup.api_call.ok) {
                            props.handleClose()
                            props.handleShowInsufTopupBalance()  
                        }
                        setAmount('--')
                        setTotalAmount('--')
                        setFeesCIX(null)
                        } catch(err) {
                        }
                }}
                        >
                        {(formik) => {
                        state = formik
                        return (
                            <Form className='form' onSubmit={formik.handleSubmit}>

                                <TextInput 
                                    name='topup_amount' 
                                    id='topup_amount' 
                                    label='Enter amount' 
                                    inputtype='text' 
                                    onKeyUp={keyUpHandler} 
                                    currency='usd'  
                                />   
                                <div className='amount-fees-wrapper'> 
                                    {localStorage.getItem('a_f_c') === 'true' && <div className='amount-fees-row'><span>Available CIX</span><span>{`${props.balanceCix.toFixed(2)}`} <strong>CIX</strong></span> </div>}                                                                               
                                    <div className='amount-fees-row'><span>Amount</span><span>{!isNaN(parseFloat(amount)) ? parseFloat(amount).toFixed(2) : '--'} USD</span></div>
                                    <div className='amount-fees-row'><span>Fees</span><span>{localStorage.getItem('t_u_f')}%</span></div> 
                                    <div className='amount-fees-row' style={feesCIX ? {alignItems: 'center'} : {}}> 
                                        <span>Total</span>
                                        {!feesCIX ? 
                                        <span>{!isNaN(parseFloat(totalAmount)) ? parseFloat(totalAmount).toFixed(2) : '--'} USDT</span> : 
                                        <span style={{display: 'flex', flexDirection: 'column', textAlign: 'right'}}>  
                                            <span>{!isNaN(parseFloat(amount)) ? parseFloat(amount).toFixed(2) : '--'} USDT</span> 
                                            <span style={{textAlign: 'center'}}>+</span> 
                                            <span>{!isNaN(parseFloat(feesCIX)) ? parseFloat(feesCIX).toFixed(2) : '--'} CIX</span>  
                                        </span>                                         
                                        }
                                    </div>                                                                                                   
                                </div> 
                                <div className='transaction-fee-notification'>
                                    * Our transaction fee are included. <span>See transaction fee</span>
                                </div>
                                <div className='depositAddressesWrapper'>
                                    <div className='depositNetworkText'>Deposit Network</div>
                                    <div className='addressesNamesWrapper'>
                                        
                                        {CHAIN_NAMES.map(chain=>{
                                            return (
                                                <button 
                                                    key={chain} 
                                                    type='button' 
                                                    onClick={chainSelectHandler}
                                                    className={`${activeChain.current === chain ? 'activeChain' : ''}`}    
                                                    disabled={choosedChainAndAddress.loadAddress}                                                                                                                                                                                                                                                                                                                                                                                                                    
                                                >
                                                 {chain}
                                                </button>                                 
                                            )
                                        })}
                                    </div>
                                    {!choosedChainAndAddress.loadAddress && <div className='wallet-address-wrapper'>
                                            <div id='qrCodeDepositAddress'></div>
                                            <div>
                                                <div>
                                                    <img src='/images/dashboard/topup/tabler_wallet.svg' alt='wallet' draggable='false' /> 
                                                    <div className='wallet-address'>
                                                        <span>Wallet address <span style={{fontWeight: '700'}}>({choosedChainAndAddress.chain})</span></span>                                
                                                        <span id='wallet-address-value1'>{choosedChainAndAddress.addressValue}</span>          
                                                    </div>
                                                </div>
                                                <button className='copy-btn' type='button' onClick={copyAddress}> 
                                                    <img src='/images/dashboard/topup/tabler_copy.svg' 
                                                    alt='2 squares with the highest one its right half clipped' hidden={isCopied} 
                                                    draggable='false'
                                                    />   
                                                    <span hidden={!isCopied}>Copied!</span>
                                                </button>
                                            </div>
                                    </div>}  
                                    {choosedChainAndAddress.loadAddress && <div className='d-flex justify-content-center align-items-center' style={{height: '100px', marginTop: '8px'}}><Loader /></div>}                                        
                                </div>
                                <div className='check-term-conditions'>
                                    <Field type="checkbox" name="agree_terms_conditions_topup" checked={termsConditions || confirm_TC}  onClick={handleConfirmTermsConditions} />   
                                    <span style={{color: 'var(--black-color)'}}> I Agree to the Terms and Conditions.</span>  
                                </div>
                                <div className='group-btn'>
                                    <Button click={handleCancel} id="cancel">Cancel</Button>
                                    <Button btnType='submit' id='confirm_topup' disabled={!(formik.dirty && formik.isValid) || formik.isSubmitting || !hasAgreedTermsConditions }>Confirm</Button>     
                                </div>
                                     
                            </Form>)}}
        </Formik> 
    </Modal>
    <ConfirmTermsConditions 
        show={termsConditions} 
        handleShowTermsConditions={handleShowTermsConditions} 
        handleCloseTermsConditions={handleCloseTermsConditions} 
        confirm_TC={()=>setConfirm_TC(true)} 
    /> 
   </>
  )
  
}
) 

export default Topup   