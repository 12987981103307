import React, { useState, useEffect } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Link } from "react-router-dom";
import {MenuList} from './Menu';
import {useScrollPosition} from "@n8tb1t/use-scroll-position";
import '../../../dashboard/CSS/navigationcustomization.css'
import { useToggle } from "../../../context/ToggleContext";
import { useActive } from "../../../context/ActiveContext";
import { useReferral } from '../../../context/ReferralContext' 
import { useGoogleAuthentication } from "../../../context/GoogleAuthenticationContext";
import LinksCollection from "./LinksCollection"; 

let path = window.location.pathname;
path = path.split("/");
path = path[path.length - 1]

const SideBar = () => {
  const {state, handleMenuParentActive} = useActive() 
  const { toggle } = useToggle()
  const { showReferral } = useReferral() 
  const { hasEnabledGoogleAuthentication } = useGoogleAuthentication()  

  useEffect(()=>{
    MenuList.forEach(function(item){
      if(item?.content?.length > 0) {
        // Section 
        document.getElementById(item.id)?.addEventListener('mouseenter', ()=>{
          if(window.innerWidth >= 1024) {
            // WINDOW >= 1024 
            if(fetchActive(item?.content) !== -1 && !toggle) {
              document.querySelector(`#menu > li > button#${item.id} > span > span > img`)?.setAttribute('src', item.active_icon_white)  
            } else {
              document.querySelector(`#menu > li > button#${item.id} > span > span > img`)?.setAttribute('src', item.active_icon_blue)   
            }
          } else {
            // WINDOW < 1024  
            if(fetchActive(item?.content) !== -1) {
              document.querySelector(`#menu > li > button#${item.id} > span > span > img`)?.setAttribute('src', item.active_icon_white)  
            } else {
              document.querySelector(`#menu > li > button#${item.id} > span > span > img`)?.setAttribute('src', item.active_icon_blue) 
            }
          } 
        })
        
        document.getElementById(item.id)?.addEventListener('mouseleave', ()=>{
          if(window.innerWidth >= 1024) {
            // WINDOW >= 1024 
            if(fetchActive(item?.content) !== -1 && !toggle) {
              document.querySelector(`#menu > li > button#${item.id} > span > span > img`)?.setAttribute('src', item.active_icon_white)
            } else if(fetchActive(item?.content) !== -1 && toggle) {
              document.querySelector(`#menu > li > button#${item.id} > span > span > img`)?.setAttribute('src', item.active_icon_blue)       
            }
            else {
              document.querySelector(`#menu > li > button#${item.id} > span > span > img`)?.setAttribute('src', item.normal_icon)
            } 
          } else {
            // // WINDOW < 1024 
            if(fetchActive(item?.content) !== -1) {
              return document.querySelector(`#menu > li > button#${item.id} > span > span > img`)?.setAttribute('src', item.active_icon_white)       
            } else if(fetchActive(item?.content) === -1) {
              document.querySelector(`#menu > li > button#${item.id} > span > span > img`)?.setAttribute('src', item.normal_icon)
            }
          }
        }) 

        // Inner Links Within Section
        item?.content.forEach(function(itemLink){
          document.getElementById(itemLink.id)?.addEventListener('mouseenter', ()=>{
            document.querySelector('#menu > li > button + ul.show > li:hover > a > span > span > img')?.setAttribute('src', itemLink.active_icon)  
          })

          document.getElementById(itemLink.id)?.addEventListener('mouseleave', ()=>{
            if(state.active === itemLink.title) {
              document.querySelector(`#menu > li > button + ul.show > li > a#${itemLink.id} > span > span > img`)?.setAttribute('src', itemLink.active_icon)
            }
            else {
              document.querySelector(`#menu > li > button + ul.show > li > a#${itemLink.id} > span > span > img`)?.setAttribute('src', itemLink.normal_icon)
            }
          
          })
        }) 

      } else {

        // Separate Links  
        document.getElementById(item.id)?.addEventListener('mouseenter', ()=>{
          document.querySelector('#menu > li:hover > a > span > span > img')?.setAttribute('src', item.active_icon)
        })

        document.getElementById(item.id)?.addEventListener('mouseleave', ()=>{
          if(state.active === item.title) {
            document.querySelector(`#menu > li > a#${item.id} > span > span > img`)?.setAttribute('src', item.active_icon)
          }
          else {
            document.querySelector(`#menu > li > a#${item.id} > span > span > img`)?.setAttribute('src', item.normal_icon)
          }
        
        })
      }
       
      
    })
   
    return ()=>{
      MenuList.forEach((item)=>{
        
        if(item?.content?.length > 0) {  
          // Section 
          document.getElementById(item.id)?.removeEventListener('mouseenter', ()=>{ })
          document.getElementById(item.id)?.removeEventListener('mouseleave', ()=>{ }) 
  
          // Inner Links Within Section
          item?.content.forEach(function(itemLink){
            document.getElementById(itemLink.id)?.removeEventListener('mouseenter', ()=>{ })
            document.getElementById(itemLink.id)?.removeEventListener('mouseleave', ()=>{ }) 
  
          })
        } else {
          document.getElementById(item.id)?.removeEventListener('mouseenter', ()=>{ })
          document.getElementById(item.id)?.removeEventListener('mouseleave', ()=>{ })
        }
      })

    }
  }, [toggle, state.active])     
  
	  let handleheartBlast = document.querySelector('.heart');
	  function heartBlast(){
		  return handleheartBlast.classList.toggle("heart-blast");
	  }
  
 	const [hideOnScroll, setHideOnScroll] = useState(true)
	useScrollPosition(
		({ prevPos, currPos }) => {
		  const isShow = currPos.y > prevPos.y
		  if (isShow !== hideOnScroll) setHideOnScroll(isShow)
		},
		[hideOnScroll]
	)

  function fetchActive (data) { 
    return data.findIndex((item)=> item.title === state.active ) 
  }

    return (
        
        <div className="deznav">
          <PerfectScrollbar className="deznav-scroll">          
            <ul className="metismenu" id="menu" >
              {MenuList.map((data, index)=>{
                let menuClass = data.classsChange;
                  if(menuClass === "menu-title"){
                    return(
                        <li className={menuClass}  key={index} >{data.title}</li>
                    )
                  }else{
                    return(				
                      <li className={` ${ state.active === data.title ? 'mm-active' : ''}`}
                        key={index} 
                      >                        
                        {data.content && data.content.length > 0 ?
                            <>
                              
                                <LinksCollection 
                                  data={data.content} 
                                  title={data.title} 
                                  normal_icon={data.normal_icon} 
                                  active_icon_blue={data.active_icon_blue} 
                                  active_icon_white={data.active_icon_white} 
                                  id={data.id} 
                                /> 
 
                            </>
                        :
                          <Link 
                            hidden={
                              data.title === 'Referral' ? 
                              (!showReferral ? true : false) :
                              (data.title === 'My Card') ? 
                              (!showReferral ? true : false) : 
                              (data.title === 'Top-up Card') ? 
                              (!showReferral ? true : false) : 
                              (data.title === 'Profile') ? 
                              (!showReferral ? true : false) :
                              (data.title === 'Order Card') ?  
                              (showReferral ? true : false) :
                              (data.title === 'Google Authentication') ?  
                              (hasEnabledGoogleAuthentication ? true : false) : 
                              false
                          }       
                            to={data.to}  
                            onClick={() => {handleMenuParentActive(data.title, '')}}    
                            id={data.id}  
                          >  
                            <span className={`link-logo-label-container ${toggle ? 'toggeled-icons-containers' : ''}`}>
                              {
                                state.active === data.title ? 
                                <span style={{marginRight: '2px'}}><img src={data.active_icon} /></span> : 
                                <span style={{marginRight: '2px'}}><img src={data.normal_icon} /></span> 

                              }
                              <span className={`nav-text link-label ${data.title === 'Profile' ? 'ml-2' : ''}`}>{data.title} </span>
                              </span>
                          </Link>
                        }
                        
                      </li>	
                    )
                  }
                })} 
            </ul> 
			    </PerfectScrollbar>
        </div>
    );
  
}

export default SideBar;
