import React from 'react'
import './CSS/carddetails.css'

const CardDetails = () => {
  return (
    <section id='carddetails'>
        <div className='carddetails-container'>
            <div className='carddetails-title'> KEY DETAILS SECTION  </div>
            <h2 className='carddetails-subtitle'>Key PAYTAPS Card Details</h2>
            <div className='carddetails-keys-container'>
                <div className='carddetails-keys'>
                    <div className='carddetails-keys-item'>
                        <img src='/images/landingpage/body/Group1.svg' alt='available worldwide' draggable='false' />
                        <div>Available globally</div>
                    </div>
                    <div className='carddetails-keys-item'>
                        <img src='/images/landingpage/body/Group2.svg' alt='free monthly subscription' draggable='false' />
                        <div>Monthly subscription: Free</div>
                    </div>
                    <div className='carddetails-keys-item' style={{position: 'relative'}}> 
                        <img src='/images/landingpage/body/Vector3.svg' alt='top-up fee: 1.9%' draggable='false' />
                        <div>Top-Up Fee: 3%</div>
                        <div className='topupAmount4PercentCardDetailsBelowXLarge'>4% for Top-Up Amount {`<`} 100$.</div>                
                    </div>
                    <div className='carddetails-keys-item'>
                        <img src='/images/landingpage/body/Vector4.svg' alt='fees in USDT' draggable='false' />
                        <div>Pay fees in USDT</div>
                    </div>
                    <div className='carddetails-keys-item'>
                        <img src='/images/landingpage/body/Vector5.svg' alt='Compatible with Apple Pay, Samsung Pay, and Google Pay' draggable='false' />
                        <div>Compatible with Apple Pay, Samsung Pay, and Google Pay</div>
                    </div>
                    <div className='carddetails-keys-item'>
                        <img src='/images/landingpage/body/Group6.svg' alt='ease handle of your card' draggable='false' />
                        <div>Easily block and unblock your card with a tap</div>
                    </div>

                </div>
                <figure className='carddetails-image'>
                    <img src='/images/landingpage/body/acardmaster.png' alt='cards with green background' draggable='false' />           

                </figure>
            </div>
            <div className='topupAmount4PercentCardDetailsXLarge'>4% for Top-Up Amount {`<`} 100$.</div>                
        </div>
    </section>
  )
}

export default CardDetails  