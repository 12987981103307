import React , { useState, useEffect, useRef, useCallback } from 'react'  
import { Modal } from 'react-bootstrap'
import './CSS/showdetails.css'
import Button from '../components/Button'
import OtpInput from '../components/OtpInput' 

import './CSS/verifycardinfo.css'
import { generateOTP, verifyOTP, refreshToken, killToken, saveToken } from '../security/AuthService'   
import { useNavigate } from 'react-router-dom' 
const VerifyCardInfo = (props) => {
  const navigate = useNavigate() 
    const [otp, setOtp] = useState('');
    const [timer, setTimer] = useState(`${localStorage.getItem('o_t_p_t') ? (localStorage.getItem('o_t_p_t').length > 1 ? localStorage.getItem('o_t_p_t')+':00' : '0'+localStorage.getItem('o_t_p_t')+':00') : ''}  `);   
    const [stateCodeGe, setStateCodeGe] = useState(false) 
    const refTimerOTP = useRef(null)
    const refAllow = useRef(false)
    const otpValue = useRef(null)  
    const [error, setError] = useState('') 
    useEffect(()=>{
        if(refAllow.current) {
        if(timer === '-1:-1') { 
            setTimer(`${localStorage.getItem('o_t_p_t').length > 1 ? localStorage.getItem('o_t_p_t')+':00' : '0'+localStorage.getItem('o_t_p_t')+':00'}  `)
        }
        if(stateCodeGe) {

        
        var x = setInterval(function() {

            // Get today's date and time
            const now = new Date()
            
              
            // Find the distance between now and the count down date
            var distance = refTimerOTP.current - now.getTime();
              
            // Time calculations for minutes and seconds
            var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            var seconds = Math.floor((distance % (1000 * 60)) / 1000);
            setTimer(`${(''+minutes).length === 1 ? '0' + minutes : minutes}:${(''+seconds).length === 1 ? '0' + seconds : seconds}`) 
              
            // If the count down is over 
            if (distance < 0) {
              
              clearInterval(x);
              refAllow.current = false  
              setStateCodeGe(false) 
              setTimer(`${localStorage.getItem('o_t_p_t').length > 1 ? localStorage.getItem('o_t_p_t')+':00' : '0'+localStorage.getItem('o_t_p_t')+':00'}  `) 
              if(error) {
                setError('')
              }  
              
            }
          }, 1000);
        }
    }
    }, [timer, stateCodeGe,  props.a]) 
    
    const handleOtpChange = (value) => {
        setOtp(value);
      };
      const handleTimerStart = useCallback(async function() {
        try {
          setError('') 
        
          let generateOTP_result = await generateOTP()

          // If fetch returns 401 
          if(generateOTP_result.status === 401) {
            const refreshTokenResult = await refreshToken({refresh_token: localStorage.getItem('u_r_t')})

            // If refresh token returns 401
            if(refreshTokenResult.status === 401) {
              killToken()
              return navigate('/login')
            } 

            // If refresh token is successfull 
            const payloadRefreshToken = await refreshTokenResult.json()   

            let tokenPayload = payloadRefreshToken.token.split('.')[1] 

            // Payload of new generated token 
            let new_decoded_payload = JSON.parse(atob(tokenPayload)) 

            if(new_decoded_payload['exp']*1000 < new Date().valueOf())  {
              killToken()
              return navigate('/login') 
            } else {
              saveToken(payloadRefreshToken.token, payloadRefreshToken.refresh_token)  
              generateOTP_result = await generateOTP() 
            }

          }
          // const generateOTP_data = await generateOTP_result.json() 
          // console.log(generateOTP_data)
          if(generateOTP_result.ok) {
              refAllow.current = true         
              setStateCodeGe(true)  
              // refTimerOTP.current = new Date().getTime() + 18001 
              refTimerOTP.current = new Date().getTime() + (parseInt(localStorage.getItem('o_t_p_t')) * 60 * 1000) + 1000
          }
      } catch(err) {

      }
      }, []) 
      const handleCancel = useCallback(function() {
        refAllow.current = false         
        setStateCodeGe(false) 
        refTimerOTP.current = 0
        props.handleClose()
        if(error) {
            setError('') 
        }
      })
      const handleverifyOTP = useCallback(async function() { 
        try {
          let otpValue =''
          document.querySelectorAll('.otp-input > input[type="text"]').forEach((item)=>{
              otpValue += item.value
          })
          // console.log(otpValue)  
          let verify_result = await verifyOTP({
              otp: parseInt(otpValue) 
          }) 
          // If fetch returns 401 
          if(verify_result.status === 401) {
            const refreshTokenResult = await refreshToken({refresh_token: localStorage.getItem('u_r_t')})

            // If refresh token returns 401
            if(refreshTokenResult.status === 401) {
              killToken()
              return navigate('/login')
            } 

            // If refresh token is successfull 
            const payloadRefreshToken = await refreshTokenResult.json()   

            let tokenPayload = payloadRefreshToken.token.split('.')[1] 

            // Payload of new generated token 
            let new_decoded_payload = JSON.parse(atob(tokenPayload)) 

            if(new_decoded_payload['exp']*1000 < new Date().valueOf())  {
              killToken()
              return navigate('/login') 
            } else {
              saveToken(payloadRefreshToken.token, payloadRefreshToken.refresh_token) 
              verify_result = await verifyOTP({
                otp: parseInt(otpValue) 
              })     
            }

          }
        
          const verify_data = await verify_result.json() 
          refAllow.current = false         
          setStateCodeGe(false) 
          refTimerOTP.current = 0
          if(verify_result.ok) {
              props.handleClose()
              props.handleShowcardDetails()
          } else {
              setError(verify_data['message']) 
          } 
      } catch(err) {

      }     
    }, [])  
  return (
    <Modal show={props.show}  className='topup-modal verifyInfo' backdrop='static' keyboard='false' >
        <div> 
            <figure>
                <img src='/images/dashboard/mycard/freepik--Character--inject-44 (1).svg' alt='2 cards' />
            </figure>
            <div className='verify-card-info'>Verify your card info</div>
            <div className='presstoverify'>Press send button to receive a verification code to your email</div>
            <Button styles='sendotpcode' click={handleTimerStart} disabled={stateCodeGe}>Send OTP</Button> 
            <div className='otpTimer'>{timer}</div>
            <OtpInput length={6} onChange={handleOtpChange}  />
            {error && <div className='errorOTPVerification'>{error}! Please Try Again</div>}     
            <div className='group-btn'>
                <Button click={handleCancel}>Cancel</Button>
                <Button  styles='uns-bal-go-deposit' disabled={!stateCodeGe || (otp.length !== 6)} id='verifyOTP' click={handleverifyOTP}>Verify</Button> 
            </div>
        </div>
                                
                                
   </Modal> 
  )
}

export default VerifyCardInfo
 