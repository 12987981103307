import React, { useState, useCallback, useEffect } from 'react'                                  
import { Modal } from 'react-bootstrap'
import Button from '../components/Button' 
import { cardApproval, refreshToken, killToken, saveToken, suspendOrActivateCard } from '../security/AuthService'                      
import { useNavigate } from 'react-router-dom'
import './CSS/approvecard.css'  
import { refreshTokenWithAPICallHandler } from '../utils/refresh'
import './CSS/suspendcard.css'  

const SuspendCard = ({show, handleClose, isSuspendedByClient, isSuspendedByAdmin, updateSuspendStatus}) => { 
    const [isError, setIsError] = useState(null)

    useEffect(()=>{
        console.log('8')
    }, []) 

    const suspendOrActivateCardHandler = useCallback(async function () {
        try {
            console.log(
                {
                    suspend : !isSuspendedByClient ? 'yes' : 'no'    
                } 
            ) 
            const suspendoractivatecard = await suspendOrActivateCard({
                suspend : !isSuspendedByClient ? 'yes' : 'no'
            })  
            const result_suspendoractivatecard = await suspendoractivatecard.json() 
            console.log(result_suspendoractivatecard) 
            if(suspendoractivatecard.ok && result_suspendoractivatecard['message'] === 'Your card is suspended by Admin') {
                setIsError('Your card is suspended by Admin') 
            } else if(suspendoractivatecard.ok && result_suspendoractivatecard['message'] === 'Card Blocked Successfully') {
                // handleClose()
                updateSuspendStatus(false, false, true) 
            } else if (suspendoractivatecard.ok && result_suspendoractivatecard['message'] === 'Card UnBlocked Successfully') {
                updateSuspendStatus(false, false, false)
            } else if(suspendoractivatecard.ok  && result_suspendoractivatecard['message'] === 'Sorry, We currently could not perform your operation Cannot suspend card more than once in 24 hours. Please try again later') {
                setIsError('Sorry, We currently could not perform your operati…ore than once in 24 hours. Please try again later')  
            }
    
        } catch(err) {
            console.log(err) 
        } 

    }, [isSuspendedByClient])

    const handleCancel = useCallback(async ()=>{
        console.log('88') 
        handleClose()
        await new Promise((resolve, reject)=>{
            setTimeout(()=>{
                resolve(errorVerificationandParentSuspendHandler(isError))   
            }, 150)
        })
    }, [isError])  

    function errorVerificationandParentSuspendHandler (error) {
        console.log(error) 
        if(error === 'Your card is suspended by Admin') {
            updateSuspendStatus(false, true, false) 
        } else if(error === 'Sorry, We currently could not perform your operati…ore than once in 24 hours. Please try again later') {
            updateSuspendStatus(false, isSuspendedByAdmin, isSuspendedByClient)  
            setIsError(null)  
        } else {
            setIsError(null)   
        }
    }
    console.log(isSuspendedByClient) 

    return (
        <Modal show={show} onHide={handleCancel} className='cardSuspend' backdrop='static' keyboard='false'>                            
            <button id='closeVerification' onClick={handleCancel}>  
              <img src='/images/dashboard/2fa/Vector (8).svg' alt='a grey cross mark' />
            </button>
            <div className='cardSuspendWrapper'>
                <figure>
                    <img src='/images/dashboard/mycard/Group 442.png' alt='2 blue cards one in front of the other and at the top right a padlock and behind them several lock washers and padlocks' draggable='false' />  
                </figure>

                {!isError ?
                    <div className='suspendCardTitle'>
                        {!isSuspendedByClient ? 'Are you sure you want to suspend your card?' : 'Are you sure you want to reactivate your card?'}
                    </div> :
                    <div className='suspendCardTitleError'>
                        {isError} 
                    </div>
                }

                {!isError && <div>
                    {
                    !isSuspendedByClient ? 
                    'Suspending your card will temporarily disable all transactions, including purchases and withdrawals. You can reactivate it anytime from your wallet by clicking "Reactivate".' : 
                    'Reactivating your card will restore full access, allowing you to make purchases and transactions instantly.'
                    }
                </div>
                }

            </div> 
            <div className='group-btn'>
                
            <Button id='cancelSuspendCardBtn' click={handleCancel} styles={`${isError ? 'w-100 mw-100' : ''}`}>Cancel</Button> 
            {!isError && <Button id='suspendYourCard' click={suspendOrActivateCardHandler}>
                {!isSuspendedByClient ? 'Suspend My Card' : 'Reactivate your card'}
            </Button>  }         
                
            </div>
        </Modal> 
      )
}

export default SuspendCard 



