// export const API_URL = import.meta.env.API_URL;
// export const API_HOST = import.meta.env.API_HOST;
export function API_URL() {
   // return import.meta.env.API_HOST;
  //return process.env.REACT_APP_API_HOST;
  //prod
  return process.env.REACT_APP_API_URL || "https://api.paytaps.com/api/";
  //dev
  // return process.env.REACT_APP_API_URL || "http://18.194.243.142:8741/api/"
 }

export function API_HOST() {
   // return import.meta.env.API_HOST;
  //return process.env.REACT_APP_API_HOST;
  //prod
  return process.env.REACT_APP_API_HOST || "https://api.paytaps.com/";
  //dev
 // return process.env.REACT_APP_API_HOST || "http://18.194.243.142:8741/" 
} 

