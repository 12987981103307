import React , { useState, useEffect, useRef, useCallback } from 'react'  
import { Modal } from 'react-bootstrap'
import './CSS/showdetails.css'
import Button from '../components/Button'
import OtpInput from '../components/OtpInput' 
import './CSS/verifycardinfo.css'
import { generateOTP, verifyOTP, getLoginType } from '../security/AuthService'   
import { useNavigate } from 'react-router-dom' 
import { refreshTokenWithAPICallHandler } from '../utils/refresh' 
import { useGoogleAuthentication } from '../context/GoogleAuthenticationContext' 
import { Formik, Form } from 'formik'
import * as YUP from 'yup' 
import PasswordInput from '../components/PasswordInput'

const VerifyCardInfo = (props) => {
    const { hasEnabledGoogleAuthentication, enableGoogleAuthenticationHandler } = useGoogleAuthentication() 
    const navigate = useNavigate() 
    const [otp, setOtp] = useState('');
    const [timer, setTimer] = useState(`${localStorage.getItem('o_t_p_t') ? (localStorage.getItem('o_t_p_t').length > 1 ? localStorage.getItem('o_t_p_t')+':00' : '0'+localStorage.getItem('o_t_p_t')+':00') : ''}  `);   
    const [stateCodeGe, setStateCodeGe] = useState(false) 
    const refTimerOTP = useRef(null)
    const refAllow = useRef(false)
    const otpValue = useRef(null)  
    const [error, setError] = useState('') 
    const [loginType, setLoginType] = useState('simple') 

    useEffect(()=>{
        if(refAllow.current) {
          if(timer === '-1:-1') { 
              setTimer(`${localStorage.getItem('o_t_p_t')?.length > 1 ? localStorage.getItem('o_t_p_t')+':00' : '0'+localStorage.getItem('o_t_p_t')+':00'}  `)
          }

          if(stateCodeGe) {
            var x = setInterval(function() {
                // Get today's date and time
                const now = new Date()
                // Find the distance between now and the count down date
                var distance = refTimerOTP.current - now.getTime();
                // Time calculations for minutes and seconds
                var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
                var seconds = Math.floor((distance % (1000 * 60)) / 1000);
                setTimer(`${(''+minutes).length === 1 ? '0' + minutes : minutes}:${(''+seconds).length === 1 ? '0' + seconds : seconds}`) 
                // If the count down is over 
                if (distance < 0) {
                  clearInterval(x);
                  refAllow.current = false  
                  setStateCodeGe(false) 
                  setTimer(`${localStorage.getItem('o_t_p_t')?.length > 1 ? localStorage.getItem('o_t_p_t')+':00' : '0'+localStorage.getItem('o_t_p_t')+':00'}  `) 
                  if(error) {
                    setError('')
                  }   
                }
              }, 1000);
          }
        } 
    }, [timer, stateCodeGe,  props.a]) 

    useEffect(()=>{

      fetchLoginType() 

    }, []) 

    const handleOtpChange = (value) => {
        setOtp(value);
    }; 

    const handleTimerStart = useCallback(async function() {
      try {
        setError('') 
        let generateOTP_result = {
          api_call: await generateOTP()
        }
        const renew = async ()=>{
            return await generateOTP()
        }
        // If fetch returns 401 
        if(generateOTP_result.api_call.status === 401) {
          if(await refreshTokenWithAPICallHandler(generateOTP_result, renew) === 'REDIRECT_TO_LOGIN') {
              return navigate('/login') 
          }
        }
        
        if(generateOTP_result.api_call.ok) {
            refAllow.current = true         
            setStateCodeGe(true)  
            refTimerOTP.current = new Date().getTime() + (parseInt(localStorage.getItem('o_t_p_t')) * 60 * 1000) + 1000
        }
    } catch(err) {
    }
    }, []) 

    const handleCancel = useCallback(function() {
      refAllow.current = false         
      setStateCodeGe(false) 
      refTimerOTP.current = 0
      props.handleClose()
      if(error) {
          setError('') 
      }
    })

    const handleverifyOTP = useCallback(async function() { 
      try {
        let otpValue =''
        document.querySelectorAll('.otp-input > input[type="text"]').forEach((item)=>{
            otpValue += item.value
        })

        let verify_result = {
          api_call: await verifyOTP({
            otp: parseInt(otpValue) 
          })
        }
        const renew = async ()=>{
          return await verifyOTP({
            otp: parseInt(otpValue) 
          })
        }
        // If fetch returns 401 
        if(verify_result.api_call.status === 401) {
          if(await refreshTokenWithAPICallHandler(verify_result, renew) === 'REDIRECT_TO_LOGIN') {
            return navigate('/login') 
          }
        }
        const verify_data = await verify_result.api_call.json() 
        
        refAllow.current = false         
        setStateCodeGe(false) 
        refTimerOTP.current = 0
        if(verify_result.api_call.ok) {
            props.handleClose()
            props.handleShowcardDetails() 
              setError('')  
        } else {
            setError(verify_data['message'])
        } 
        setOtp('')   


      } catch(err) {

    }     
    }, [])  

    const fetchLoginType = async ()=>{
      const fetch_login_type = await getLoginType()
      const result_fetch_login_type = await fetch_login_type.json() 
      setLoginType(result_fetch_login_type['data']['loginType'])    
    }
    
  return (
    <Modal show={props.show}  className='topup-modal verifyInfo' backdrop='static' keyboard='false' >
        <div> 
            <figure>
                <img src='/images/dashboard/mycard/freepik--Character--inject-44 (1).svg' alt='2 cards' />
            </figure>
            <div className='verify-card-info'>{hasEnabledGoogleAuthentication ? 'Enter Google Authenticator Code' : 'Verify your card info'}</div>
            <div className='presstoverify'>
              {loginType === 'simple' ? 
                'Please enter your password to proceed.' : 
                hasEnabledGoogleAuthentication ? 
                'Please enter the 6-digit code from Google Authenticator application to proceed.' :
                'Press send button to receive a verification code to your email'}
              </div>           
            {loginType === 'google' && <>
            {!hasEnabledGoogleAuthentication && <>
            <Button styles='sendotpcode' click={handleTimerStart} disabled={stateCodeGe}>Send OTP</Button> 
            <div className='otpTimer'>{timer}</div></>}
            <OtpInput length={6} onChange={handleOtpChange} /> 
            {error && <div className='errorOTPVerification'>{error}! Please Try Again</div>}     
            <div className='group-btn'>
                <Button click={handleCancel}>Cancel</Button>
                <Button  styles='uns-bal-go-deposit' disabled={hasEnabledGoogleAuthentication ?  (otp.length !== 6) : (!stateCodeGe || (otp.length !== 6)) } id='verifyOTP' click={handleverifyOTP}>Verify</Button> 
            </div>
            </>}
            {loginType === 'simple' && <>
              <Formik
                initialValues={{
                    password: ''
                }}

                validationSchema={YUP.object({
                    password: YUP.string().required('Required!') 
                })}

                onSubmit={ async (values, {setSubmitting, resetForm})=>{
                  try {

                    let verify_result = {
                      api_call: await verifyOTP({
                        otp: values.password 
                      })
                    }
                    // If fetch returns 401 
                    if(verify_result.api_call.status === 401) {
                      const renew = async ()=>{
                        return await verifyOTP({
                          otp: values.password 
                        })
                      } 

                      if(await refreshTokenWithAPICallHandler(verify_result, renew) === 'REDIRECT_TO_LOGIN') {
                        return navigate('/login') 
                      }
                    }
                    const verify_data = await verify_result.api_call.json()

                    if(verify_result.api_call.ok && verify_data['message'] === 'code is valid') {
                      props.handleClose()
                      props.handleShowcardDetails() 
                      if(error) {
                        setError('')  
                      }
                    } else {
                      setError(verify_data['error']) 
                    }

                  } catch(err) {

                  }
                }}
              >
                    
                {(formik) => (
                  <Form className='form' onSubmit={formik.handleSubmit}>
                      <PasswordInput 
                        name='password' 
                        id='password' 
                        passwordName='password' 
                      /> 

                      {error && <div className='errorOTPVerification'>{error}! Please Try Again</div>}     

                      <div className='group-btn'>
                        <Button click={handleCancel}>Cancel</Button>
                        <button type='submit' id='verifyPassword' className='uns-bal-go-deposit' disabled={!(formik.dirty && formik.isValid) || formik.isSubmitting}>Verify</button> 
                      </div> 
                  </Form>
                )}      
              </Formik>
            </>}  
        </div> 
                                               
   </Modal> 
  )
}

export default VerifyCardInfo


