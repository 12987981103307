import React, { useState, useEffect, useCallback } from 'react'  
import Topup from '../Modals/Topup'  
import InsufficientBalance_top_up from '../Modals/InsufficientBalance_top_up' 
import { userBalance, convertToUSDT, getAddressBasedOnChain } from '../security/AuthService' 
import TopupSuccess from '../Modals/TopupSuccess'
import { useReferral } from '../context/ReferralContext'; 
import { refreshTokenWithAPICallHandler } from '../utils/refresh' 
import { useNavigate } from 'react-router-dom'

const TopUpCard = () => {
    const [show, setShow] = useState(false) 
    const [showInsufficientTopupBalance, setShowInsufficientTopupBalance] = useState(false)   
    const [showSuccess, setShowSuccess] = useState(false)                                                                                   
    const { showReferral, handleShowReferral } = useReferral() 
    const navigate = useNavigate() 

    const [showTopupResultMsg, setShowTopupResultMsg] = useState('')  
    
    const [btcAddress, setBtcAddress] = useState('')

    const handleCloseInsufTopupBalance = () => setShowInsufficientTopupBalance(false); 
    const handleShowInsufTopupBalance = () => setShowInsufficientTopupBalance(true);

    useEffect(()=>{
        getBalanceUSDTEUR() 
    }, []) 
    const handleClose = useCallback(function() {
        setShow(false) 
    }, []) 

    const handleCloseTopupSuccess = useCallback(function () {
        setShowSuccess(false) 
    }, [])
    const handleShowTopupSuccess = useCallback(function () {
        setShowSuccess(true)
    }, [])

    async function getBalanceUSDTEUR () {

        // Start of verification if referral should appear or no
            if(!showReferral) {
                handleShowReferral() 
            }
        // End   

        const fetch_address = await getAddressBasedOnChain({network:  'BTC'})                                                                      
        const result_fetch_address = await fetch_address.json()
            
        if(fetch_address.ok && result_fetch_address['message'] === 'success') {
            setBtcAddress(result_fetch_address['data'][0]['wallet_address'])
        }
         
        setShow(true) 
    }

    const showTopupMsgHandler = (msg)=>{
        setShowTopupResultMsg(msg)
    }
    
  return (
    
    <>
        <Topup 
            show={show} 
            handleClose={handleClose} 
            handleShowInsufTopupBalance={handleShowInsufTopupBalance}  
            handleShowTopupSuccess={handleShowTopupSuccess} 
            showTopupMsgHandler={showTopupMsgHandler}
            btcAddress={btcAddress} 
        />                                                          
        <InsufficientBalance_top_up 
            show={showInsufficientTopupBalance} 
            handleCloseInsufTopupBalance={handleCloseInsufTopupBalance} 
            handleShowInsufTopupBalance={handleShowInsufTopupBalance} 
        />   
        <TopupSuccess 
            show={showSuccess} 
            handleClose={handleCloseTopupSuccess} 
            showTopupResultMsg={showTopupResultMsg}
            showTopupMsgHandler={showTopupMsgHandler} 
        />                                                                                                                                                                                                                    
    </>
  )
}

export default TopUpCard  
