import React, { useEffect } from 'react'
import moment from 'moment'
import './CSS/topupvisualizer.css' 
const TopUpVisualizer = (props) => { 
    let path = window.location.pathname;
    path = path.split("/");
    path = path[path.length - 1] 
    useEffect(()=>{
        if(path === 'mycard') {
            if(document.getElementById('topupRW')) {
                document.getElementById('topupRW').style.setProperty('--mHeight', '35px')  
            }                                                                                     
        }
    }, [])  
  return (
    <>
    {(props.data?.length > 0) && props.data !== null && props.data !== undefined  && <div id='topupRW' className='transactions-wrapper-body'> 
        <ul className='transactions-wrapper-body-list'>
            {props.data.map((item, index)=>{
                return (<li key={index} className='transactions-wrapper-body-list-transaction-data-item'>
                            <figure className='transaction-status-image'>
                                {   item['status'].toLowerCase() === "success" ? 
                                    <img src='/images/dashboard/mycard/transaction-green.svg' alt='completed transaction' draggable='false' />  :
                                    (item.status.toLowerCase() === "declined" ? 
                                    (<img src='/images/dashboard/mycard/SVG (5).svg' alt='declined transaction' draggable='false' /> ) :
                                    ((item.status.toLowerCase() === 'pending') ?
                                        (<img src='/images/dashboard/dashboard/SVG (4).svg' alt='pending transaction' draggable='false' />) :
                                        (<img src='/images/dashboard/mycard/transaction-red.svg' alt='pending transaction' draggable='false' />)) )       
                                }
                            </figure>
                            <div>
                                <span className='transaction-origin'>Top-Up Request</span>
                                <div className='transaction-date'><span>{`${moment(item?.createdAt?.date).format("MMM DD, YYYY")}`}</span><span>{`${moment(item?.createdAt?.date).format("HH:mm")}`}</span></div>                       
                                <span className='transaction-value'>{item['currency']=="eur" ? "€" :"$" } {parseFloat(item['amount']).toFixed(2)}</span>   
                                {item.status&&<span className={`transaction-status ${item.status.toLowerCase() !== 'success' ? (item.status.toLowerCase() === 'declined' ? 'declinedColor' : 'pendingColor') : ''}`}>{item.status[0].toUpperCase() + item.status.slice(1)}</span>}  
                            </div>
                        </li>)
                })}
        </ul>
    </div>}
    {
            (props.data?.length === 0 || props.data === null || props.data === undefined ) && <div className='noRecords'>No Records</div>  
        }
    </>
  )
}

export default TopUpVisualizer 

