import React, { useState, useEffect, useRef, useCallback } from 'react' 
import './CSS/walletdeposit.css' 
import { Modal } from 'react-bootstrap'
import { Formik, Form, Field } from 'formik'
import TextInput from '../components/TextInput'
import * as YUP from 'yup'
import Button from '../components/Button'
import Loader from '../jsx/pages/Loader/Loader'
import { useNavigate } from 'react-router-dom'
import { generateAddress, regenerateAddress, normalDeposit, refreshToken, killToken, saveToken } from '../security/AuthService'
import { useActive } from '../context/ActiveContext' 
import QRCodeStyling from "qr-code-styling";  
import ConfirmTermsConditions from './ConfirmTermsConditions'
import Select from '../components/Select' 


import Carousel from 'react-bootstrap/Carousel';                           

const WalletDeposit = (props) => {
    const [showInstruction, setShowInstruction] = useState(false)  
    const handleCloseInstruction = () => {setShowInstruction(false)}  
    const handleShowInstruction = () => setShowInstruction(true) 
    const [isCopied, setIsCopied] = useState(false)
    const [show, setShow] = useState(false)
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    //initialise timer that would be displayed 
    const [timer, setTimer] = useState(`${localStorage.getItem('t_d_d').length > 1 ? localStorage.getItem('t_d_d')+':00' : '0'+localStorage.getItem('t_d_d')+':00'}  `) 

    //State to choose if top up modal should appear or the regeneration
    const [refisTimerEnabled, setRefisTimerEnabled] = useState(true)

    //state to check if the refersh of refresh modal is enabled or not 
    const [requestRefresh, setRequestRefresh] = useState(false)

    //state to stock the address wallet
    const [addressValue, setAddressValue]= useState('')

    //state to check the loading status of wallet address
    const [loadAdd, setLoadAdd] = useState(false)

    // timer that will be uses in the countdown calculation
    const refTimerND = useRef(null)

    // State to contain error if it happens
    const [error, setError] = useState(false)

    // State success to track the success of deposit 
    const [success, setSuccess] = useState(false)

    // Ref to check if deposit has been made or not
    const sendStatus = useRef(false) 

    // Ref cancel to track if a cancel has been made or not 
    const cancel = useRef(false) 

    const navigate = useNavigate() 

    const [termsConditions, setTermsConditions] = useState(false)   
    const [confirm_TC, setConfirm_TC] = useState(false)
    const { handleMenuActive } = useActive()  
    const [accountType, setAccountType] = useState('Blockchain')  
    
    // Generation of address wallet at the initial render
    useEffect(()=>{
        if(props.stateCountDown) {
            generateAddressWallet()
        }
    }, [props.stateCountDown])

    useEffect(()=>{ 

        //Timer Count Down will be started only if stateCountDown is enabled
        if(props.stateCountDown) {
            if(timer === '-1:-1' || !refisTimerEnabled || timer.includes('-')) {
                handleShowrequestRefresh() 
                setTimer(`${localStorage.getItem('t_d_d').length > 1 ? localStorage.getItem('t_d_d')+':00' : '0'+localStorage.getItem('t_d_d')+':00'}  `)
            }
            
            if(refisTimerEnabled && addressValue) {
                var x = setInterval(function() {
                // Get today's date and time
                const now = new Date()

                // Find the distance between now and the count down date
                var distance = refTimerND.current - now.getTime();

                // Time calculations for minutes and seconds
                var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
                var seconds = Math.floor((distance % (1000 * 60)) / 1000);
                setTimer(`${(''+minutes).length === 1 ? '0' + minutes : minutes}:${(''+seconds).length === 1 ? '0' + seconds : seconds}`) 

                // If the count down is over 
                if (distance < 0) {
                    clearInterval(x);
                    setRefisTimerEnabled(false)
                    setRequestRefresh(true)
                    setTermsConditions(false)
                    setConfirm_TC(false) 

                    if(refTimerND.current === 0 && sendStatus.current) {
                        setError(false)
                        setSuccess(true)  
                        return null 
                    } else {    
                        setError(true) 
                        setSuccess(false)  
                    }

                    if(cancel.current) {
                        setRefisTimerEnabled(true)
                    }
                                
                }
                }, 1000); 
            }
        }
    }, [timer, refisTimerEnabled, addressValue, props.stateCountDown, error, success, sendStatus.current, cancel.current]) 

    async function generateAddressWallet() {
        try {
            setLoadAdd(true)
            let result = await generateAddress() 
            // If fetch returns 401 
            if(result.status === 401) {
                const refreshTokenResult = await refreshToken({refresh_token: localStorage.getItem('u_r_t')})

                // If refresh token returns 401
                if(refreshTokenResult.status === 401) {
                killToken()
                return navigate('/login')
                } 

                // If refresh token is successfull 
                const payloadRefreshToken = await refreshTokenResult.json()   

                let tokenPayload = payloadRefreshToken.token.split('.')[1] 

                // Payload of new generated token 
                let new_decoded_payload = JSON.parse(atob(tokenPayload)) 

                if(new_decoded_payload['exp']*1000 < new Date().valueOf())  {
                    killToken()
                    return navigate('/login') 
                } else {
                    saveToken(payloadRefreshToken.token, payloadRefreshToken.refresh_token)               
                    result = await generateAddress() 
                }

            } 

            const message =  await result.json()
            cancel.current = false

             if(result.ok && message.message === 'success' && message.data[0]['value']) {
                setLoadAdd(false)
                setAddressValue(message.data[0]['value'])
                refTimerND.current = new Date().getTime() + (parseInt(localStorage.getItem('t_d_d')) * 60 * 1000) + 1000 
             } else {
                setLoadAdd(false)
                setAddressValue('')
             }

        } catch(err) {
            console.log(err) 
        }
    }  
    
    async function regenerateAddressWallet() {
        try {
            refTimerND.current = 0
            setLoadAdd(true)
            let result = await regenerateAddress() 
            // If fetch returns 401 
            if(result.status === 401) {
                const refreshTokenResult = await refreshToken({refresh_token: localStorage.getItem('u_r_t')})

                // If refresh token returns 401
                if(refreshTokenResult.status === 401) {
                killToken()
                return navigate('/login')
                } 

                // If refresh token is successfull 
                const payloadRefreshToken = await refreshTokenResult.json()   

                let tokenPayload = payloadRefreshToken.token.split('.')[1] 

                // Payload of new generated token 
                let new_decoded_payload = JSON.parse(atob(tokenPayload)) 

                if(new_decoded_payload['exp']*1000 < new Date().valueOf())  {
                    killToken()
                    return navigate('/login') 
                } else {
                    saveToken(payloadRefreshToken.token, payloadRefreshToken.refresh_token)               
                    result = await regenerateAddress()  
                }

            }
            const message =  await result.json()
            setLoadAdd(false)
             if(result.ok && message.message === 'success' && message.data[0]['value']) {
                setAddressValue(message.data[0]['value'])
                refTimerND.current = new Date().getTime() + (parseInt(localStorage.getItem('t_d_d')) * 60 * 1000) + 1000
                setError(false)
             } else {
                setAddressValue('')
             }
        } catch(err) {
            console.log(err) 
        }
    }
    async function copyAddress () {
        try {
            setIsCopied(true)
            await navigator.clipboard.writeText(document.getElementById('wallet-address-value1').innerHTML)
            await new Promise((resolve, reject)=>{
                setTimeout(()=>{
                    resolve(setIsCopied(false))
                }, 2000)
            })
        } catch (err) {
            setIsCopied(false)
        }
    }

    let state
    const keyUpHandler = (e)=>{
        state.setFieldValue(e.target['name'], e.target.value); 
        state.setFieldTouched(e.target['name'])
    }

    const handleShowrequestRefresh = useCallback(function(){
        setRequestRefresh(true)
    }, [])

    const handleCloserequestRefresh = useCallback(function(){
        regenerateAddressWallet()
        props.setRefTrue()
        refTimerND.current = new Date().getTime() + (parseInt(localStorage.getItem('t_d_d')) * 60 * 1000) + 1000
        setRefisTimerEnabled(true); 
        setRequestRefresh(false)
        setShowInstruction(false)
        setAccountType('Blockchain')
    }, []) 
    
    const handleCancel = useCallback(function() {
        let path_ = window.location.pathname;
        path_ = path_.split("/");
        path_ = path_[path_.length - 1]
        if(path_ === 'depositfund') {
            navigate('/dashboard')
            handleMenuActive('Dashboard') 
            handleMenuActive('Dashboard') 

        }

        cancel.current = true
        refTimerND.current = 0; 
        setRequestRefresh(false); 
        setRefisTimerEnabled(true)  
        setTimer(`${localStorage.getItem('t_d_d').length > 1 ? localStorage.getItem('t_d_d')+':00' : '0'+localStorage.getItem('t_d_d')+':00'}  `)  
        sendStatus.current = false
        
        if(props.handleclickDepositStatusOff !== undefined) {
            props.handleclickDepositStatusOff() 
        }                                  
    }, [])

    const handleOKDeposit = useCallback(function() { 

        setRequestRefresh(false); 
        setRefisTimerEnabled(true); 
        setTimer(`${localStorage.getItem('t_d_d').length > 1 ? localStorage.getItem('t_d_d')+':00' : '0'+localStorage.getItem('t_d_d')+':00'}  `) 
        sendStatus.current = false 
        let path = window.location.pathname;
        path = path.split("/");
        path = path[path.length - 1]

    }, []) 

    const generateQRCode = (data) => { 
        const qrCode = new QRCodeStyling({ 
          width: 126, 
          height: 130, 
          data: data,  
          image: "https://paytaps.com/images/dashboard/header/paytaps%20icon.svg", // Your logo  
          dotsOptions: { 
            gradient: { 
              type: "radial",  
              colorStops: [ 
                { offset: 0, color: "#4196FC" },
                { offset: 0.5, color: "#4A61D4" },
                { offset: 1, color: "#5520A3" }    
              ], 
            }, 
            type: "rounded", // Dot shape 
          }, 
          backgroundOptions: { 
            gradient: { 
              type: "radial", // 'linear' or 'radial' gradient 
              colorStops: [ 
                { offset: 0, color: "#ffffff" }, // Center color 
                { offset: 1, color: "#ffffff" }, // Outer color 
              ], 
            }, 
          }, 
          imageOptions: { 
            margin: 5, // Margin around the logo 
            scale: 0.4, // Scale the logo (0.1 to 1.0, where 1.0 is the original size)                                                                 
          }, 
          cornersSquareOptions: { 
            color: "#5520A3", // Color for the corner squares 
            type: "extra-rounded", // Shape: 'square', 'rounded', 'extra-rounded' 
          }, 
          cornersDotOptions: { 
            color: "#5520A3", // Color for the dots inside the corner squares 
            type: "rounded", // Shape: 'square', 'dot', 'rounded' 
          },  
        });       
     
        qrCode.append(document.getElementById("qrcodenormal")); // Append the QR code to the container 
      };

      useEffect(() => { 
        if (addressValue) {  
          generateQRCode(addressValue); // Generate QR code with the fetched data 
        } 
      }, [addressValue]); 

      const handleShowTermsConditions = useCallback(function() {
        setTermsConditions(true) 
      }, [])
    
      const handleCloseTermsConditions = useCallback(function() {
        setTermsConditions(false)   
      }, []) 

      const handleConfirmTermsConditions = function(e) { 
          
          if(!termsConditions) {
            if(confirm_TC) {
              return setConfirm_TC(false) 
            }
            handleShowTermsConditions()    
        } else {
            if(confirm_TC) {
                return setConfirm_TC(false) 
            }
            handleCloseTermsConditions()    
        }

    }  

    const handleAccountType = useCallback(function(payload) {
        setAccountType(payload) 
    }, [])  

    function handleResetForm(e) {
        state.resetForm()
        state.setFieldValue('hash_trx', '')
        state.setFieldValue('uid', '')   
        setTermsConditions(false)
        setConfirm_TC(false)  

    }
  return (
    <>
    {refisTimerEnabled && <><Modal show={props.show} onHide={props.handleClose} className='wallet_deposit' backdrop='static' keyboard='false' >
        
        <Modal.Header>
            <div className='timer'>
                <img src='/images/dashboard/topup/tabler_alarm.svg' alt='blue alarm' />
                <span>{timer}</span>
            </div> 
            <div className='insufficient-balance'>
                Wallet deposit 
            </div> 
        </Modal.Header>
        <Formik
                initialValues={
                    accountType === 'Blockchain' ? 
                    {
                    topup_amount: '',
                    hash_trx: '',
                    uid: null
                    } :
                    {
                    topup_amount: '',
                    uid: '', 
                    hash_trx: null
                    } 
                } 

                validateOnChange={ true }
                validateOnBlur={ true } 
                validationSchema={
                    accountType === 'Blockchain' ?
                    YUP.object({
                        topup_amount: YUP.string().required('Required!').matches(/^[0-9\.]+$/, { message: <span>Please enter a valid topu up amount</span>}).test('normal deposit', `Please enter at least $${localStorage.getItem('m_n_d')}`,  (value, context) =>(parseInt(value))>=parseFloat(localStorage.getItem('m_n_d'))),  
                        hash_trx: YUP.string().required('Required!')  
                    }) 
                    :
                    YUP.object({
                        topup_amount: YUP.string().required('Required!').matches(/^[0-9\.]+$/, { message: <span>Please enter a valid topu up amount</span>}).test('normal deposit', `Please enter at least $${localStorage.getItem('m_n_d')}`,  (value, context) =>(parseInt(value))>=parseFloat(localStorage.getItem('m_n_d'))),  
                        uid: YUP.string().required('Required!').matches(/^[0-9]+$/, { message: <span>Please enter a valid UID</span>}).min(8, 'Please enter a valid UID'), 
                    }) 
                }

                onSubmit={async (values)=>{
                    try {
                        let account_type = `${(accountType === 'Blockchain') ? 'blockchain' : 'ciexAccount'}`   

                        if(account_type === 'blockchain') {
                            let result = await normalDeposit({hashTransaction: values.hash_trx, amount: values.topup_amount, address: addressValue, type: account_type, uidAccount: null}) 
                            // If fetch returns 401 
                            if(result.status === 401) {
                                const refreshTokenResult = await refreshToken({refresh_token: localStorage.getItem('u_r_t')})

                                // If refresh token returns 401
                                if(refreshTokenResult.status === 401) {
                                killToken()
                                return navigate('/login')
                                } 

                                // If refresh token is successfull 
                                const payloadRefreshToken = await refreshTokenResult.json()   

                                let tokenPayload = payloadRefreshToken.token.split('.')[1] 

                                // Payload of new generated token 
                                let new_decoded_payload = JSON.parse(atob(tokenPayload)) 

                                if(new_decoded_payload['exp']*1000 < new Date().valueOf())  {
                                    killToken()
                                    return navigate('/login') 
                                } else {
                                    saveToken(payloadRefreshToken.token, payloadRefreshToken.refresh_token)               
                                    result = await normalDeposit({hashTransaction: values.hash_trx, amount: values.topup_amount, address: addressValue, type: account_type, uidAccount: null})   
                                }

                            }

                            const message = await result.json()
                            if(result.ok && message.message === 'success') {
                                navigate('/thankyoufordeposit')
                                sendStatus.current = true
                                handleMenuActive('') 
                            }
                        } else {
                            let result = await normalDeposit({hashTransaction: null, amount: values.topup_amount, address: addressValue, type: account_type, uidAccount: values.uid}) 
                            // If fetch returns 401 
                            if(result.status === 401) {
                                const refreshTokenResult = await refreshToken({refresh_token: localStorage.getItem('u_r_t')})

                                // If refresh token returns 401
                                if(refreshTokenResult.status === 401) {
                                killToken()
                                return navigate('/login')
                                } 

                                // If refresh token is successfull 
                                const payloadRefreshToken = await refreshTokenResult.json()   

                                let tokenPayload = payloadRefreshToken.token.split('.')[1] 

                                // Payload of new generated token 
                                let new_decoded_payload = JSON.parse(atob(tokenPayload)) 

                                if(new_decoded_payload['exp']*1000 < new Date().valueOf())  {
                                    killToken()
                                    return navigate('/login') 
                                } else {
                                    saveToken(payloadRefreshToken.token, payloadRefreshToken.refresh_token)               
                                    result = await normalDeposit({hashTransaction: null, amount: values.topup_amount, address: addressValue, type: account_type, uidAccount: values.uid})   
                                }

                            }   

                            const message = await result.json()
                            if(result.ok && message.message === 'success') {
                                navigate('/thankyoufordeposit')
                                sendStatus.current = true
                                handleMenuActive('')  
                            }
                        }

                        refTimerND.current = 0
                        props.setRefFalse()
                        setRefisTimerEnabled(true) 
                        setRequestRefresh(false)
                        props.handleClose()
                        setError(false)  
                    } catch(err) {

                    } 
                }} 
                    >

                {(formik) => {
                state = formik
                return (
                    <Form className='form' onSubmit={formik.handleSubmit}>
                        <Select handleAccountType={handleAccountType} handleResetForm={handleResetForm} />  
                        {accountType === 'Blockchain' && <TextInput name='hash_trx' id='hash_trx_wallet-deposit' label='Enter Hash Transaction' 
                        inputtype='text' onKeyUp={keyUpHandler} /> }
                        {accountType === 'CIEx Account' && <TextInput name='uid' id='uid' label='Enter Account UID'        
                        inputtype='text' onKeyUp={keyUpHandler} click={handleShowInstruction} /> }   
                        <TextInput name='topup_amount' id='topup_amount' label='Enter amount' 
                        inputtype='text' onKeyUp={keyUpHandler} currency='$' />  
                            


                        {!loadAdd && <div className='wallet-address-wrapper'>
                            <div className='use-wallet-address-provided-for-deposit'>Use the wallet address provided below to make the deposit.</div> 
                            <div id='qrcodenormal'></div>
                            <div>
                                <div>
                                    <img src='/images/dashboard/topup/tabler_wallet.svg' alt='wallet' />
                                    <div className='wallet-address'>
                                        <span>Wallet address (TRC20)</span>
                                        <span id='wallet-address-value1'>{addressValue}</span>
                                    </div>
                                </div>
                                <button className='copy-btn' type='button' onClick={copyAddress}> 
                                    <img src='/images/dashboard/topup/tabler_copy.svg' 
                                    alt='2 squares with the highest one its right half clipped' hidden={isCopied} /> 
                                    <span hidden={!isCopied}>Copied!</span>
                                </button>
                            </div>
                        </div>}
                        {loadAdd && <div className='d-flex justify-content-center'><Loader /></div>} 
                            
                        <div className='check-term-conditions'>
                                <Field type="checkbox" name="agree_terms_conditions_normal_deposit" checked={termsConditions || confirm_TC}  onClick={handleConfirmTermsConditions} />   
                                <span style={{color: 'var(--black-color)'}}> I Agree to the Terms and Conditions.</span>  
                        </div>

                        <div className='group-btn'> 
                            <Button click={handleCancel} id="cancel">Cancel</Button>                                       
                            {accountType === 'Blockchain' && <Button btnType='submit' id='confirm_wallet_normal_deposit_hash' disabled={(!document.querySelector('input[name="agree_terms_conditions_normal_deposit"]')?.checked) || !( formik.isValid) || formik.isSubmitting || !refisTimerEnabled}>Confirm</Button>  }
                            {accountType === 'CIEx Account' && <Button btnType='submit' id='confirm_wallet_normal_deposit_uid' disabled={(!document.querySelector('input[name="agree_terms_conditions_normal_deposit"]')?.checked) || !( formik.isValid) || formik.isSubmitting || !refisTimerEnabled}>Confirm</Button> }   
                        </div>                                
                        
                    </Form>)
                }}
        </Formik>
    
    </Modal>

    <Modal show={showInstruction} onHide={handleCloseInstruction} className='showCiexAccount' id='depositThroughCIExNormalDeposit'>
        <Modal.Header closeButton>
          <Modal.Title>CIEx account</Modal.Title>
          <img src='/images/dashboard/userguide/Frame 20.svg' alt='6 screenshots showing steps to withdraw crypto on ciex app' />   
          <Carousel fade interval={null} id='withdrawSteps'>
            <Carousel.Item>
              <img src='/images/dashboard/userguide/1.svg' alt='an interface with 5 menus, with a blue line under overview, deposit button and a rectangle with yellow borders around withdraw button ' />
            </Carousel.Item>
            <Carousel.Item>
            <img src='/images/dashboard/userguide/2.svg' alt="an interface with 5 menus, with a blue line under overview, total balance and today\'s PNL values are hidden with asterisks and a rectangle with yellow borders around USDT " />  

            </Carousel.Item>
            <Carousel.Item>
            <img src='/images/dashboard/userguide/3.svg' alt='an interface with 5 menus, with a blue line under overview, at the bottom of it; an area with title withdraw and cancel button and a rectangle with yellow borders around the rectangle with title send via crypto network, below it a rectangle with title send via email/phone/UID' /> 

            </Carousel.Item> 
            <Carousel.Item>
              <img src='/images/dashboard/userguide/4.svg' alt='an interface with main title withdraw, containing a form, into it a label of chain name underneath of it erc-20, trc-20 surrounded by a rectangle with yellow borders , bep-20 and with other fields; address, amount, ... below it other details about the withdraw and a confirm button' />
            </Carousel.Item>
            <Carousel.Item>
            <img src='/images/dashboard/userguide/5.svg' alt='an interface with main title withdraw, containing a form, into it a label of chain name underneath of it erc-20, trc-20, bep-20, a filled adrress field below it a rectangle with yellow borders containing UID:32235592, below it other details about the withdraw and a confirm button' />

            </Carousel.Item>
            <Carousel.Item>
            <img src='/images/dashboard/userguide/6.svg' alt='an interface with main title withdraw, containing a form, within it a field with label send mode and value UID, below it a field with label UID and value 32235592 surrounded by a rectangle with yellow borders, below this field a message starting with this is the UID corresponding to the address..., and in the bottom a confirm button surrounded by a recatngle with yellow borders' /> 

            </Carousel.Item>
          </Carousel> 
        </Modal.Header>
      </Modal>
      </>
    }

    {!refisTimerEnabled && 
        <Modal show={requestRefresh} className='loader-error' backdrop='static' keyboard='false'>
            <Modal.Body className='center'>
                {(error && !success) && <div className='errorMessageContainer'> 
                    <figure><img src='/images/dashboard/topup/refresh.svg' width='150' /></figure>
                    <div>Times out</div><div className='messageError'>It seems your session has timed out. Please refresh the page to continue. If you need assistance, feel free to contact our support team.</div> 
                    <Button styles= 'try-again-btn' click={handleCloserequestRefresh}>Refresh</Button>
                    </div>
                }

                {(success && !error) && <div>
                    <div>
                        <div className='thankyoudepositContainer'> 
                            <figure><img src='/images/dashboard/topup/Wallet-pana 1 (1).svg' /></figure>
                            <div>Thank you for Deposit</div><div className='messagethankyoudeposit'>thank you for you Paytaps wallet deposit
                            you'll receive and email notification once your deposit is confirmed
                            </div> 
                            <Button styles= 'okThankyouDeposit' click={handleOKDeposit}>Ok</Button>
                        </div>
                    </div> 
                </div>}
            </Modal.Body>
        </Modal>
    }

    <ConfirmTermsConditions show={termsConditions} handleShowTermsConditions={handleShowTermsConditions} handleCloseTermsConditions={handleCloseTermsConditions} confirm_TC={()=>setConfirm_TC(true)} />
    </>
  )
}

export default WalletDeposit  
 