import React, { lazy } from 'react'

/// React router dom
import {Route, createBrowserRouter, createRoutesFromElements, redirect } from 'react-router-dom'
import { userBalance, findRequestCard, getAllDeposits, killToken, refreshToken, saveToken } from '../security/AuthService'               


/// Css
import './index.css'
import './chart.css'
import './step.css'

/// Layout
// import Nav from './layouts/nav'
// import Footer from './layouts/Footer'
// import ThemeContext from '../context/ThemeContext';

/// Dashboard
// import Home from './components/Dashboard/Home'



/// Widget
// import Widget from './pages/Widget'

/// Table
// import DataTable from './components/table/DataTable'
// import BootstrapTable from './components/table/BootstrapTable'
// import SortingTable from "./components/table/SortingTable/SortingTable";
// import FilteringTable from "./components/table/FilteringTable/FilteringTable";


/// Form
//import WizardForm from "./components/Forms/ReduxWizard/Index";
// import Element from './components/Forms/Element/Element'
// import Wizard from './components/Forms/Wizard/Wizard'
// import CkEditor from './components/Forms/CkEditor/CkEditor'
// import Pickers from './components/Forms/Pickers/Pickers'
// import FormValidation from './components/Forms/FormValidation/FormValidation'

/// Pages
//import Registration from './pages/Registration'
//import Login from './pages/Login'
import LockScreen from './pages/LockScreen'
import Error400 from './pages/Error400'
import Error403 from './pages/Error403'
import Error404 from './pages/Error404'
import Error500 from './pages/Error500'
import Error503 from './pages/Error503'
// import Todo from './pages/Todo';
// import Main, {loader} from '../Main' 

//Scroll To Top
// import ScrollToTop from './layouts/ScrollToTop';
import MainLayout from '../jsx/index'
// import Login from './pages/Login'

import Lplayout from '../landingpage/Lplayout'

import LpBody from '../landingpage/LPBody/LpBody'

// const SignUp = lazy(() => import('../jsx/pages/Registration'));
// import Registration from './pages/Registration'
import Registration from '../Registration/Register'


import Login from '../login/Login'

import MyCard from '../dashboard/MyCard'
import Dashboard from '../dashboard/Dashboard'
import MyInfo from '../dashboard/MyInfo'
import OrderCard from '../dashboard/OrderCard'
import ThankYouOrderCard from '../dashboard/ThankYouOrderCard' 
import ThankYouForDeposit from '../dashboard/ThankYouForDeposit'
import ErrorPage from '../dashboard/ErrorPage' 
import Contact from '../dashboard/Contact' 
import DepositFund from '../dashboard/DepositFund'  
import ResetPasswortEmail from '../resetpassword/ResetPasswortEmail'
import ResetPasswordNewPassword from '../resetpassword/ResetPasswordNewPassword' 
import ResetPasswordOTP from '../resetpassword/ResetPasswordOTP'   
import ResetPasswordPage from '../inresetpassword/ResetPasswordPage'  
import ResetPasswordSuccess from '../inresetpassword/ResetPasswordSuccess' 
import TopUpCard from '../dashboard/TopUpCard'   
import Instruction from '../dashboard/Instructions'  
import TransactionsHistory from '../dashboard/TransactionsHistory'
// import WelcomeReferral from '../dashboard/WelcomeReferral'            
const ForgotPassword = lazy(() => import('../jsx/pages/ForgotPassword'));

const allroutes = [
    /// Dashboard
    // { url: '', component: <Home/> },   
    // { url: '/welcomereferral', component: <WelcomeReferral/> }, 
    { url: 'transactionshistory', component: <TransactionsHistory/> , loader: async ()=> {
       
      try {
        if(localStorage.getItem('u_t_p')) {
          const decoded_payload = JSON.parse(atob(localStorage.getItem('u_t_p'))) 
          if(decoded_payload['exp']*1000 < new Date().valueOf()) { 

            const resultRefresh = await refreshToken({refresh_token: localStorage.getItem('u_r_t')})

            if(resultRefresh.status === 401) {
              killToken()
              return redirect('/login') 
            }

            const result = await resultRefresh.json() 

            let tokenPayload = result.token.split('.')[1] 

            let new_decoded_payload = JSON.parse(atob(tokenPayload)) 
            
            if(new_decoded_payload['exp']*1000 < new Date().valueOf())  {
              killToken()
              return redirect('/login') 
            } else {
              saveToken(result.token, result.refresh_token) 
              // return null  
            } 

          }

          let fetch_deposits = await getAllDeposits() 
          const result = await fetch_deposits.json()

          if(result['data'].length > 0) { 
            return null
          } else {
            return redirect('/dashboard') 
          } 
          
        } else {
          return redirect('/login') 
        }
      } catch(err) {

      }
    } },
    { url: 'userguide', component: <Instruction/> , loader: async ()=> {
       
      try {
        if(localStorage.getItem('u_t_p')) {
          const decoded_payload = JSON.parse(atob(localStorage.getItem('u_t_p'))) 
          if(decoded_payload['exp']*1000 < new Date().valueOf()) { 

            const resultRefresh = await refreshToken({refresh_token: localStorage.getItem('u_r_t')})

            if(resultRefresh.status === 401) {
              killToken()
              return redirect('/login') 
            }

            const result = await resultRefresh.json() 

            let tokenPayload = result.token.split('.')[1] 

            let new_decoded_payload = JSON.parse(atob(tokenPayload)) 
            
            if(new_decoded_payload['exp']*1000 < new Date().valueOf())  {
              killToken()
              return redirect('/login') 
            } else {
              saveToken(result.token, result.refresh_token) 
              // return null  
            } 

          }
  
          return null 
        } else {
          return redirect('/login') 
        }
      } catch(err) {

      }
    } },   
    { url: 'topupcard', component: <TopUpCard />, loader: async ()=> {
    
      try {
        if(localStorage.getItem('u_t_p')) {
          const decoded_payload = JSON.parse(atob(localStorage.getItem('u_t_p'))) 
          if(decoded_payload['exp']*1000 < new Date().valueOf()) {

            const resultRefresh = await refreshToken({refresh_token: localStorage.getItem('u_r_t')})

            if(resultRefresh.status === 401) {
              killToken()
              return redirect('/login') 
            }

            const result = await resultRefresh.json() 

            let tokenPayload = result.token.split('.')[1] 

            let new_decoded_payload = JSON.parse(atob(tokenPayload)) 

            if(new_decoded_payload['exp']*1000 < new Date().valueOf())  {
              killToken()
              return redirect('/login') 
            } else {
              saveToken(result.token, result.refresh_token) 
              // return null  
            } 
            
          }
  
          let resultFindRequest = await findRequestCard()
          // If fetch returns 401 
          if(resultFindRequest.status === 401) {
            const refreshTokenResult = await refreshToken({refresh_token: localStorage.getItem('u_r_t')})

            // If refresh token returns 401
            if(refreshTokenResult.status === 401) {
              killToken()
              return redirect('/login')
            } 

            // If refresh token is successfull 
            const payloadRefreshToken = await refreshTokenResult.json()   

            let tokenPayload = payloadRefreshToken.token.split('.')[1] 

            // Payload of new generated token 
            let new_decoded_payload = JSON.parse(atob(tokenPayload)) 

            if(new_decoded_payload['exp']*1000 < new Date().valueOf())  {
              killToken()
              return redirect('/login') 
            } else {
              saveToken(payloadRefreshToken.token, payloadRefreshToken.refresh_token) 
              resultFindRequest = await findRequestCard()    
            }

          }
          const requestCardStatus = await resultFindRequest.json()
          if(requestCardStatus.data.length > 0) {
            if(requestCardStatus.data[0].requesteStatus === 'Success') {
              return null 
            } else if(requestCardStatus.data[0].requesteStatus === 'Pending') {
              return redirect('/thankyouforcardorder') 
            }
          } else {
            return redirect('/ordercard') 
          }
        } else {
          return redirect('/login') 
        }
      } catch(err) {

      }
   }},    
    { url: 'successresetpassword', component: <ResetPasswordSuccess />, loader: async ()=> {
       
      try {
        if(localStorage.getItem('u_t_p')) {
          const decoded_payload = JSON.parse(atob(localStorage.getItem('u_t_p'))) 
          if(decoded_payload['exp']*1000 < new Date().valueOf()) { 

            const resultRefresh = await refreshToken({refresh_token: localStorage.getItem('u_r_t')})

            if(resultRefresh.status === 401) {
              killToken()
              return redirect('/login') 
            }

            const result = await resultRefresh.json() 

            let tokenPayload = result.token.split('.')[1] 

            let new_decoded_payload = JSON.parse(atob(tokenPayload)) 
            
            if(new_decoded_payload['exp']*1000 < new Date().valueOf())  {
              killToken()
              return redirect('/login') 
            } else {
              saveToken(result.token, result.refresh_token) 
              // return null  
            } 

          }
  
          return null 
        } else {
          return redirect('/login') 
        }
      } catch(err) {

      }
    }}, 
    { url: 'loggedresetpassword', component: <ResetPasswordPage />, loader: async ()=> {
      
      try {
        if(localStorage.getItem('u_t_p')) {
          const decoded_payload = JSON.parse(atob(localStorage.getItem('u_t_p'))) 
          if(decoded_payload['exp']*1000 < new Date().valueOf()) {

            const resultRefresh = await refreshToken({refresh_token: localStorage.getItem('u_r_t')})

            if(resultRefresh.status === 401) {
              killToken()
              return redirect('/login') 
            }

            const result = await resultRefresh.json() 

            let tokenPayload = result.token.split('.')[1] 

            let new_decoded_payload = JSON.parse(atob(tokenPayload)) 
            
            if(new_decoded_payload['exp']*1000 < new Date().valueOf())  {
              killToken()
              return redirect('/login') 
            } else {
              saveToken(result.token, result.refresh_token) 
              // return null  
            } 

          }
  
          return null
        } else {
          return redirect('/login') 
        }
      } catch(err) {

      }
    }}, 
    { url: 'depositfund', component: <DepositFund />, loader: async ()=>{
       
      try {
        if(localStorage.getItem('u_t_p')) {
          const decoded_payload = JSON.parse(atob(localStorage.getItem('u_t_p'))) 
          if(decoded_payload['exp']*1000 < new Date().valueOf()) { 

            const resultRefresh = await refreshToken({refresh_token: localStorage.getItem('u_r_t')})

            if(resultRefresh.status === 401) {
              killToken()
              return redirect('/login') 
            }

            const result = await resultRefresh.json() 

            let tokenPayload = result.token.split('.')[1] 

            let new_decoded_payload = JSON.parse(atob(tokenPayload)) 
            
            if(new_decoded_payload['exp']*1000 < new Date().valueOf())  {
              killToken()
              return redirect('/login') 
            } else {
              saveToken(result.token, result.refresh_token) 
              // return null  
            } 

          } 
  
          let resultBalance = await userBalance() 
          // If fetch returns 401 
          if(resultBalance.status === 401) {
            const refreshTokenResult = await refreshToken({refresh_token: localStorage.getItem('u_r_t')})

            // If refresh token returns 401
            if(refreshTokenResult.status === 401) {
              killToken()
              return redirect('/login')
            } 

            // If refresh token is successfull 
            const payloadRefreshToken = await refreshTokenResult.json()   

            let tokenPayload = payloadRefreshToken.token.split('.')[1] 

            // Payload of new generated token 
            let new_decoded_payload = JSON.parse(atob(tokenPayload)) 

            if(new_decoded_payload['exp']*1000 < new Date().valueOf())  {
              killToken()
              return redirect('/login') 
            } else {
              saveToken(payloadRefreshToken.token, payloadRefreshToken.refresh_token)
              resultBalance = await userBalance() 
            }

          }

          const balance = await resultBalance.json()

          let resultFindRequest = await findRequestCard() 
          // If fetch returns 401 
          if(resultFindRequest.status === 401) {
            const refreshTokenResult = await refreshToken({refresh_token: localStorage.getItem('u_r_t')})

            // If refresh token returns 401
            if(refreshTokenResult.status === 401) {
              killToken()
              return redirect('/login')
            } 

            // If refresh token is successfull 
            const payloadRefreshToken = await refreshTokenResult.json()   

            let tokenPayload = payloadRefreshToken.token.split('.')[1] 

            // Payload of new generated token 
            let new_decoded_payload = JSON.parse(atob(tokenPayload)) 

            if(new_decoded_payload['exp']*1000 < new Date().valueOf())  {
              killToken()
              return redirect('/login') 
            } else {
              saveToken(payloadRefreshToken.token, payloadRefreshToken.refresh_token)
              resultFindRequest = await findRequestCard() 
            }

          }

          const requestCardStatus = await resultFindRequest.json()
          //console.log(balance.data[0].balance, requestCardStatus) 
          let result = await getAllDeposits()  
          // If fetch returns 401 
          if(result.status === 401) {
            const refreshTokenResult = await refreshToken({refresh_token: localStorage.getItem('u_r_t')})

            // If refresh token returns 401
            if(refreshTokenResult.status === 401) {
              killToken()
              return redirect('/login')
            } 

            // If refresh token is successfull 
            const payloadRefreshToken = await refreshTokenResult.json()   

            let tokenPayload = payloadRefreshToken.token.split('.')[1] 

            // Payload of new generated token 
            let new_decoded_payload = JSON.parse(atob(tokenPayload)) 

            if(new_decoded_payload['exp']*1000 < new Date().valueOf())  {
              killToken()
              return redirect('/login') 
            } else {
              saveToken(payloadRefreshToken.token, payloadRefreshToken.refresh_token)
              result = await getAllDeposits()  
            }

          }

          const res = await result.json()
          //console.log(res) 
          if(requestCardStatus.data.length === 0) {
            if(res['data'].length > 0) {
            if((res['data'].length === 1 && res['data'][0]['status'] === 'Pending')) {
              return redirect('/thankyoufordeposit')
            } else {
              return {normal_deposit: true}
            }
          } else {
            return balance.data[0].balance 
          }
          } else if(requestCardStatus.data.length >= 1) {
            return {normal_deposit: true} 
          } 
          
        } else {
          return redirect('/login') 
        }
      } catch(err) {

      }
      
        }},
        // return null},} 
    { url: 'contactus', component: <Contact />, loader: async ()=> {
      
      try {
        if(localStorage.getItem('u_t_p')) { 
          const decoded_payload = JSON.parse(atob(localStorage.getItem('u_t_p'))) 
          if(decoded_payload['exp']*1000 < new Date().valueOf()) {
              const resultRefresh = await refreshToken({refresh_token: localStorage.getItem('u_r_t')})

              if(resultRefresh.status === 401) {
                killToken()
                return redirect('/login') 
              }

              const result = await resultRefresh.json() 

              let tokenPayload = result.token.split('.')[1] 

              let new_decoded_payload = JSON.parse(atob(tokenPayload)) 
              
              if(new_decoded_payload['exp']*1000 < new Date().valueOf())  {
                killToken()
                return redirect('/login') 
              } else {
                saveToken(result.token, result.refresh_token) 
                // return null  
              } 

          } 
  
          return null
        } else {
          return redirect('/login') 
        }
      } catch(err) {

      }
    }}, 
    { url: 'thankyoufordeposit', component: <ThankYouForDeposit />, loader: async ()=> {
       
      try {
        if(localStorage.getItem('u_t_p')) { 
          const decoded_payload = JSON.parse(atob(localStorage.getItem('u_t_p'))) 
          if(decoded_payload['exp']*1000 < new Date().valueOf()) {
              const resultRefresh = await refreshToken({refresh_token: localStorage.getItem('u_r_t')})

              if(resultRefresh.status === 401) {
                killToken()
                return redirect('/login') 
              }

              const result = await resultRefresh.json() 

              let tokenPayload = result.token.split('.')[1] 

              let new_decoded_payload = JSON.parse(atob(tokenPayload)) 
              
              if(new_decoded_payload['exp']*1000 < new Date().valueOf())  {
                killToken()
                return redirect('/login') 
              } else {
                saveToken(result.token, result.refresh_token) 
                // return null  
              } 

          }
  
          return null 
        } else {
          return redirect('/login') 
        } 
      } catch(err) {

      }
    }},
    { url: 'thankyouforcardorder', component: <ThankYouOrderCard />, loader: async ()=> {
       
      try {
        if(localStorage.getItem('u_t_p')) { 
          const decoded_payload = JSON.parse(atob(localStorage.getItem('u_t_p'))) 
          if(decoded_payload['exp']*1000 < new Date().valueOf()) {
              const resultRefresh = await refreshToken({refresh_token: localStorage.getItem('u_r_t')})

              if(resultRefresh.status === 401) {
                killToken()
                return redirect('/login') 
              }

              const result = await resultRefresh.json() 

              let tokenPayload = result.token.split('.')[1] 

              let new_decoded_payload = JSON.parse(atob(tokenPayload)) 
              
              if(new_decoded_payload['exp']*1000 < new Date().valueOf())  {
                killToken()
                return redirect('/login') 
              } else {
                saveToken(result.token, result.refresh_token) 
                // return null  
              }

          } 
  
          return null
        } else {
          return redirect('/login') 
        }
      } catch(err) {

      }
    }}, 
    { url: 'ordercard', component: <OrderCard />, loader: async ()=>{
      
        try {
          if(localStorage.getItem('u_t_p')) {
            const decoded_payload = JSON.parse(atob(localStorage.getItem('u_t_p'))) 
            if(decoded_payload['exp']*1000 < new Date().valueOf()) { 
              const resultRefresh = await refreshToken({refresh_token: localStorage.getItem('u_r_t')})

              if(resultRefresh.status === 401) {
                killToken()
                return redirect('/login') 
              }

              const result = await resultRefresh.json() 

              let tokenPayload = result.token.split('.')[1] 

              let new_decoded_payload = JSON.parse(atob(tokenPayload)) 
              
              if(new_decoded_payload['exp']*1000 < new Date().valueOf())  {
                killToken()
                return redirect('/login') 
              } else {
                saveToken(result.token, result.refresh_token) 
                // return null  
              }
            }
    
            let resultBalance = await userBalance()
            // // If fetch returns 401 
            // if(resultBalance.status === 401) {
            //   const refreshTokenResult = await refreshToken({refresh_token: localStorage.getItem('u_r_t')})

            //   // If refresh token returns 401
            //   if(refreshTokenResult.status === 401) {
            //     killToken()
            //     return redirect('/login')
            //   } 

            //   // If refresh token is successfull 
            //   const payloadRefreshToken = await refreshTokenResult.json()   

            //   let tokenPayload = payloadRefreshToken.token.split('.')[1] 

            //   // Payload of new generated token 
            //   let new_decoded_payload = JSON.parse(atob(tokenPayload)) 

            //   if(new_decoded_payload['exp']*1000 < new Date().valueOf())  {
            //     killToken()
            //     return redirect('/login') 
            //   } else {
            //     saveToken(payloadRefreshToken.token, payloadRefreshToken.refresh_token)
            //     resultBalance = await userBalance()  
            //   }

            // }

            const balance = await resultBalance.json()

            let resultFindRequest = await findRequestCard()
            // // If fetch returns 401 
            // if(resultFindRequest.status === 401) {
            //   const refreshTokenResult = await refreshToken({refresh_token: localStorage.getItem('u_r_t')})

            //   // If refresh token returns 401
            //   if(refreshTokenResult.status === 401) {
            //     killToken()
            //     return redirect('/login')
            //   } 

            //   // If refresh token is successfull 
            //   const payloadRefreshToken = await refreshTokenResult.json()   

            //   let tokenPayload = payloadRefreshToken.token.split('.')[1] 

            //   // Payload of new generated token 
            //   let new_decoded_payload = JSON.parse(atob(tokenPayload)) 

            //   if(new_decoded_payload['exp']*1000 < new Date().valueOf())  {
            //     killToken()
            //     return redirect('/login') 
            //   } else {
            //     saveToken(payloadRefreshToken.token, payloadRefreshToken.refresh_token)
            //     resultFindRequest = await findRequestCard() 
            //   }

            // }

            const requestCardStatus = await resultFindRequest.json()

            let result = await getAllDeposits() 
            // // If fetch returns 401 
            // if(result.status === 401) {
            //   const refreshTokenResult = await refreshToken({refresh_token: localStorage.getItem('u_r_t')})

            //   // If refresh token returns 401
            //   if(refreshTokenResult.status === 401) {
            //     killToken()
            //     return redirect('/login')
            //   } 

            //   // If refresh token is successfull 
            //   const payloadRefreshToken = await refreshTokenResult.json()   

            //   let tokenPayload = payloadRefreshToken.token.split('.')[1] 

            //   // Payload of new generated token 
            //   let new_decoded_payload = JSON.parse(atob(tokenPayload)) 

            //   if(new_decoded_payload['exp']*1000 < new Date().valueOf())  {
            //     killToken()
            //     return redirect('/login') 
            //   } else {
            //     saveToken(payloadRefreshToken.token, payloadRefreshToken.refresh_token)
            //     result = await getAllDeposits()   
            //   }

            // }

            const res = await result.json()
            // console.log(res, requestCardStatus, balance, decoded_payload['req'], balance.data[0].balance)     
            if(decoded_payload['req'] === 'S') {
              return redirect('/mycard')
            }
            if(decoded_payload['req'] === 'P') {
              return redirect('/thankyouforcardorder')
            }
            // console.log(parseFloat(localStorage.getItem('m_f_d')))  


            if(requestCardStatus.data.length === 0) {

              if(res['data'].length > 0) {

                if((res['data'].length === 1 && res['data'][0]['status'] === 'Pending')) {
                  return redirect('/thankyoufordeposit') 
                }
                if(balance.data[0].balance >= parseFloat(localStorage.getItem('m_f_d')) && res['data'][0]['status'] === 'Success' && res['data'].length === 1) {
                  return  `${balance.data[0].balance}`
                }
                // UPDATE 
                return null 
              } else {
                return balance.data[0].balance
              }
              
            } else if(requestCardStatus.data.length >= 1) {
              if(requestCardStatus.data[0].requesteStatus === 'Pending') {
                return redirect('/thankyouforcardorder') 
              } else if(requestCardStatus.data[0].requesteStatus === 'Success') {
                return redirect('/mycard') 
              }
              return {balance: balance.data[0].balance, status: requestCardStatus.data[0].requesteStatus}
            } else {
              return null
            } 
            } else {
              return redirect('/login')
            }
        } catch(err) {

        }
        }


    },

    { url: 'profile', component: <MyInfo/>  , loader: async ()=>{
      

      try {
        if(localStorage.getItem('u_t_p')) {  
          const decoded_payload = JSON.parse(atob(localStorage.getItem('u_t_p'))) 
          if(decoded_payload['exp']*1000 < new Date().valueOf()) { 
              const resultRefresh = await refreshToken({refresh_token: localStorage.getItem('u_r_t')})

              if(resultRefresh.status === 401) {
                killToken()
                return redirect('/login') 
              }

              const result = await resultRefresh.json() 

              let tokenPayload = result.token.split('.')[1] 

              let new_decoded_payload = JSON.parse(atob(tokenPayload)) 
              
              if(new_decoded_payload['exp']*1000 < new Date().valueOf())  {
                killToken()
                return redirect('/login') 
              } else {
                saveToken(result.token, result.refresh_token) 
                // return null  
              } 
          }
           
          let resultFindRequest = await findRequestCard() 
          // If fetch returns 401 
          if(resultFindRequest.status === 401) {
            const refreshTokenResult = await refreshToken({refresh_token: localStorage.getItem('u_r_t')})

            // If refresh token returns 401
            if(refreshTokenResult.status === 401) {
              killToken()
              return redirect('/login')
            } 

            // If refresh token is successfull 
            const payloadRefreshToken = await refreshTokenResult.json()   

            let tokenPayload = payloadRefreshToken.token.split('.')[1] 

            // Payload of new generated token 
            let new_decoded_payload = JSON.parse(atob(tokenPayload)) 

            if(new_decoded_payload['exp']*1000 < new Date().valueOf())  {
              killToken()
              return redirect('/login') 
            } else {
              saveToken(payloadRefreshToken.token, payloadRefreshToken.refresh_token)
              resultFindRequest = await findRequestCard() 
            }

          } 

          const requestCardStatus = await resultFindRequest.json()
          if(requestCardStatus.data.length === 0) {
            return redirect('/ordercard')
          } else if(requestCardStatus.data.length === 1) {
            if(requestCardStatus.data[0].requesteStatus === 'Pending') {
              return redirect('/thankyouforcardorder') 
            } else if(requestCardStatus.data[0]['requesteStatus'] === 'Success') {
              return null 
            }
          }
          return null
        } else {
          return redirect('/login')
        } 
      } catch(err) {

      }
       
    }},
    { url: 'dashboard', component: <Dashboard/>, loader: async ()=>{
       
        try {
          if(localStorage.getItem('u_t_p')) {
            const decoded_payload = JSON.parse(atob(localStorage.getItem('u_t_p'))) 
            if(decoded_payload['exp']*1000 < new Date().valueOf()) {                                                                       
              const resultRefresh = await refreshToken({refresh_token: localStorage.getItem('u_r_t')})

              if(resultRefresh.status === 401) {
                killToken()
                return redirect('/login') 
              }

              const result = await resultRefresh.json() 

              let tokenPayload = result.token.split('.')[1] 

              let new_decoded_payload = JSON.parse(atob(tokenPayload)) 
              
              if(new_decoded_payload['exp']*1000 < new Date().valueOf())  {
                killToken()
                return redirect('/login') 
              } else {
                saveToken(result.token, result.refresh_token) 
                // return null  
              }
            }
    
            let resultBalance = await userBalance()
            // If fetch returns 401 
            if(resultBalance.status === 401) {
              const refreshTokenResult = await refreshToken({refresh_token: localStorage.getItem('u_r_t')})

              // If refresh token returns 401
              if(refreshTokenResult.status === 401) {
                killToken()
                return redirect('/login')
              } 

              // If refresh token is successfull 
              const payloadRefreshToken = await refreshTokenResult.json()   

              let tokenPayload = payloadRefreshToken.token.split('.')[1] 

              // Payload of new generated token 
              let new_decoded_payload = JSON.parse(atob(tokenPayload)) 

              if(new_decoded_payload['exp']*1000 < new Date().valueOf())  {
                killToken()
                return redirect('/login') 
              } else {
                saveToken(payloadRefreshToken.token, payloadRefreshToken.refresh_token)
                resultBalance = await userBalance() 
              }

            }

            const balance = await resultBalance.json()

            let resultFindRequest = await findRequestCard() 
            // If fetch returns 401 
            if(resultFindRequest.status === 401) {
              const refreshTokenResult = await refreshToken({refresh_token: localStorage.getItem('u_r_t')})

              // If refresh token returns 401
              if(refreshTokenResult.status === 401) {
                killToken()
                return redirect('/login')
              } 

              // If refresh token is successfull 
              const payloadRefreshToken = await refreshTokenResult.json()   

              let tokenPayload = payloadRefreshToken.token.split('.')[1] 

              // Payload of new generated token 
              let new_decoded_payload = JSON.parse(atob(tokenPayload)) 

              if(new_decoded_payload['exp']*1000 < new Date().valueOf())  {
                killToken()
                return redirect('/login') 
              } else {
                saveToken(payloadRefreshToken.token, payloadRefreshToken.refresh_token)
                resultFindRequest = await findRequestCard() 
              }

            }

            const requestCardStatus = await resultFindRequest.json()
            //console.log(balance.data[0].balance, requestCardStatus) 
            let result = await getAllDeposits() 
            // If fetch returns 401 
            if(result.status === 401) {
              const refreshTokenResult = await refreshToken({refresh_token: localStorage.getItem('u_r_t')})

              // If refresh token returns 401
              if(refreshTokenResult.status === 401) {
                killToken()
                return redirect('/login')
              } 

              // If refresh token is successfull 
              const payloadRefreshToken = await refreshTokenResult.json()   

              let tokenPayload = payloadRefreshToken.token.split('.')[1] 

              // Payload of new generated token 
              let new_decoded_payload = JSON.parse(atob(tokenPayload)) 

              if(new_decoded_payload['exp']*1000 < new Date().valueOf())  {
                killToken()
                return redirect('/login') 
              } else {
                saveToken(payloadRefreshToken.token, payloadRefreshToken.refresh_token)
                result = await getAllDeposits() 
              }

            }

            const res = await result.json()
            if(requestCardStatus.data.length === 0) {
              if(res['data'].length > 0) {
                if((res['data'].length === 1 && res['data'][0]['status'] === 'Pending')) {
                  return `${balance.data[0].balance}`
                } else {
                  return {normal_deposit: true}
                } } else {
                  return {normal_deposit: false} 
                }
    
            } else if(requestCardStatus.data.length >= 1) {
              return {normal_deposit: true}
              // return {balance: balance.data[0].balance, status: requestCardStatus.data[0].requesteStatus}
            } 
            } else {
              return redirect('/login') 
            } 
        } catch(err) {

        }
      }
        // return null 
    },    
    // { url: 'my-wallet', component: <MyWallet/> },
    // { url: 'invoices', component: <Invoices/> },
    // { url: 'cards-center', component: <CardsCenter/> },
    // { url: 'transactions', component: <Transactions/> },
    // { url: 'transactions-details', component: <TransactionsDetails/> },
	  // { url: 'task', component: <Task/> },

    /// Apps
    // { url: 'app-profile', component: <AppProfile/> },
    // { url: 'post-details', component: <PostDetails/> },
    // { url: 'email-compose', component: <Compose/> },
    // { url: 'email-inbox', component: <Inbox/> },
    // { url: 'email-read', component: <Read/> },
    // { url: 'app-calender', component: <Calendar/> },
    

    /// Chart
    // { url: 'chart-sparkline', component: <SparklineChart/> },
    // { url: 'chart-chartjs', component: <ChartJs/> },
    //{ url: 'chart-chartist', component: Chartist },
    // { url: 'chart-apexchart', component: <ApexChart/> },
    // { url: 'chart-rechart', component: <RechartJs/> },

    /// Bootstrap
    // { url: 'ui-alert', component: <UiAlert/> },
    // { url: 'ui-badge', component: <UiBadge/> },
    // { url: 'ui-button', component: <UiButton/> },
    // { url: 'ui-modal', component: <UiModal/> },
    // { url: 'ui-button-group', component: <UiButtonGroup/> },
    // { url: 'ui-accordion', component: <UiAccordion/> },
    // { url: 'ui-list-group', component: <UiListGroup/> },
    
    // { url: 'ui-card', component: <UiCards/> },
    // { url: 'ui-carousel', component: <UiCarousel/> },
    // { url: 'ui-dropdown', component: <UiDropDown/> },
    // { url: 'ui-popover', component: <UiPopOver/> },
    // { url: 'ui-progressbar', component: <UiProgressBar/> },
    // { url: 'ui-tab', component: <UiTab/> },
    // { url: 'ui-pagination', component: <UiPagination/> },
    // { url: 'ui-typography', component: <UiTypography/> },
    // { url: 'ui-grid', component: <UiGrid/> },

    /// Plugin
    // { url: 'uc-select2', component: <Select2/> },
    // { url: 'uc-nestable', component: <Nestable/> },
    // { url: 'uc-noui-slider', component: <MainNouiSlider/> },
    // { url: 'uc-sweetalert', component: <MainSweetAlert/> },
    // { url: 'uc-toastr', component: <Toastr/> },
    // { url: 'map-jqvmap', component: <JqvMap/> },
    // { url: 'uc-lightgallery', component: <Lightgallery/> },


    /// Widget
    // { url: 'widget-basic', component: <Widget/> },

    /// Shop
    // { url: 'ecom-product-grid', component: <ProductGrid/> },
    // { url: 'ecom-product-list', component: <ProductList/> },
    // { url: 'ecom-product-detail', component: <ProductDetail/> },
    // { url: 'ecom-product-order', component: <ProductOrder/> },
    // { url: 'ecom-checkout', component: <Checkout/> },
    // { url: 'ecom-invoice', component: <Invoice/> },
    // { url: 'ecom-product-detail', component: <ProductDetail/> },
    // { url: 'ecom-customers', component: <EcomCustomers/> },

    /// Form
    
   
    
    // { url: 'form-element', component: <Element/> },
    // { url: 'form-wizard', component: <Wizard/> },
    // { url: 'form-wizard', component: <Wizard/> },
    // { url: 'form-ckeditor', component: <CkEditor/> },
    // { url: 'form-pickers', component: <Pickers/> },
    // { url: 'form-validation', component: <FormValidation/> },

    /// table
    // { url: 'table-datatable-basic', component: <DataTable/> },
    // { url: 'table-bootstrap-basic', component: <BootstrapTable/> },
    // { url: 'table-filtering', component: <FilteringTable/> },
    // { url: 'table-sorting', component: <SortingTable/> },

    /// pages
    // { url: 'page-register', component: Registration },
    // { url: 'page-lock-screen', component: LockScreen },
    // { url: 'page-login', component: Login },
    // { url: 'page-error-400', component: Error400 },
    // { url: 'page-error-403', component: Error403 },
    // { url: 'page-error-404', component: Error404 },
    // { url: 'page-error-500', component: Error500 },
    // { url: 'page-error-503', component: Error503 },
    // { url: 'todo', component: <Todo/> },
    { url: 'mycard', component: <MyCard />, loader:  async ()=>{
      
      try {
        if(localStorage.getItem('u_t_p')) {
          const decoded_payload = JSON.parse(atob(localStorage.getItem('u_t_p')))  
          if(decoded_payload['exp']*1000 < new Date().valueOf()) {
              const resultRefresh = await refreshToken({refresh_token: localStorage.getItem('u_r_t')})
              if(resultRefresh.status === 401) {
                killToken()
                return redirect('/login') 
              }
              const result = await resultRefresh.json()   


              // console.log(result.token, result.refresh_token) 
              let tokenPayload = result.token.split('.')[1] 
              // console.log(tokenPayload) 
              // saveToken(result.token, result.refresh_token)  


              let new_decoded_payload = JSON.parse(atob(tokenPayload))    
              // console.log(new_decoded_payload['exp']*1000, new Date().valueOf())      
              // console.log(new_decoded_payload['exp']*1000 < new Date().valueOf()) 
              
              
              if(new_decoded_payload['exp']*1000 < new Date().valueOf())  {
                killToken()
                return redirect('/login') 
              } else {
                saveToken(result.token, result.refresh_token) 
                // return null  
              }
            // const result = await refreshToken.json() 
            // console.log(resultRefresh) 
            // console.log('N') 
            //   killToken()
              // return redirect('/login')
          }
  
          let resultFindRequest = await findRequestCard() 
          // If fetch returns 401 
          if(resultFindRequest.status === 401) {
            const refreshTokenResult = await refreshToken({refresh_token: localStorage.getItem('u_r_t')})

            // If refresh token returns 401
            if(refreshTokenResult.status === 401) {
              killToken()
              return redirect('/login')
            } 

            // If refresh token is successfull 
            const payloadRefreshToken = await refreshTokenResult.json()   

            let tokenPayload = payloadRefreshToken.token.split('.')[1] 

            // Payload of new generated token 
            let new_decoded_payload = JSON.parse(atob(tokenPayload)) 

            if(new_decoded_payload['exp']*1000 < new Date().valueOf())  {
              killToken()
              return redirect('/login') 
            } else {
              saveToken(payloadRefreshToken.token, payloadRefreshToken.refresh_token)
              resultFindRequest = await findRequestCard() 
            }

          }

          const requestCardStatus = await resultFindRequest.json()
          if(requestCardStatus.data.length > 0) {
            if(requestCardStatus.data[0].requesteStatus === 'Success') {
              return null 
            } else if(requestCardStatus.data[0].requesteStatus === 'Pending') {
              return redirect('/thankyouforcardorder') 
            }
          } else {
            return redirect('/ordercard') 
          }
        } else {
          return redirect('/login') 
        }
          return null 
        
      } catch(err) {

      }}} 
  ]
  
  export const router = createBrowserRouter(createRoutesFromElements(
  <>
  {/* <Suspense fallback={<div>Loading...</div>}> */}
  <Route path='/' element={<Lplayout />} >
    <Route index element={<LpBody />} />
  </Route>
  {/* <Route path='/main' element={<Main />} loader={loader}/> */}
  <Route path='/login' element={<Login />} />
  
    {/* <Route path='/page-register' element={<SignUp />} /> */}
    <Route path='/register' element={<Registration />} loader={()=>{
      return null
    }}/> 

    <Route path='/resetpassword' element={<ResetPasswortEmail />} /> 
    <Route path='/newpassword' element={<ResetPasswordNewPassword />} /> 
    <Route path='/resetpasswordverification' element={<ResetPasswordOTP />} /> 
  
  <Route path='/page-forgot-password' element={<ForgotPassword />} />
  <Route path='page-lock-screen' element= {<LockScreen />} />
  <Route path='page-error-400' element={<Error400/>} />
  <Route path='page-error-403' element={<Error403/>} />
  <Route path='page-error-404' element={<Error404/>} />
  <Route path='page-error-500' element={<Error500/>} />
  <Route path='page-error-503' element={<Error503/>} />
  <Route  element={<MainLayout />} > 
      {allroutes.map((data, i) => (
        <Route
          key={i}
          exact
          path={`${data.url}`}
          element={data.component}
          loader={data.loader}
          errorElement={data.errorElement} 
        />
      ))}
  </Route>
  {/* </Suspense> */}
  </>))
