import React, { useState } from 'react'
import './CSS/verification.css'  
import { Modal } from 'react-bootstrap' 
import { Formik, Form } from 'formik'
import * as YUP from 'yup' 
import OtpInput from '../components/OtpInput'   
import { securedLoginCheck, getConfig, saveToken } from '../security/AuthService' 
import { useReferral } from '../context/ReferralContext'
import { useActive } from '../context/ActiveContext'
import { useNavigate } from 'react-router-dom' 
import { useGoogleAuthentication } from '../context/GoogleAuthenticationContext' 
import { LocalStorage } from '../login/LocalStorage'

const Verification = ({show, title, description, loginEmail, loginPassword, handleClose}) => {          
  const [verificationError, setVerificationError] = useState('')   
      const navigate = useNavigate()
      const {handleMenuActive, handleMenuParentActive} = useActive() 
      const { handleShowReferral } = useReferral()  
      const { enableGoogleAuthenticationHandler } = useGoogleAuthentication() 
      const closeHandler = ()=>{
        handleClose()
        if(verificationError) {
          setVerificationError('') 
        }
      }
  return (
    <Modal show={show} className='verification' backdrop='static' keyboard='false'>  
            <button id='closeVerification' onClick={closeHandler}>
              <img src='/images/dashboard/2fa/Vector (8).svg' alt='a grey cross mark' />
            </button>   

            <Modal.Body className='center flex-column'> 
                <img 
                    src='/images/dashboard/2fa/Enter OTP-cuate 1.svg' 
                    alt='A person sitting on a chair, holding a PC with the Paytaps logo and a phone, behind him a screen containing a verification text and 6 rectangular fields, the first 4 contain 5, 3, 3, 8 respectively, and the remaining 2 fields are empty without content, on the upper right edge of the screen a blue shield inside it a white check mark inside a gray circle, and next to the person there is a plant with blue leaves'  
                    draggable='false'  
                /> 
                <div className='verificationContentWrapper'>
                    <div className='verificationContentTitle'>{title}</div>
                    <p className='verificationContentDescription'>{description}</p>  
                </div> 
                <Formik
                            initialValues={{
                                otp: '',
                            }} 

                            validationSchema={YUP.object({
                                otp: YUP.string().required('Required!'),
                            })}

                            onSubmit={ async (values, {setSubmitting, resetForm})=>{
                              try { 

                                const post_login_check = await securedLoginCheck({
                                  email: loginEmail,
                                  password: loginPassword, 
                                  auth_code: values.otp   
                                })
                                const result_post_login_check = await post_login_check.json()
  

                                if(!post_login_check.ok) { 
                                  setVerificationError(result_post_login_check.error) 
                                } 

                                if(post_login_check.ok && result_post_login_check.token) {

                                  setVerificationError('') 
                                  saveToken(result_post_login_check.token, result_post_login_check.refresh_token) 

                                  sessionStorage.clear()  
                                  await LocalStorage(navigate, handleMenuParentActive, handleShowReferral);  
                                }
                              } catch(err) {

                              } 
                            }}
                        >
                           
                                 {(formik) => (
                                <Form className='form' onSubmit={formik.handleSubmit}>
                                  <OtpInput length={6} 
                                      onChange={(otp) => formik.setFieldValue('otp', otp)} 
                                  />
                                  {verificationError && <div className='verificationError'>{verificationError}</div>}   
                                  <button
                                    type='submit'
                                    id='verificationVerifyNowOTP'
                                    className={`${verificationError ? 'mt-0': 'mt-27'}`} 
                                    disabled={!(formik.dirty && formik.isValid) || formik.isSubmitting || formik.values.otp.length !== 6}  
                                  > 
                                    Verify Now
                                  </button> 
                                </Form>  
          )}
                </Formik>
                <div className={`checkSpamSendAgainWrapper ${title === 'Enter Google Authenticator Code' ? 'h-27' : 'h-auto'}`}> 
                    {title !== 'Enter Google Authenticator Code' && <span className='checkSpam'>Didn’t receive it? Check your spam folder</span>}
                </div>  
            </Modal.Body>
    </Modal>
  )
}

export default Verification    


           
