import React, { useState, useEffect, useRef, useCallback } from 'react'
import './CSS/mycard.css'
import { FigureCaption } from 'react-bootstrap'
import BalanceDataDetail from '../components/BalanceDataDetail'
import Button from '../components/Button'
import { useToggle } from '../context/ToggleContext'
import TransactionsVisulizer from '../components/TransactionsVisulizer'
import Topup from '../Modals/Topup'
import InsufficientBalance_top_up from '../Modals/InsufficientBalance_top_up' 
import ShowDetails from '../Modals/ShowDetails' 
import VerifyCardInfo from '../Modals/VerifyCardInfo'
import { useNavigate } from 'react-router-dom'  
import { getCardBalance, 
        getCardTopupHistory, 
        getCardTransactions,
        getAddressBasedOnChain,
        suspendOrActivateCard,
        cardInfo
} from '../security/AuthService'     
import Loader from '../jsx/pages/Loader/Loader'  
import TopUpVisualizer from '../components/TopUpVisualizer' 
import ApproveCard from '../Modals/ApproveCard' 
import TopupSuccess from '../Modals/TopupSuccess' 
import moment from 'moment';   
import { useReferral } from '../context/ReferralContext'
import { refreshTokenWithAPICallHandler } from '../utils/refresh'
import { walletRedirectButton } from '../utils/utils' 
import SuspendCard from '../Modals/SuspendCard' 

const MyCard = () => {
    const { showReferral, handleShowReferral } = useReferral()
    const { toggle } = useToggle()
    const navigate = useNavigate() 
    const activeFilterPrevTrx = useRef(null)  
    const activeFilterTopUp = useRef(null)   
    const [show, setShow] = useState(false)
    const [balanceCard, setBalanceCard] = useState('')
    const [previousTrx, setPreviousTrx] = useState({data: []}) 
    const [topupHistory, setTopupHistory] = useState({data: []}) 
    const [filteredPreviousTrx, setFilteredPreviousTrx] = useState({data: []}) 
    const [filteredTopupHistory, setFilteredTopupHistory] = useState({data: []})   
    const [showdetails, setShowDetails] = useState(false)
    const [showcarddetails, setShowcardDetails] = useState(false) 
    const [showInsufficientTopupBalance, setShowInsufficientTopupBalance] = useState(false)  
    const [error, setError] = useState('') 
    const [cardHolderDataLoadingState, setCardHolderDataLoadingState] = useState(true)  
    const [showApproveCard, setShowApproveCard] = useState(false) 
    const [showSuccess, setShowSuccess] = useState(false) 
    const [show1, setShow1] = useState(false)                                                        
    const refAllow = useRef(false) 

    const [balanceCix, setBalanceCix] = useState({
        availableCix: 0,
        availableCixinUsdt: 0
    }) 
    const [btcAddress, setBtcAddress] = useState('') 

    const [showTopupResultMsg, setShowTopupResultMsg] = useState('') 
    const [suspendCard, setSuspendCard] = useState({
        show: false,
        isSuspendedByClient: false,  
        isSuspendedByAdmin: false  
    })
    
    const handleClose = () => {setShow(false)} 
    const handleShow = () => setShow(true);
    const handleClose1 = () => setShow1(false);
    const handleShow1 = () => setShow1(true);
    const handleCloseDetails = () => setShowDetails(false);
    const handleShowDetails = () => setShowDetails(true);  
    const handleClosecardDetails = () => setShowcardDetails(false);
    const handleShowcardDetails = () => setShowcardDetails(true);
    const handleCloseInsufTopupBalance = () => setShowInsufficientTopupBalance(false); 
    const handleShowInsufTopupBalance = () => setShowInsufficientTopupBalance(true);                                        
    const handleRefAllowTrue = ()=>  refAllow.current = true  
    const handleRefAllowFalse = ()=>  refAllow.current = false 
    
    const handleShowApproveCard = useCallback(function () {
        setShowApproveCard(true)
    }, [])
    const handleCloseApproveCard = useCallback(function () {
        setShowApproveCard(false) 
    }, [])  
     
    const loading = useRef(false)

    const handleCloseTopupSuccess = useCallback(function () {
        setShowSuccess(false) 
    }, [])
    const handleShowTopupSuccess = useCallback(function () {
        setShowSuccess(true)
    }, [])  
    const [activeRerender, setActiveRerender] = useState(false) 
     
    useEffect(()=>{
        let ignore = false;
        getAllCardHolderDetails(ignore)  

    }, [activeRerender])                       

    const updateBalance = useCallback(function () {
        setActiveRerender(activeRerender => !activeRerender)  
    }, [activeRerender])           


    function activeFilterPrevTrxHandler (e) {

        if((e.target.innerHTML !== 'Current Month' && e.target.innerHTML !== 'Current Week') && (e.target.innerHTML !== 'Today')) {
            activeFilterPrevTrx.current = null 
        } else {
            activeFilterPrevTrx.current = e.target.innerHTML  
        }  
        handleFilterPrevTrx()

    }
    function activeFilterTopUpHandler (e) {

        if((e.target.innerHTML !== 'Current Month' && e.target.innerHTML !== 'Current Week') && (e.target.innerHTML !== 'Today')) {
            activeFilterTopUp.current = null 
        } else {
            activeFilterTopUp.current = e.target.innerHTML  
        }
        handleFilterTopupHistory() 

    } 

    function topupClickHandler () {
        handleShow()
    }
    
    async function getAllCardHolderDetails(ignore) {
        try {
            setCardHolderDataLoadingState(true)
            if(!showReferral) {
                handleShowReferral() 
            }             
            
            let result_card_balance = {
                api_call: await getCardBalance()
            }
            const renewCAB = async ()=>{
                return await getCardBalance()
            }
            // If fetch returns 401
            if(result_card_balance.api_call.status === 401) {
                if(await refreshTokenWithAPICallHandler(result_card_balance, renewCAB) === 'REDIRECT_TO_LOGIN') {
                    return navigate('/login')  
                }
            }
            const card_balance = await result_card_balance.api_call.json() 

            let result_card_trx = {
                api_call: await getCardTransactions()
            }
            const renewCAT = async ()=>{
                return await getCardTransactions()
            }
            // If fetch returns 401
            if(result_card_trx.api_call.status === 401) {
                if(await refreshTokenWithAPICallHandler(result_card_trx, renewCAT) === 'REDIRECT_TO_LOGIN') {
                    return navigate('/login')  
                }
            }
            const card_previous_trx = await result_card_trx.api_call.json() 


            let result_card_topup_history = {
                api_call: await getCardTopupHistory()
            }
            const renewCTH = async ()=>{
                return await getCardTopupHistory()
            }
            // If fetch returns 401
            if(result_card_topup_history.api_call.status === 401) {
                if(await refreshTokenWithAPICallHandler(result_card_topup_history, renewCTH) === 'REDIRECT_TO_LOGIN') {
                    return navigate('/login')  
                }
            }
            const card_topup_history = await result_card_topup_history.api_call.json()  

            const fetch_address = await getAddressBasedOnChain({network:  'BTC'})                                                                      
            const result_fetch_address = await fetch_address.json()
                
            if(fetch_address.ok && result_fetch_address['message'] === 'success') {
                setBtcAddress(result_fetch_address['data'][0]['wallet_address'])                                                  
            }
            let card_fetch_info = {
                api_call: await cardInfo()
            }
            const renewCAI = async ()=>{
                return await cardInfo()
            }
            // If fetch returns 401
            if(card_fetch_info.api_call.status === 401) {
                if(await refreshTokenWithAPICallHandler(card_fetch_info, renewCAI) === 'REDIRECT_TO_LOGIN') {
                return navigate('/login')  
                }
            }
            const result_card_info = await card_fetch_info.api_call.json()

            if(!ignore) {
                if(result_card_balance.api_call.ok && result_card_trx.api_call.ok && result_card_topup_history.api_call.ok && card_fetch_info.api_call.ok) {                        
                 
                    setBalanceCard(card_balance['data']['balance'])      
                    setPreviousTrx({...previousTrx, data: card_previous_trx['data'].reverse()}); setFilteredPreviousTrx({...filteredPreviousTrx, data: card_previous_trx['data']})    
                    setTopupHistory({...topupHistory, data: card_topup_history['data']}); 
                    setFilteredTopupHistory({...filteredTopupHistory, data: card_topup_history['data']})    
                    setCardHolderDataLoadingState(false) 
                    console.log(result_card_info)
                    console.log(result_card_info['data'][0]) 
                    console.log(result_card_info['data'][0]['suspendStatusAdmin'], typeof result_card_info['data'][0]['suspendStatusAdmin'])
                    console.log(result_card_info['data'][0]['suspendStatusClient'], typeof result_card_info['data'][0]['suspendStatusClient'])
                
                    setSuspendCard({
                        ...suspendCard,
                        isSuspendedByAdmin: result_card_info['data'][0]['suspendStatusAdmin'],
                        isSuspendedByClient: result_card_info['data'][0]['suspendStatusClient']  
                    })

                } else {
                    setError('Error') 
                    setCardHolderDataLoadingState(false) 
                    loading.current = false 
                }  
            }  
        } catch(err) {
            setError(err) 
        } finally {
            setCardHolderDataLoadingState(false) 
            loading.current = false 
        }
          
    } 
    
    function handleFilterPrevTrx() {
        
            if(activeFilterPrevTrx.current==='Current Month') {
                const filteredData = previousTrx["data"].filter((trx)=> (((moment().month() + 1) === moment(trx['dateOfTransaction']).month() + 1) && ((moment(trx['dateOfTransaction']).year()) === moment().year() )))    
                return  setFilteredPreviousTrx({...filteredPreviousTrx, data: filteredData})
                   
            } else if(activeFilterPrevTrx.current === 'Current Week') {
                const filteredData = previousTrx['data'].filter((trx)=> ((moment(trx['dateOfTransaction']).valueOf()>= moment().startOf('week').valueOf()) && (moment(trx['dateOfTransaction']).valueOf() <= moment().endOf('week').valueOf())))     
                return  setFilteredPreviousTrx({...filteredPreviousTrx, data: filteredData}) 
            } else if(activeFilterPrevTrx.current === 'Today') {
                const filteredData = previousTrx['data'].filter((trx)=> ((moment(trx['dateOfTransaction']).valueOf() >= moment().startOf('day')) && (moment(trx['dateOfTransaction']).valueOf() <= moment().endOf('day'))))   
                return  setFilteredPreviousTrx({...filteredPreviousTrx, data: filteredData})  
            } else {

                return setFilteredPreviousTrx({data: previousTrx['data']})                                                                                                                                                                 
            }  
        
    } 

    function handleFilterTopupHistory() {
        
        if(activeFilterTopUp.current==='Current Month') {
            const filteredData = topupHistory["data"].filter((trx)=> (((moment().month() + 1) === moment(trx?.createdAt?.date).month() + 1) && ((moment(trx?.createdAt?.date).year()) === moment().year() )))    
            return  setFilteredTopupHistory({...filteredTopupHistory, data: filteredData})
               
        } else if(activeFilterTopUp.current === 'Current Week') {
            const filteredData = topupHistory['data'].filter((trx)=> ((moment(trx?.createdAt?.date).valueOf()>= moment().startOf('week').valueOf()) && (moment(trx?.createdAt?.date).valueOf() <= moment().endOf('week').valueOf())))     
            return  setFilteredTopupHistory({...filteredTopupHistory, data: filteredData}) 
        } else if(activeFilterTopUp.current === 'Today') {
            const filteredData = topupHistory['data'].filter((trx)=> ((moment(trx?.createdAt?.date).valueOf() >= moment().startOf('day')) && (moment(trx?.createdAt?.date).valueOf() <= moment().endOf('day'))))  
            return  setFilteredTopupHistory({...filteredTopupHistory, data: filteredData})  
        } else {
            
            return setFilteredTopupHistory({data: topupHistory['data']})  
        } 
    
    }  

    const showTopupMsgHandler = (msg)=>{
        setShowTopupResultMsg(msg)
    } 

    const showSuspendCardModal = useCallback(function () {
        setSuspendCard({
            ...suspendCard,
            show: true
        })
    }) 

    const closeSuspendCardModal = useCallback(function () {
        setSuspendCard({
            ...suspendCard,
            show: false 
        })
    })

    const updateSuspendStatus = useCallback((show, updateAdminState, updateClientState)=>{
        setSuspendCard({
            ...suspendCard,
            show: show,
            isSuspendedByAdmin: updateAdminState,
            isSuspendedByClient: updateClientState
        })
    })
    console.log(suspendCard) 
return (
    <>
        {((!cardHolderDataLoadingState) && !error) && <div className='mycard-container'>
            <section className='balance-card-container'>
                <div className='mycard-title'>My Paytaps card</div>
                <div className='mypaytaps-container'>
                    <div className='main-balance-wrapper'>
                        <figure className='main-balance-figure-wrapper'>
                            <img src='/images/dashboard/mycard/card.svg' alt='card' draggable='false' loading='eager' /> 
                            <FigureCaption>Main Balance</FigureCaption>
                        </figure>
                        <div className='balance-details-wrapper'>
                            
                            <BalanceDataDetail imagesrc='/images/dashboard/mycard/tabler_credit-card.svg' 
                                label='Card balance' value={`${parseFloat(balanceCard).toFixed(2)} $`} altText='card balance' 
                            />
                            <Button styles='topup-btn-styles' click={topupClickHandler}>+Top Up</Button>
                            
                        </div>
                        
                    </div>
                    <div className={`card-wrapper ${toggle ? 'align-items-center' : ''}`} style={suspendCard.isSuspendedByAdmin ? { gap: '15px', marginTop: '50px'} : {}}>
                        <figure>
                            <img 
                                src='/images/dashboard/mycard/mycard.png' 
                                alt='A purple area with a linear gradient from lightest to darkest towards the top right, in the upper left corner a full Paytabs logo. At the bottom a large Paytabs text with low opacity shifted a little downwards and in the lower right corner the Mastercard logo'   
                                draggable='false'
                                loading='eager' 
                            />                              
                            <span className='card-holder-name'>XXXXX XXXXX</span>

                            <span className='card-number'>XXXX XXXX XXXX XXXX</span> 

                            <div className='card-validity'>
                                <span>VALID THRU</span>
                                <span>XX/XX</span> 
                            </div>
                            {/* <Button imagesrc='/images/dashboard/mycard/checked.svg' styles='approvetrx-btn-styles' click={handleShowApproveCard}>Approve Transaction</Button>  */} 
                            {suspendCard.isSuspendedByAdmin && <div className='suspendedByAdmin'>Your card has been suspended by the admin!</div>}  
                        </figure>
                        <div className={`card-config-wrapper ${suspendCard.isSuspendedByAdmin ? 'marginTop0' : 0} `}>                                                                                                                                             
                            {!suspendCard.isSuspendedByAdmin && 
                            <Button 
                                imagesrc={`${!suspendCard.isSuspendedByClient ? 
                                '/images/dashboard/mycard/tabler_lock (2).svg' : 
                                '/images/dashboard/mycard/tabler_lock-open-2.svg'}`} 
                                styles={`setting-btn-styles ${!suspendCard.isSuspendedByClient ? 'suspended' : ''}`} 
                                click={showSuspendCardModal}
                            >
                            {!suspendCard.isSuspendedByClient ? 'Suspend My Card' : 'Reactivate your card'}
                            </Button>}               
                            <Button imagesrc='/images/dashboard/mycard/tabler_eye.svg' styles={`showdetails-btn-styles ${suspendCard.isSuspendedByAdmin ? 'w-100' : ''}`} click={handleShowDetails} style={suspendCard.isSuspendedByAdmin ? {width: '100%'} : {}}> Show details</Button>                                                                                                               
                        </div>
                    </div>
                </div>
            </section>

            
            <section className='history-data-container'>
                <div className='previous-transactions-wrapper'>
                    <div className='previous-transactions-wrapper-header myCardPreviousTrxHeader'>
                        <div className='previous-transactions-wrapper-header-text-content'>
                            <span>Previous Transactions</span>
                            {(previousTrx['data'].length > 0) && 
                            <>
                            {(activeFilterPrevTrx.current === 'Current Month') && <span>Current month's transactions</span>}
                            {(activeFilterPrevTrx.current === 'Current Week') && <span>Current Week's transactions</span>}
                            {(activeFilterPrevTrx.current === 'Today') && <span>Today's transactions</span>}
                            {(activeFilterPrevTrx.current === null) && <span>All previous transactions</span>}                      
                            </>}
                        </div>
                        {(previousTrx['data'].length > 0) && <div className='previous-transactions-wrapper-header-filter'>
                            <button className={`${activeFilterPrevTrx.current === 'Current Month' ? 'active-filter' : ''}`} onClick={activeFilterPrevTrxHandler} >Current Month</button>
                            <button className={`${activeFilterPrevTrx.current === 'Current Week' ? 'active-filter' : ''}`} onClick={activeFilterPrevTrxHandler}>Current Week</button>
                            <button className={`${activeFilterPrevTrx.current === 'Today' ? 'active-filter' : ''}`} onClick={activeFilterPrevTrxHandler}>Today</button>                                                                            
                            {((activeFilterPrevTrx.current === 'Current Month' || activeFilterPrevTrx.current === 'Current Week') || activeFilterPrevTrx.current === 'Today') && <button className='clearFilter' onClick={activeFilterPrevTrxHandler}><span>X</span> <span>Clear Filter</span></button>}         
                        </div>}
                    </div>
                    
                <TransactionsVisulizer data={filteredPreviousTrx['data']} parentFilter={activeFilterPrevTrx.current} />

                </div>

                <div className='top-up-history-wrapper'>
                    <div className='previous-transactions-wrapper-header myCardTopupHistoryHeader'>
                        <div className='previous-transactions-wrapper-header-text-content'>
                            <span>Top-up History </span>
                            {(topupHistory['data'].length > 0) && 
                            <>
                            {(activeFilterTopUp.current === 'Current Month') && <span>Current month's transactions</span>}
                            {(activeFilterTopUp.current === 'Current Week') && <span>Current Week's transactions</span>}
                            {(activeFilterTopUp.current === 'Today') && <span>Today's transactions</span>}
                            {(activeFilterTopUp.current === null) && <span>All Top-up history</span>}                   
                            </>}
                        </div>
                        {(topupHistory['data'].length > 0) &&<div className='previous-transactions-wrapper-header-filter'>
                            <button className={`${activeFilterTopUp.current === 'Current Month' ? 'active-filter' : ''}`} onClick={activeFilterTopUpHandler} >Current Month</button>
                            <button className={`${activeFilterTopUp.current === 'Current Week' ? 'active-filter' : ''}`} onClick={activeFilterTopUpHandler}>Current Week</button>
                            <button className={`${activeFilterTopUp.current === 'Today' ? 'active-filter' : ''}`} onClick={activeFilterTopUpHandler}>Today</button>                                                                                    
                            {((activeFilterTopUp.current === 'Current Month' ||activeFilterTopUp.current === 'Current Week') || activeFilterTopUp.current === 'Today') && <button className='clearFilter' onClick={activeFilterTopUpHandler}><span>X</span> <span>Clear Filter</span></button>}                                             
                        </div>} 
                    </div>
                    
                    <TopUpVisualizer data={filteredTopupHistory['data']} topup={true} />      

                </div>
                
            </section>

        </div>}
        {((cardHolderDataLoadingState) && !error) && <div className='waitLoadingProfileData'><div>Please wait...</div><Loader /></div>}
    
        <Topup 
            show={show} 
            handleClose={handleClose} 
            handleShowConfirmation={handleShow1} 
            handleShowInsufTopupBalance={handleShowInsufTopupBalance} 
            updateBalance={updateBalance} 
            balanceCix={balanceCix.availableCix}  
            handleShowTopupSuccess={handleShowTopupSuccess}
            showTopupMsgHandler={showTopupMsgHandler} 
            btcAddress={btcAddress} 
        />          
        <InsufficientBalance_top_up 
            show={showInsufficientTopupBalance} 
            handleCloseInsufTopupBalance={handleCloseInsufTopupBalance} 
            handleShowInsufTopupBalance={handleShowInsufTopupBalance} 
        />
        <TopupSuccess 
            show={showSuccess} 
            handleClose={handleCloseTopupSuccess} 
            showTopupResultMsg={showTopupResultMsg}
            showTopupMsgHandler={showTopupMsgHandler}
        />                                                                                                                                                                                                                   
        <ShowDetails 
            show={showcarddetails}  
            handleClosecardDetails={handleClosecardDetails} 
            isSuspendedByClient={suspendCard.isSuspendedByClient} 
            isSuspendedByAdmin={suspendCard.isSuspendedByAdmin} 
        />    
        <VerifyCardInfo 
            show={showdetails} 
            handleClose={handleCloseDetails} 
            a={refAllow.current} 
            handleRefAllowTrue={handleRefAllowTrue} 
            handleRefAllowFalse={handleRefAllowFalse} 
            handleClosecardDetails={handleClosecardDetails} 
            handleShowcardDetails={handleShowcardDetails} 
        />   
        <ApproveCard show={showApproveCard} handleClose={handleCloseApproveCard} /> 
        <SuspendCard 
            show={suspendCard.show} 
            handleClose={closeSuspendCardModal}  
            isSuspendedByClient={suspendCard.isSuspendedByClient} 
            isSuspendedByAdmin={suspendCard.isSuspendedByAdmin}  
            updateSuspendStatus={updateSuspendStatus} 
        />     
    </> 
  )
}

export default MyCard


