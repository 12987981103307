import React, { createContext, useCallback, useContext, useReducer } from "react";
// import { reducer, initialState } from "../jsx/layouts/nav/SideBar";
import { MenuList } from "../jsx/layouts/nav/Menu";
import { useToggle } from "./ToggleContext";

export const ActiveContext = createContext(null);
let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1]
  function initialActiveLink () {
    let index_active_link = MenuList.findIndex(menulink=> (menulink.to==='/'+path))
    // console.log(index_active_link)
    if(index_active_link === -1) {
      return 'profile'
    } else {
      return MenuList[index_active_link].title
    }
  }
 const reducer = (previousState, updatedState) => ({
    ...previousState,
    ...updatedState,
  });
 const initialState = {
    active : initialActiveLink(),
    activeSubmenu : "",
  }
const ActiveContextProvider = (props) => {
  const { toggleHandler } = useToggle()
    const [state, setState] = useReducer(reducer, initialState);
    const handleMenuActive = useCallback((status) => {		
		setState({active : status});
    if(window.innerWidth < 1024) {
      toggleHandler()
    }}, [toggleHandler])  

		// if(state.active === status){				
		// 	setState({active : ""});      
		// }   
	
    // const toggleHandler = useCallback(function toggleHandler () {
    //     setToggle(!toggle)
    //     NavMenuToggle()
    //  }, [toggle])
     return (
        <ActiveContext.Provider
          value={{
            state,
            setState,
            handleMenuActive
        }}
        >
          {props.children}
        </ActiveContext.Provider>
     )
}

export default ActiveContextProvider

export function useActive () {
    const {state, setState, handleMenuActive} = useContext(ActiveContext)
    return {state, setState, handleMenuActive}
}

