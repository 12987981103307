import React, { useState, useEffect, useCallback } from 'react'
import './CSS/confirmpasswordchange.css' 
import { useToggle } from '../context/ToggleContext'
import Modal from 'react-bootstrap/Modal'
import { Formik, Form, Field } from 'formik'
import TextInput from '../components/TextInput'
import * as YUP from 'yup'
import Button from '../components/Button' 
import { convertToUSDT, requestTopUp } from '../security/AuthService' 
const ConfirmPasswordChange = ({show, handleClose, payload, submitUpdatePassword}) => {

  return (
    <Modal show={show}  className='topup-modal confirmPasswordChange' backdrop='static' keyboard='false' > 
        <div className='confirmPasswordChangeWrapper'> 
            <figure>
                <img src='/images/dashboard/resetpassword/freepik--Interface--inject-70.svg' 
                alt='a blue frame within it an opened lock, the latest below it 5 asterisk '
                style={{display: 'block', margin: 'auto'}} 
                />  
            </figure>
            <div className='confirmPasswordTitle'>Confirm Password Change</div>
            <p className='confirmPasswordDescription'>Are you sure you want to change your password? This action cannot be undone</p> 
            <div className='group-btn'>
                <Button click={handleClose}>Cancel</Button> 
                <Button btnType='submit' id='confirm_password_update' click={submitUpdatePassword}>Confirm</Button> 
            </div>  
        </div>
                                
                                
   </Modal>
  )
}

export default ConfirmPasswordChange