import React, { lazy } from 'react'
import {Route, createBrowserRouter, createRoutesFromElements, redirect } from 'react-router-dom'
import { 
  findRequestCard, 
  getReferralCode, 
  getSecretKey, 
  getLoginType
 } 
from '../security/AuthService'                 
import { refreshTokenHandler, refreshTokenWithAPICallHandler } from '../utils/refresh' 

/// Css
import './index.css'
import './chart.css'
import './step.css'

import MainLayout from '../jsx/index'
import Lplayout from '../landingpage/Lplayout'
import LpBody from '../landingpage/LPBody/LpBody'
import Registration from '../Registration/Register'
import Login from '../login/Login'
import MyCard from '../dashboard/MyCard'
import MyInfo from '../dashboard/MyInfo'
import OrderCard from '../dashboard/OrderCard'
import ThankYouOrderCard from '../dashboard/ThankYouOrderCard' 
import ErrorPage from '../dashboard/ErrorPage' 
import Contact from '../dashboard/Contact' 
import ResetPasswortEmail from '../resetpassword/ResetPasswortEmail'
import ResetPasswordNewPassword from '../resetpassword/ResetPasswordNewPassword' 
import ResetPasswordOTP from '../resetpassword/ResetPasswordOTP'   
import ResetPasswordPage from '../inresetpassword/ResetPasswordPage'  
import ResetPasswordSuccess from '../inresetpassword/ResetPasswordSuccess' 
import TopUpCard from '../dashboard/TopUpCard'   
import Instruction from '../dashboard/Instructions'  
import WelcomeReferral from '../dashboard/WelcomeReferral' 
import ReferredUsersList from '../dashboard/ReferredUsersList'  
import SecureYourAccount from '../dashboard/SecureYourAccount' 
const ForgotPassword = lazy(() => import('../jsx/pages/ForgotPassword'));

const allroutes = [
    /// Dashboard
    // { url: '', component: <Home/> },
    { url: 'bindgoogleauthentication', component: <SecureYourAccount/>, loader: async () => {                
      try {
        if(localStorage.getItem('u_t_p')) {
          const decoded_payload = JSON.parse(atob(localStorage.getItem('u_t_p'))) 
          if(decoded_payload['exp']*1000 < new Date().valueOf()) {             
            if(await refreshTokenHandler() === 'REDIRECT_TO_LOGIN') {
              return redirect('/login')  
            }
          }
          
          if(decoded_payload?.loginType === 'google') {
            return redirect('/profile') 
          } else {
            
            let fetch_login_type = {
              api_call: await getLoginType()
            }
            const renewLT = async ()=>{
                return await getLoginType()
            } 
            // If fetch returns 401 
            if(fetch_login_type.api_call.status === 401) {
              if(await refreshTokenWithAPICallHandler(fetch_login_type, renewLT) === 'REDIRECT_TO_LOGIN') {
                return redirect('/login')  
              }
            }
            const result_fetch_login_type = await fetch_login_type.api_call.json() 

            if(fetch_login_type.api_call.ok && (result_fetch_login_type.message === 'successfully')) { 
              if(result_fetch_login_type?.data?.loginType === 'google') {
                return redirect('/profile') 
              } else {
                                  
                  let fetch_secret_key = {
                    api_call: await getSecretKey()
                  }
                  const renewSK = async ()=>{
                    return await getSecretKey()
                  }
                  // If fetch returns 401 
                  if(fetch_secret_key.api_call.status === 401) {
                    if(await refreshTokenWithAPICallHandler(fetch_secret_key, renewSK) === 'REDIRECT_TO_LOGIN') {
                      return redirect('/login')  
                    }
                  }
                  const result_fetch_secret_key = await fetch_secret_key.api_call.json() 

                  if(result_fetch_secret_key?.data?.secret && result_fetch_secret_key?.data?.qrCodeUrl) {
                    return { secretKey: result_fetch_secret_key?.data?.secret, qrCodeUrl: result_fetch_secret_key?.data?.qrCodeUrl} 
                  }
                
              } 
            }
          }
          
          return null  
        } else {
          return redirect('/login') 
        }
      } catch(err) {
      }
    } 
  },    
    { url: '/referredusers', component: <ReferredUsersList/>, loader: async () => {
      try {
        if(localStorage.getItem('u_t_p')) {
          const decoded_payload = JSON.parse(atob(localStorage.getItem('u_t_p'))) 
          if(decoded_payload['exp']*1000 < new Date().valueOf()) { 
            if(await refreshTokenHandler() === 'REDIRECT_TO_LOGIN') {
              return redirect('/login')  
            }
          }

          if(decoded_payload['req'] !== 'S') {
            let fetch_card_request = {
              api_call: await findRequestCard()
            }
            const renewFRC = async ()=>{
              return await findRequestCard()
            }
            // If fetch returns 401
            if(fetch_card_request.api_call.status === 401) {
              if(await refreshTokenWithAPICallHandler(fetch_card_request, renewFRC) === 'REDIRECT_TO_LOGIN') {
                return redirect('/login')  
              }
            }
            const result_fetch_card_request = await fetch_card_request.api_call.json() 

            if(result_fetch_card_request['data'].length > 0) {
              if(result_fetch_card_request['data'][0]['requesteStatus'] === 'Success') {
                return null
              } else {
                return redirect('/dashboard')  
              }
            } else {
              return redirect('/dashboard') 
            }
          }

          return null 

        } else {
          return redirect('/login') 
        }
      } catch (err) {

      }
    }}, 
    { url: '/welcomereferral', component: <WelcomeReferral/>, loader: async ()=> {
        try {
          if(localStorage.getItem('u_t_p')) {
            const decoded_payload = JSON.parse(atob(localStorage.getItem('u_t_p'))) 
            if(decoded_payload['exp']*1000 < new Date().valueOf()) {  
              if(await refreshTokenHandler() === 'REDIRECT_TO_LOGIN') {
                return redirect('/login')  
              }
            }

            if(decoded_payload['req'] !== 'S') {
              let fetch_card_request = {
                api_call: await findRequestCard()
              }
              const renewFCR = async ()=>{
                return await findRequestCard()
              }
              // If fetch returns 401
              if(fetch_card_request.api_call.status === 401) {
                if(await refreshTokenWithAPICallHandler(fetch_card_request, renewFCR) === 'REDIRECT_TO_LOGIN') {
                  return redirect('/login')  
                }
              }
              const result_fetch_card_request = await fetch_card_request.api_call.json() 

              if(result_fetch_card_request['data'].length > 0) {
                if(result_fetch_card_request['data'][0]['requesteStatus'] !== 'Success') {
                  return redirect('/dashboard')  
                }
              } else {
                return redirect('/dashboard') 
              }
            }

            let fetch_all_referral_codes = {
              api_call: await getReferralCode()
            }
            const renewARC = async ()=>{
              return await getReferralCode()
            }
            // If fetch returns 401
            if(fetch_all_referral_codes.api_call.status === 401) {
              if(await refreshTokenWithAPICallHandler(fetch_all_referral_codes, renewARC) === 'REDIRECT_TO_LOGIN') {
                return redirect('/login')  
              }
            }
            const result_fetch_all_referral_codes = await fetch_all_referral_codes.api_call.json()  
             
            if(result_fetch_all_referral_codes['data'].length > 0) { 
              return {code: result_fetch_all_referral_codes['data'][0]['referralCode'], expiration_date: result_fetch_all_referral_codes['data'][0]['expireAt']['date']}  
            } else {
              return {code: null}    
            }
            return null 
          } else {
            return redirect('/login') 
          }
        } catch(err) {

        }
    } },     
    { url: 'userguide', component: <Instruction/> , loader: async ()=> {
       
      try {
        if(localStorage.getItem('u_t_p')) {
          const decoded_payload = JSON.parse(atob(localStorage.getItem('u_t_p'))) 

          if(decoded_payload['exp']*1000 < new Date().valueOf()) { 
            if(await refreshTokenHandler() === 'REDIRECT_TO_LOGIN') {
              return redirect('/login')  
            }
          }

          return null 
        } else {
          return redirect('/login') 
        }
      } catch(err) {

      }
    } },    
    { url: 'topupcard', component: <TopUpCard />, loader: async ()=> {
    
      try {
        if(localStorage.getItem('u_t_p')) {
          const decoded_payload = JSON.parse(atob(localStorage.getItem('u_t_p'))) 

          if(decoded_payload['exp']*1000 < new Date().valueOf()) {
            if(await refreshTokenHandler() === 'REDIRECT_TO_LOGIN') {
              return redirect('/login')  
            }
          }

          if(decoded_payload['req'] === 'S') {
            return null  
          }

          let resultFindRequest = {
            api_call: await findRequestCard()
          }
          const renewFRC = async ()=>{
            return await findRequestCard()
          }
          // If fetch returns 401 
          if(resultFindRequest.api_call.status === 401) {
            if(await refreshTokenWithAPICallHandler(resultFindRequest, renewFRC) === 'REDIRECT_TO_LOGIN') {
              return redirect('/login')  
            }
          } 
          const requestCardStatus = await resultFindRequest.api_call.json()

          if(requestCardStatus.data.length > 0) {
            if(requestCardStatus.data[0].requesteStatus === 'Success') {
              return null 
            } else if(requestCardStatus.data[0].requesteStatus === 'Pending') {
              return redirect('/thankyouforcardorder') 
            }
          } else {
            return redirect('/ordercard') 
          }
        } else {
          return redirect('/login') 
        }
      } catch(err) {

      }
   }},    
    { url: 'successresetpassword', component: <ResetPasswordSuccess />, loader: async ()=> {
       
      try {
        if(localStorage.getItem('u_t_p')) {
          const decoded_payload = JSON.parse(atob(localStorage.getItem('u_t_p'))) 
          if(decoded_payload['exp']*1000 < new Date().valueOf()) {  
            if(await refreshTokenHandler() === 'REDIRECT_TO_LOGIN') {
              return redirect('/login')  
            }
          }

          return null 
        } else {
          return redirect('/login') 
        }
      } catch(err) {

      }
    }}, 
    { url: 'loggedresetpassword', component: <ResetPasswordPage />, loader: async ()=> {
      
      try {
        if(localStorage.getItem('u_t_p')) {
          const decoded_payload = JSON.parse(atob(localStorage.getItem('u_t_p'))) 
          if(decoded_payload['exp']*1000 < new Date().valueOf()) {
            if(await refreshTokenHandler() === 'REDIRECT_TO_LOGIN') {
              return redirect('/login')  
            }
          }

          return null
        } else {
          return redirect('/login') 
        }
      } catch(err) {

      }
    }}, 
    { url: 'contactus', component: <Contact />, loader: async ()=> {
      
      try {
        if(localStorage.getItem('u_t_p')) { 
          const decoded_payload = JSON.parse(atob(localStorage.getItem('u_t_p'))) 
          
          if(decoded_payload['exp']*1000 < new Date().valueOf()) {
              if(await refreshTokenHandler() === 'REDIRECT_TO_LOGIN') {
                return redirect('/login')  
              } 
          } 

          return null
        } else {
          return redirect('/login') 
        }
      } catch(err) {

      }
    }}, 
    { url: 'thankyouforcardorder', component: <ThankYouOrderCard />, loader: async ()=> {
       
      try {
        if(localStorage.getItem('u_t_p')) { 
          const decoded_payload = JSON.parse(atob(localStorage.getItem('u_t_p'))) 
          if(decoded_payload['exp']*1000 < new Date().valueOf()) {
              if(await refreshTokenHandler() === 'REDIRECT_TO_LOGIN') {
                return redirect('/login')  
              }
          } 
          if(decoded_payload['req'] === 'S') {
            return redirect('/mycard')
          } 
          let resultFindRequest = {
            api_call: await findRequestCard() 
          }
          // If fetch returns 401
          if(resultFindRequest.api_call.status === 401) {
            const renewFCR = async ()=>{
              return await findRequestCard()
            }
            if(await refreshTokenWithAPICallHandler(resultFindRequest, renewFCR) === 'REDIRECT_TO_LOGIN') {
              return redirect('/login')  
            }
          }
          const requestCardStatus = await resultFindRequest.api_call.json()

          if(requestCardStatus.data.length === 0) {
            return null
          } else if(requestCardStatus.data.length === 1) {
            if(requestCardStatus.data[0].requesteStatus === 'Pending') {
              return null
            } else if(requestCardStatus.data[0]['requesteStatus'] === 'Success') {
              return redirect('/mycard')  
            }
          }

          return null
        } else {
          return redirect('/login') 
        }
      } catch(err) {

      }
    }}, 
    { url: 'ordercard', component: <OrderCard />, loader: async ()=>{
      
        try {
          if(localStorage.getItem('u_t_p')) {
            const decoded_payload = JSON.parse(atob(localStorage.getItem('u_t_p'))) 
            if(decoded_payload['exp']*1000 < new Date().valueOf()) { 
              if(await refreshTokenHandler() === 'REDIRECT_TO_LOGIN') {
                return redirect('/login')  
              }
            }

            if(decoded_payload['req'] === 'S') {
              return redirect('/mycard')
            }
            
            let resultFindRequest = {
              api_call: await findRequestCard()
            }
            const renewFCR = async ()=>{
              return await findRequestCard()
            }
            if(resultFindRequest.api_call.status === 401) {
              if(await refreshTokenWithAPICallHandler(resultFindRequest, renewFCR) === 'REDIRECT_TO_LOGIN') {
                return redirect('/login')  
              }
            }
            const requestCardStatus = await resultFindRequest.api_call.json()

            if(requestCardStatus.data.length === 0) {
              return null 
              
            } else if(requestCardStatus.data.length >= 1) { 
              if(requestCardStatus.data[0].requesteStatus === 'Pending') {
                return redirect('/thankyouforcardorder') 
              } else if(requestCardStatus.data[0].requesteStatus === 'Success') {
                return redirect('/mycard') 
              }
              return {status: requestCardStatus.data[0].requesteStatus}

            } else {
              return null
            } 
            } else {
              return redirect('/login')
            }
        } catch(err) {

        }
        }
    },
    { url: 'profile', component: <MyInfo/>  , loader: async ()=>{

      try {
        if(localStorage.getItem('u_t_p')) {  
          const decoded_payload = JSON.parse(atob(localStorage.getItem('u_t_p'))) 

          if(decoded_payload['exp']*1000 < new Date().valueOf()) { 
              if(await refreshTokenHandler() === 'REDIRECT_TO_LOGIN') {
                return redirect('/login')  
              }
          }

          if(decoded_payload['req'] === 'S') {
            return null
          } 

          let resultFindRequest = {
            api_call: await findRequestCard() 
          }
          const renewFCR = async ()=>{
            return await findRequestCard()
          }
          // If fetch returns 401
          if(resultFindRequest.api_call.status === 401) {
            if(await refreshTokenWithAPICallHandler(resultFindRequest, renewFCR) === 'REDIRECT_TO_LOGIN') {
              return redirect('/login')  
            }
          }
          const requestCardStatus = await resultFindRequest.api_call.json()

          if(requestCardStatus.data.length === 0) {
            return redirect('/ordercard')
          } else if(requestCardStatus.data.length === 1) {
            if(requestCardStatus.data[0].requesteStatus === 'Pending') {
              return redirect('/thankyouforcardorder') 
            } else if(requestCardStatus.data[0]['requesteStatus'] === 'Success') {
              return null 
            }
          }
          return null
        } else {
          return redirect('/login')
        } 
      } catch(err) {

      }
       
    }},
    { url: 'mycard', component: <MyCard />, loader:  async ()=>{
      
      try {
        if(localStorage.getItem('u_t_p')) {
          const decoded_payload = JSON.parse(atob(localStorage.getItem('u_t_p')))  
          if(decoded_payload['exp']*1000 < new Date().valueOf()) {
              if(await refreshTokenHandler() === 'REDIRECT_TO_LOGIN') {
                return redirect('/login')  
              } 
          }

          if(decoded_payload['req'] === 'S') {
            return null  
          }

          let resultFindRequest = {
            api_call: await findRequestCard()
          }
          const renewFRC = async ()=>{
            return await findRequestCard()
          }
          // If fetch returns 401 
          if(resultFindRequest.api_call.status === 401) {
            if(await refreshTokenWithAPICallHandler(resultFindRequest, renewFRC) === 'REDIRECT_TO_LOGIN') {
              return redirect('/login')  
            }
          } 
          const requestCardStatus = await resultFindRequest.api_call.json() 

          if(requestCardStatus.data.length > 0) {
            if(requestCardStatus.data[0].requesteStatus === 'Success') {
              return null 
            } else if(requestCardStatus.data[0].requesteStatus === 'Pending') {
              return redirect('/thankyouforcardorder') 
            }
          } else {
            return redirect('/ordercard') 
          }
        } else {
          return redirect('/login') 
        }
          return null 
        
      } catch(err) {

      }}} 
  ]
  
  export const router = createBrowserRouter(createRoutesFromElements(
  <>
    <Route path='/' element={<Lplayout />} >
      <Route index element={<LpBody />} />
    </Route>

    <Route path='/login' element={<Login />} />
    <Route path='/register' element={<Registration />} />  
    <Route path='/resetpassword' element={<ResetPasswortEmail />} /> 
    <Route path='/newpassword' element={<ResetPasswordNewPassword />} /> 
    <Route path='/resetpasswordverification' element={<ResetPasswordOTP />} /> 

    <Route  element={<MainLayout />} > 
        {allroutes.map((data, i) => (
          <Route
            key={i}
            exact
            path={`${data.url}`}
            element={data.component}
            loader={data.loader}
            errorElement={data.errorElement} 
          />
        ))}
    </Route>
  </>))

