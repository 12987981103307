import React from 'react'
import './CSS/confirmpasswordchange.css' 
import { useToggle } from '../context/ToggleContext'
import Modal from 'react-bootstrap/Modal'
import { Formik, Form, Field } from 'formik'
import TextInput from '../components/TextInput'
import * as YUP from 'yup'
import Button from '../components/Button' 
import { convertToUSDT, requestTopUp } from '../security/AuthService' 
const ConfirmPasswordError = ({show, handleCloseError}) => {
    return (
        <Modal show={show}  className='topup-modal confirmPasswordChange' backdrop='static' keyboard='false' > 
            <div className='errorConfirmPasswordChangeWrapper'> 
                <figure>
                    <img src='/images/registration/tabler_alert-circle.svg' 
                    alt='red alert' 
                    style={{display: 'block', margin: 'auto', maxWidth: '150px'}}  
                    />  
                </figure>
                <div className='errorTitle'>Error</div>
                <p className='errorDescription'>The current password was incorrect!</p> 
                
                <Button btnType='submit' id='tryAgainChangePassword' click={handleCloseError}>Try Again!</Button>   
            </div>
                                    
                                    
       </Modal>
    )
}

export default ConfirmPasswordError