import React from 'react'
import ScrollAnimation from 'react-animate-on-scroll';
import './CSS/securetrusted.css'

const SecureTrusted = () => {
  return (
    <>
        <section id='securetrusted'>
            <div className='secure-trusted-container'>
                <div className='secure-trusted-title-mobile'>Secure & Trusted</div>
                <figure className=''>
                    <img src='/images/landingpage/body/Group 65.svg' alt='secure trusted' draggable='false' />
                </figure>
                <div className='secure-trusted-wrapper'>
                    <ScrollAnimation animateIn="fadeInRight">
                        <h2 className='secure-trusted-title'>Secure & Trusted</h2>
                        <p className='secure-trusted-description'>Mastercard and PAYTAPS protect your payments no matter what you purchase in-person or online.</p>
                        <div className='security-features-text'>Security Features:</div>
                        <ul className='security-features'>
                            <li>
                                Supported by Mastercard security & fraud prevention
                            </li>
                            <li>
                                Easily block and unblock your card with a tap
                            </li>
                            <li>
                                Additional 3DS protection for enhanced security
                            </li>
                        </ul>
                    </ScrollAnimation>
                </div>
            </div>
            
        </section>
        <section>
            <div className='real-time-container'>
                <div className='real-time-content'>
                    <ScrollAnimation animateIn="fadeInLeft">
                        <h2 className='real-time-title'>
                            Real-Time Spending Alerts
                        </h2>
                        <p className='real-time-description'>
                            With your PAYTAPS dashboard, you can easily keep an eye on your  spending by checking your transaction history. Get immediate alerts for each  transaction you complete.
                        </p>
                    </ScrollAnimation>
                </div>
                <figure >
                    <img src='/images/landingpage/body/macbook-air-mockup-paytaps 1 (2).png' alt='macbook' draggable='false' />            
                </figure>
            </div>
        </section>
    </>
  )
}

export default SecureTrusted