import React, { useEffect } from 'react'
import LpHeader from './LPHeader/LpHeader'
import LpBody from './LPBody/LpBody'
import LpFooter from './LPFooter/LpFooter'
import SEO from '../components/SEO'  
import './lplayout.css'
 
const Lplayout = () => {
  useEffect(()=>{
    document.body.style.background = 'var(--bs-body-bg) !important' 
    return ()=>{
      document.body.removeAttribute('style') 
    }
  }, [])
  
  return (
    <>

      <SEO title={'PayTaps - Your Crypto Payment Solution'} 
           description={'PayTaps Crypto MasterCard: Free, secure virtual card. Works with Apple Pay, Samsung Pay, and Google Pay. Spend crypto as fiat globally, hassle-free.'} 
           ogTitle={'PayTaps Crypto MasterCard – Spend Crypto With A Simple Tap'} 
           ogDescription={'PayTaps Crypto MasterCard: Free, secure virtual card. Works with Apple Pay, Samsung Pay, and Google Pay. Spend crypto as fiat globally, hassle-free.'} 
           ogUrl={'https://paytaps.com'}  
           twitterTitle={'PayTaps Crypto MasterCard – Spend Crypto With A Simple Tap'} 
           twitterDescription={'PayTaps Crypto MasterCard: Free, secure virtual card. Works with Apple Pay, Samsung Pay, and Google Pay. Spend crypto as fiat globally, hassle-free.'} 
           canonicalLink={'https://paytaps.com'}   
      />  
      <LpHeader />
      <main>
          <LpBody />
      </main>
      <LpFooter />
    </>
  )
}

export default Lplayout