import React, { useState, useEffect, useRef, useCallback } from 'react'
import './CSS/mycard.css'
import { FigureCaption } from 'react-bootstrap'
import BalanceDataDetail from '../components/BalanceDataDetail'
import Button from '../components/Button'
import { useToggle } from '../context/ToggleContext'
import TransactionsVisulizer from '../components/TransactionsVisulizer'
import Topup from '../Modals/Topup'
import InsufficientBalance_top_up from '../Modals/InsufficientBalance_top_up' 
import ShowDetails from '../Modals/ShowDetails' 
import VerifyCardInfo from '../Modals/VerifyCardInfo'
import { useNavigate } from 'react-router-dom'  
import { userBalance, getCardBalance, getCardPreviousTransactions, getCardTopupHistory, refreshToken, killToken, saveToken, convertToUSDT, getCardTransactions } from '../security/AuthService'   
import Loader from '../jsx/pages/Loader/Loader'  
import TopUpVisualizer from '../components/TopUpVisualizer' 
import ApproveCard from '../Modals/ApproveCard' 
import TopupSuccess from '../Modals/TopupSuccess' 
import moment from 'moment';   

const MyCard = () => {
    const navigate = useNavigate() 
    const initialRender = useRef(true);
    const activeFilterPrevTrx = useRef(null)  
    const activeFilterTopUp = useRef(null)   
    const { toggle } = useToggle()
    const [show, setShow] = useState(false)
    const [balanceCard, setBalanceCard] = useState('')
    const [balanceWallet, setBalanceWallet] = useState('') 
    const [previousTrx, setPreviousTrx] = useState({data: []}) 
    const [topupHistory, setTopupHistory] = useState({data: []}) 
    const [filteredPreviousTrx, setFilteredPreviousTrx] = useState({data: []}) 
    const [filteredTopupHistory, setFilteredTopupHistory] = useState({data: []})   
    const [showdetails, setShowDetails] = useState(false)
    const [showcarddetails, setShowcardDetails] = useState(false) 
    const [showInsufficientTopupBalance, setShowInsufficientTopupBalance] = useState(false)  
    const [error, setError] = useState('') 
    const [cardHolderDataLoadingState, setCardHolderDataLoadingState] = useState(true)  
    const [showApproveCard, setShowApproveCard] = useState(false) 
    const [balanceEUR, setBalanceEUR] = useState(0)  
    const [showSuccess, setShowSuccess] = useState(false)                                                                                   
    const refAllow = useRef(false) 
    const handleClose = () => {setShow(false)} 
    const handleShow = () => setShow(true);
    const [show1, setShow1] = useState(false)
    const handleClose1 = () => setShow1(false);
    const handleShow1 = () => setShow1(true);
    const handleCloseDetails = () => setShowDetails(false);
    const handleShowDetails = () => setShowDetails(true);  
    const handleClosecardDetails = () => setShowcardDetails(false);
    const handleShowcardDetails = () => setShowcardDetails(true);
    const handleCloseInsufTopupBalance = () => setShowInsufficientTopupBalance(false); 
    const handleShowInsufTopupBalance = () => setShowInsufficientTopupBalance(true);                                        
    const handleRefAllowTrue = ()=>  refAllow.current = true  
    const handleRefAllowFalse = ()=>  refAllow.current = false    
    const handleShowApproveCard = useCallback(function () {
        setShowApproveCard(true)
    }, [])
    const handleCloseApproveCard = useCallback(function () {
        setShowApproveCard(false) 
    }, [])  
     
    const loading = useRef(false)

    const handleCloseTopupSuccess = useCallback(function () {
        setShowSuccess(false) 
    }, [])
    const handleShowTopupSuccess = useCallback(function () {
        setShowSuccess(true)
    }, [])  
    const [activeRerender, setActiveRerender] = useState(false) 
     
    useEffect(()=>{
         
        let ignore = false;
        getAllCardHolderDetails(ignore)  

    }, [activeRerender])                       

    const updateBalance = useCallback(function (newBalance) {
        setBalanceWallet(newBalance) 
        setActiveRerender(activeRerender => !activeRerender)  
    }, [activeRerender])           


    function activeFilterPrevTrxHandler (e) {

        if((e.target.innerHTML !== 'Current Month' && e.target.innerHTML !== 'Current Week') && (e.target.innerHTML !== 'Today')) {
            activeFilterPrevTrx.current = null 
        } else {
            activeFilterPrevTrx.current = e.target.innerHTML  
        }  
        handleFilterPrevTrx()

    }
    function activeFilterTopUpHandler (e) {

        if((e.target.innerHTML !== 'Current Month' && e.target.innerHTML !== 'Current Week') && (e.target.innerHTML !== 'Today')) {
            activeFilterTopUp.current = null 
        } else {
            activeFilterTopUp.current = e.target.innerHTML  
        }
        handleFilterTopupHistory() 

    } 

    function topupClickHandler () {
        handleShow()
    }
    
    async function getAllCardHolderDetails(ignore) {
        try {
            
            setCardHolderDataLoadingState(true)
            const result_balance_wallet = await userBalance() 
             
            // If fetch returns 401 
            // if(result_balance_wallet.status === 401) {
            //     const refreshTokenResult = await refreshToken({refresh_token: localStorage.getItem('u_r_t')})

            //     // If refresh token returns 401
            //     if(refreshTokenResult.status === 401) {
            //       killToken()
            //       return navigate('/login')
            //     } 

            //     // If refresh token is successfull 
            //     const payloadRefreshToken = await refreshTokenResult.json()   

            //     let tokenPayload = payloadRefreshToken.token.split('.')[1] 

            //     // Payload of new generated token 
            //     let new_decoded_payload = JSON.parse(atob(tokenPayload)) 

            //     if(new_decoded_payload['exp']*1000 < new Date().valueOf())  {
            //       killToken()
            //       return navigate('/login') 
            //     } else {
            //       saveToken(payloadRefreshToken.token, payloadRefreshToken.refresh_token) 
            //       result_balance_wallet = await userBalance()  
            //     }

            //   }

            const wallet_balance = await result_balance_wallet.json() 

            

            // console.log(typeof result_balance_wallet.status)     
            //console.log(wallet_balance['data'][0]['balance']) 
            const result_card_balance = await getCardBalance()
            // If fetch returns 401 
            // if(result_card_balance.status === 401) {
            //     const refreshTokenResult = await refreshToken({refresh_token: localStorage.getItem('u_r_t')})

            //     // If refresh token returns 401
            //     if(refreshTokenResult.status === 401) {
            //       killToken()
            //       return navigate('/login')
            //     } 

            //     // If refresh token is successfull 
            //     const payloadRefreshToken = await refreshTokenResult.json()   

            //     let tokenPayload = payloadRefreshToken.token.split('.')[1] 

            //     // Payload of new generated token 
            //     let new_decoded_payload = JSON.parse(atob(tokenPayload)) 

            //     if(new_decoded_payload['exp']*1000 < new Date().valueOf())  {
            //       killToken()
            //       return navigate('/login')  
            //     } else {
            //       saveToken(payloadRefreshToken.token, payloadRefreshToken.refresh_token) 
            //       result_card_balance = await getCardBalance()  
            //     }

            //   }

            const card_balance = await result_card_balance.json() 
            //console.log(card_balance['data']['balance'])   
            // getCardTransactions 
            // let result_card_previous_trx = await getCardPreviousTransactions()
            // // If fetch returns 401 
            // if(result_card_previous_trx.status === 401) {
            //     const refreshTokenResult = await refreshToken({refresh_token: localStorage.getItem('u_r_t')})

            //     // If refresh token returns 401
            //     if(refreshTokenResult.status === 401) {
            //       killToken()
            //       return navigate('/login')
            //     } 

            //     // If refresh token is successfull 
            //     const payloadRefreshToken = await refreshTokenResult.json()   

            //     let tokenPayload = payloadRefreshToken.token.split('.')[1] 

            //     // Payload of new generated token 
            //     let new_decoded_payload = JSON.parse(atob(tokenPayload)) 

            //     if(new_decoded_payload['exp']*1000 < new Date().valueOf())  {
            //       killToken()
            //       return navigate('/login') 
            //     } else {
            //       saveToken(payloadRefreshToken.token, payloadRefreshToken.refresh_token) 
            //       result_card_previous_trx = await getCardPreviousTransactions()  
            //     }

            //   }

            // const card_previous_trx = await result_card_previous_trx.json() 

            const result_card_trx = await getCardTransactions()
            // If fetch returns 401 
            // if(result_card_trx.status === 401) {
            //     const refreshTokenResult = await refreshToken({refresh_token: localStorage.getItem('u_r_t')})

            //     // If refresh token returns 401
            //     if(refreshTokenResult.status === 401) {
            //       killToken()
            //       return navigate('/login')
            //     } 

            //     // If refresh token is successfull 
            //     const payloadRefreshToken = await refreshTokenResult.json()   

            //     let tokenPayload = payloadRefreshToken.token.split('.')[1] 

            //     // Payload of new generated token 
            //     let new_decoded_payload = JSON.parse(atob(tokenPayload)) 

            //     if(new_decoded_payload['exp']*1000 < new Date().valueOf())  {
            //       killToken()
            //       return navigate('/login') 
            //     } else {
            //       saveToken(payloadRefreshToken.token, payloadRefreshToken.refresh_token) 
            //       result_card_trx = await getCardTransactions()   
            //     }

            //   }

            const card_previous_trx = await result_card_trx.json() 

            const result_card_topup_history = await getCardTopupHistory() 
            // If fetch returns 401 
            // if(result_card_topup_history.status === 401) {
            //     const refreshTokenResult = await refreshToken({refresh_token: localStorage.getItem('u_r_t')})

            //     // If refresh token returns 401
            //     if(refreshTokenResult.status === 401) {
            //       killToken()
            //       return navigate('/login')
            //     } 

            //     // If refresh token is successfull 
            //     const payloadRefreshToken = await refreshTokenResult.json()   

            //     let tokenPayload = payloadRefreshToken.token.split('.')[1] 

            //     // Payload of new generated token 
            //     let new_decoded_payload = JSON.parse(atob(tokenPayload)) 

            //     if(new_decoded_payload['exp']*1000 < new Date().valueOf())  {
            //       killToken()
            //       return navigate('/login') 
            //     } else {
            //       saveToken(payloadRefreshToken.token, payloadRefreshToken.refresh_token) 
            //       result_card_topup_history = await getCardTopupHistory()   
            //     }

            //   }

            const card_topup_history = await result_card_topup_history.json() 
             
            const convert = await convertToUSDT({
                amount: wallet_balance['data'][0]['balance'] 
            })
            // console.log({
            //     amount: balance['data'][0]['balance'] 
            // })  

            // const balance_usdt = await convert.json()
            const convert_result = await convert.json()
            // && convert.ok && result_card_topup_history.ok
            if(!ignore) {
                if(result_balance_wallet.ok && result_card_balance.ok && result_card_trx.ok && convert.ok && result_card_topup_history.ok) {                       
                
                    
                    setBalanceWallet(wallet_balance['data'][0]['balance']) 
                    setBalanceCard(card_balance['data']['balance'])
                         
                    setPreviousTrx({...previousTrx, data: card_previous_trx['data'].reverse()}); setFilteredPreviousTrx({...filteredPreviousTrx, data: card_previous_trx['data']})    
                    setTopupHistory({...topupHistory, data: card_topup_history['data']}); 
                    setFilteredTopupHistory({...filteredTopupHistory, data: card_topup_history['data']}) 
                       
                    setCardHolderDataLoadingState(false) 
                     
                    const balance_eur = wallet_balance['data'][0]['balance'] / convert_result['data']['info']['rate']
                    setBalanceEUR(balance_eur) 
                } else {
                    setError('Error') 
                    setCardHolderDataLoadingState(false) 
                    loading.current = false 
                }  
            }  
        } catch(err) {
            setError(err) 
        } finally {
            setCardHolderDataLoadingState(false) 
            loading.current = false 
        }
          
} 
    
    function handleFilterPrevTrx() {
        
            if(activeFilterPrevTrx.current==='Current Month') {
                const filteredData = previousTrx["data"].filter((trx)=> (((moment().month() + 1) === moment(trx['dateOfTransaction']).month() + 1) && ((moment(trx['dateOfTransaction']).year()) === moment().year() )))    
                return  setFilteredPreviousTrx({...filteredPreviousTrx, data: filteredData})
                   
            } else if(activeFilterPrevTrx.current === 'Current Week') {
                const filteredData = previousTrx['data'].filter((trx)=> ((moment(trx['dateOfTransaction']).valueOf()>= moment().startOf('week').valueOf()) && (moment(trx['dateOfTransaction']).valueOf() <= moment().endOf('week').valueOf())))     
                return  setFilteredPreviousTrx({...filteredPreviousTrx, data: filteredData}) 
            } else if(activeFilterPrevTrx.current === 'Today') {
                const filteredData = previousTrx['data'].filter((trx)=> ((moment(trx['dateOfTransaction']).valueOf() >= moment().startOf('day')) && (moment(trx['dateOfTransaction']).valueOf() <= moment().endOf('day'))))   
                return  setFilteredPreviousTrx({...filteredPreviousTrx, data: filteredData})  
            } else {

                return setFilteredPreviousTrx({data: previousTrx['data']})                                                                                                                                                                 
            }  
        
    } 
    function handleFilterTopupHistory() {
        
        if(activeFilterTopUp.current==='Current Month') {
            const filteredData = topupHistory["data"].filter((trx)=> (((moment().month() + 1) === moment(trx?.createdAt?.date).month() + 1) && ((moment(trx?.createdAt?.date).year()) === moment().year() )))    
            return  setFilteredTopupHistory({...filteredTopupHistory, data: filteredData})
               
        } else if(activeFilterTopUp.current === 'Current Week') {
            const filteredData = topupHistory['data'].filter((trx)=> ((moment(trx?.createdAt?.date).valueOf()>= moment().startOf('week').valueOf()) && (moment(trx?.createdAt?.date).valueOf() <= moment().endOf('week').valueOf())))     
            return  setFilteredTopupHistory({...filteredTopupHistory, data: filteredData}) 
        } else if(activeFilterTopUp.current === 'Today') {
            const filteredData = topupHistory['data'].filter((trx)=> ((moment(trx?.createdAt?.date).valueOf() >= moment().startOf('day')) && (moment(trx?.createdAt?.date).valueOf() <= moment().endOf('day'))))  
            return  setFilteredTopupHistory({...filteredTopupHistory, data: filteredData})  
        } else {
            
            return setFilteredTopupHistory({data: topupHistory['data']})  
        } 
    
} 
 
function WalletRedirectButton() { 
    const userAgent = navigator.userAgent.toLowerCase(); 
    if (userAgent.includes("android")) { 
        if (userAgent.includes("samsung")) { 
            window.location.href = "https://www.samsung.com/us/samsung-pay/"; 
        } else { 
            window.location.href = "https://pay.google.com/gp/wallet"; 
        } 
    } else if ( 
        userAgent.includes("iphone") || 
        userAgent.includes("ipad") || 
        userAgent.includes("ipod") 
    ) { 
        alert( 
            'To add your card to Apple Pay:\n\n1. Open the Wallet app on your iPhone.\n2. Tap the "+" icon in the upper right corner.\n3. Follow the instructions to add your card.' 
        ); 
    } else { 
        alert("Device not supported for Google Pay, Samsung Pay, or Apple Pay."); 
    } 
};
  return (
    <>
    {((!cardHolderDataLoadingState) && !error) && <div className='mycard-container'>
        <section className='balance-card-container'>
            <div className='mycard-title'>My Paytaps card</div>
            <div className='mypaytaps-container'>
                <div className='main-balance-wrapper'>
                    <figure className='main-balance-figure-wrapper'>
                        <img src='/images/dashboard/mycard/card.svg' alt='card' draggable='false' />
                        <FigureCaption>Main Balance</FigureCaption>
                    </figure>
                    <div className='balance-details-wrapper'>
                        
                        <BalanceDataDetail imagesrc='/images/dashboard/mycard/tabler_credit-card.svg' 
                            label='Card balance' value={`€${parseFloat(balanceCard).toFixed(2)}`} altText='card balance' 
                        />
                        <BalanceDataDetail imagesrc='/images/dashboard/mycard/mingcute_coin-2-line.svg' 
                            label='Wallet balance' value={`$${parseFloat(balanceWallet).toFixed(2)}`} altText='wallet'  
                        />
                        <Button styles='topup-btn-styles' click={topupClickHandler}>+Top Up</Button>
                        
                    </div>
                    
                </div>
                <div className={`card-wrapper ${toggle ? 'align-items-center' : ''}`} >
                    <figure>
                        <img src='/images/dashboard/mycard/paytaps card.png' alt="card paytaps" />
                        <span className='card-holder-name'>XXXXX XXXXX</span>

                        <span className='card-number'>XXXX XXXX XXXX XXXX</span> 

                        <div className='card-validity'>
                            <span>VALID THRU</span>
                            <span>XX/XX</span> 
                        </div>
                        <Button imagesrc='/images/dashboard/mycard/checked.svg' styles='approvetrx-btn-styles' click={handleShowApproveCard}>Approve Transaction</Button>
                    </figure>
                    <div className='card-config-wrapper'>
                        <Button imagesrc='/images/dashboard/mycard/uil_setting.svg' styles='setting-btn-styles' click={WalletRedirectButton}>Add to Wallet</Button> 
                        <Button imagesrc='/images/dashboard/mycard/tabler_eye.svg' styles='showdetails-btn-styles' click={handleShowDetails}> Show details</Button>
                    </div>
                </div>
            </div>
        </section>
        <section className='history-data-container'>
            <div className='previous-transactions-wrapper'>
                <div className='previous-transactions-wrapper-header myCardPreviousTrxHeader'>
                    <div className='previous-transactions-wrapper-header-text-content'>
                        <span>Previous Transactions</span>
                        {(previousTrx['data'].length > 0) && 
                        <>
                        {(activeFilterPrevTrx.current === 'Current Month') && <span>Current month's transactions</span>}
                        {(activeFilterPrevTrx.current === 'Current Week') && <span>Current Week's transactions</span>}
                        {(activeFilterPrevTrx.current === 'Today') && <span>Today's transactions</span>}
                        {(activeFilterPrevTrx.current === null) && <span>All previous transactions</span>}                      
                        </>}
                    </div>
                    {(previousTrx['data'].length > 0) && <div className='previous-transactions-wrapper-header-filter'>
                        <button className={`${activeFilterPrevTrx.current === 'Current Month' ? 'active-filter' : ''}`} onClick={activeFilterPrevTrxHandler} >Current Month</button>
                        <button className={`${activeFilterPrevTrx.current === 'Current Week' ? 'active-filter' : ''}`} onClick={activeFilterPrevTrxHandler}>Current Week</button>
                        <button className={`${activeFilterPrevTrx.current === 'Today' ? 'active-filter' : ''}`} onClick={activeFilterPrevTrxHandler}>Today</button>                                                                            
                        {((activeFilterPrevTrx.current === 'Current Month' || activeFilterPrevTrx.current === 'Current Week') || activeFilterPrevTrx.current === 'Today') && <button className='clearFilter' onClick={activeFilterPrevTrxHandler}><span>X</span> <span>Clear Filter</span></button>}         
                    </div>}
                </div>
                
            <TransactionsVisulizer data={filteredPreviousTrx['data']} />     

            </div>
            <div className='top-up-history-wrapper'>
                <div className='previous-transactions-wrapper-header myCardTopupHistoryHeader'>
                    <div className='previous-transactions-wrapper-header-text-content'>
                        <span>Top-up History </span>
                        {(topupHistory['data'].length > 0) && 
                        <>
                        {(activeFilterTopUp.current === 'Current Month') && <span>Current month's transactions</span>}
                        {(activeFilterTopUp.current === 'Current Week') && <span>Current Week's transactions</span>}
                        {(activeFilterTopUp.current === 'Today') && <span>Today's transactions</span>}
                        {(activeFilterTopUp.current === null) && <span>All Top-up history</span>}                   
                        </>}
                    </div>
                    {(topupHistory['data'].length > 0) &&<div className='previous-transactions-wrapper-header-filter'>
                        <button className={`${activeFilterTopUp.current === 'Current Month' ? 'active-filter' : ''}`} onClick={activeFilterTopUpHandler} >Current Month</button>
                        <button className={`${activeFilterTopUp.current === 'Current Week' ? 'active-filter' : ''}`} onClick={activeFilterTopUpHandler}>Current Week</button>
                        <button className={`${activeFilterTopUp.current === 'Today' ? 'active-filter' : ''}`} onClick={activeFilterTopUpHandler}>Today</button>                                                                                    
                        {((activeFilterTopUp.current === 'Current Month' ||activeFilterTopUp.current === 'Current Week') || activeFilterTopUp.current === 'Today') && <button className='clearFilter' onClick={activeFilterTopUpHandler}><span>X</span> <span>Clear Filter</span></button>}                                             
                    </div>} 
                </div>
                
                <TopUpVisualizer data={filteredTopupHistory['data']} topup={true} />      

            </div>
            
        </section>
    </div>}
    {((cardHolderDataLoadingState) && !error) && <div className='waitLoadingProfileData'><div>Please wait...</div><Loader /></div>}
    
    
      <Topup show={show} handleClose={handleClose} handleShowConfirmation={handleShow1} handleShowInsufTopupBalance={handleShowInsufTopupBalance} updateBalance={updateBalance} balanceEUR={balanceEUR} balanceUSDT={balanceWallet} handleShowTopupSuccess={handleShowTopupSuccess} />          
      <InsufficientBalance_top_up show={showInsufficientTopupBalance} handleCloseInsufTopupBalance={handleCloseInsufTopupBalance} handleShowInsufTopupBalance={handleShowInsufTopupBalance} />
      <TopupSuccess show={showSuccess} handleClose={handleCloseTopupSuccess} />                                                                                                                                                                                                                   
      <ShowDetails show={showcarddetails}  handleClosecardDetails={handleClosecardDetails} />   
      <VerifyCardInfo show={showdetails} handleClose={handleCloseDetails} a={refAllow.current} handleRefAllowTrue={handleRefAllowTrue} handleRefAllowFalse={handleRefAllowFalse} 
      handleClosecardDetails={handleClosecardDetails} handleShowcardDetails={handleShowcardDetails} />  
      <ApproveCard show={showApproveCard} handleClose={handleCloseApproveCard} />    
  </>
  )
}

export default MyCard


 