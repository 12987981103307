import React from 'react'
import './CSS/benefits.css'

const Benefits = () => {
  return (
    <section id='benefits'>
        <div className='benefits-container'>
            <div className='benefits-title'>BENEFITS SECTION</div>
            <h2 className='benefits-subtitle'>Advantages of PAYTAPS Card</h2>
            <div className='benefits-wrapper'>
              <div className='benefits-wrapper-content'>
                <img src='/images/landingpage/body/0-percent_6895305.svg' alt='benefit 1: no monthly charges' className='benefit-img' draggable='false' />
                <div>
                  <div className='benefit-title'>Zero Monthly Charges</div>
                  <div className='benefit-description'>No costs to buy or utilize it.</div>
                </div>
              </div>
              <div className='benefits-wrapper-content'>
                <img src='/images/landingpage/body/e-wallet_7006667.svg' alt='benefit 2: connection with Google Pay, Samsung Pay and Apple Pay' className='benefit-img' draggable='false' /> 
                <div>
                  <div className='benefit-title'>Integration of Mobile Wallets</div>
                  <div className='benefit-description'>Connect with Google Pay, Samsung Pay, and  Apple Pay.</div>
                </div>
              </div>
              <div className='benefits-wrapper-content'>
                <img src='/images/landingpage/body/Group.svg' alt='benefit 3: available where MasterCard does'  className='benefit-img' draggable='false' />
                <div>
                  <div className='benefit-title'>Worldwide Recognition</div>
                  <div className='benefit-description'>Use your card anywhere MasterCard is accepted.</div>
                </div>
              </div>
              <div className='benefits-wrapper-content'>
                <img src='/images/landingpage/body/coin_6084971 1.svg' alt='benefit 4: low top-up costs'  className='benefit-img' draggable='false' />
                <div>
                  <div className='benefit-title'>Low Costs of Top-Up</div>
                  <div className='benefit-description'>Enjoy affordable transactions with minimal top-up costs.</div>
                </div>
              </div>
            </div>
        </div>
    </section>
  )
}

export default Benefits
