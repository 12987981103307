import React, { useState, useEffect, useCallback } from 'react'
import { useActive } from '../context/ActiveContext'
import { useNavigate, Link } from 'react-router-dom' 

const SignUpDashboard = ({classStyles, linkLabel}) => {
    const [isLogged, setIsLogged] = useState(false)  
    const { handleMenuParentActive } = useActive() 
    const navigate = useNavigate() 
    useEffect(()=>{
        if(localStorage.getItem('u_t_h') && localStorage.getItem('u_t_p') && localStorage.getItem('u_t_s')) {
          setIsLogged(true) 
        }
    }, [isLogged]) 
    const redirectToDashboard = useCallback(function () { 
        navigate('/mycard')
        handleMenuParentActive('My Card', 'Card')   
    }, [])  
  return (
    <>
        {/* If user is not logged in */}
        {!isLogged && <>
            <Link to='/register' className={`${classStyles}`}>{linkLabel}</Link>   
        </>
        } 
        {/* If user is logged in */}
        {isLogged &&  <>
                <span className={`${classStyles}`} style={{cursor: 'pointer'}} onClick={redirectToDashboard}>Dashboard</span>        
            </>
        } 
    </>
  )
}

export default SignUpDashboard                                                                                                                