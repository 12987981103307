import React, { useEffect } from 'react' 
import { useLocation  } from 'react-router-dom' 
import { MenuList } from '../jsx/layouts/nav/Menu' 
import { useActive } from '../context/ActiveContext'
const ActiveSection = () => {
    const location = useLocation()
    const { state, handleMenuActive } = useActive()

  //   function activeLink () {
  //     const newList =  MenuList.flatMap((item)=>{return item?.content ? item.content : item }) 

  //       let index_active_link = newList.findIndex(menulink=> (menulink.to===location.pathname)) 
  //       if(index_active_link === -1) {
  //         if(location.pathname === '/ordercard') {
  //           return 'My Card' 
  //         } else if(location.pathname === '/referredusers') {
  //           return 'Referral'  
  //         } else if(location.pathname === '/getstarted') {
  //           return 'Dashboard' 
  //         } else {
  //           return '' 
  //         }
  //       } else {
  //         // return MenuList[index_active_link].title
  //         console.log(MenuList)
  //     console.log(newList[index_active_link].title) 
  //     MenuList.findIndex(function(item) {
  //       if(item.title === newList[index_active_link].title) {
  //         return newList[index_active_link].title
  //       } else  {
  //         // const fetchParentIndex = item?.content.findIndex((subItem)=> subItem.title === newList[index_active_link].title)
  //         // console.log(fetchParentIndex) 
  //         // console.log(item?.content?.findIndex((subItem)=> subItem.title === newList[index_active_link].title)) 
  //         const i = item?.content?.findIndex((subItem)=> subItem.title === newList[index_active_link].title) 
  //         console.log(i) 
  //         if(i >= 0) {
  //           console.log(item?.content[i], i, item?.content[i]['id'])   
  //           console.log(document?.querySelectorAll('a#' + item?.content[i]['id'] + '[hidden=true]'))   
  //           console.log('a#' + item?.content[i]['id'] + '[hidden=true]')   
  //           console.log(document.getElementById(item?.content[i]['id'])) 
  //           console.log(document.querySelector(`#menu > li > button + ul.show > li > a#${item?.content[i]['id']}`))
             
  //         }
  //       }
  //     })
  //   }
  // } 

    // useEffect(()=>{
    //     // if(state.active === activeLink()) {

    //     // } else {
    //     //     handleMenuActive(activeLink())
    //     //     handleMenuActive(activeLink())
    //     // }
    //     activeLink()
    // }, [location.pathname]) 
    // console.log(location) 
}

export default ActiveSection

