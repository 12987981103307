import React, { useState, useCallback } from 'react'
import { Modal } from 'react-bootstrap'
import Button from '../components/Button' 
import { cardApproval, refreshToken, killToken, saveToken } from '../security/AuthService'
import { useNavigate } from 'react-router-dom'
import './CSS/approvecard.css'  
const ApproveCard = (props) => { 

    const [responseAPI, setResponseAPI] = useState('')  
    const navigate = useNavigate()  
    const [show, setShow] = useState(true) 
    const handleApproveTransaction = useCallback(async function () {
        try {
            if(responseAPI !== '') {
                setResponseAPI('')
            }
            setShow(false) 
            let approve_card = await cardApproval( 
                {
                    approve_status: "1"
                }
            ) 
            // If fetch returns 401 
            if(approve_card.status === 401) {
                const refreshTokenResult = await refreshToken({refresh_token: localStorage.getItem('u_r_t')})

                // If refresh token returns 401
                if(refreshTokenResult.status === 401) {
                killToken()
                return navigate('/login')
                } 

                // If refresh token is successfull 
                const payloadRefreshToken = await refreshTokenResult.json()   

                let tokenPayload = payloadRefreshToken.token.split('.')[1] 

                // Payload of new generated token 
                let new_decoded_payload = JSON.parse(atob(tokenPayload)) 

                if(new_decoded_payload['exp']*1000 < new Date().valueOf())  {
                    killToken()
                    return navigate('/login') 
                } else {
                    saveToken(payloadRefreshToken.token, payloadRefreshToken.refresh_token)
                    approve_card = await cardApproval( 
                        {
                            approve_status: "1"
                        }
                    )
                }

            }

            const result_approve_card = await approve_card.json() 
            setResponseAPI(result_approve_card.message) 
        } catch(err) {

        }
    }, [responseAPI])

    const handleDeclineTransaction = useCallback( async function () { 
        try {
            if(responseAPI !== '') {
                setResponseAPI('')
            }  
            setShow(false) 
            let decline_card = await cardApproval( 
                {
                    approve_status: "2"
                }
            ) 
            // If fetch returns 401 
            if(decline_card.status === 401) {
                const refreshTokenResult = await refreshToken({refresh_token: localStorage.getItem('u_r_t')})

                // If refresh token returns 401
                if(refreshTokenResult.status === 401) {
                killToken()
                return navigate('/login')
                } 

                // If refresh token is successfull 
                const payloadRefreshToken = await refreshTokenResult.json()   

                let tokenPayload = payloadRefreshToken.token.split('.')[1] 

                // Payload of new generated token 
                let new_decoded_payload = JSON.parse(atob(tokenPayload)) 

                if(new_decoded_payload['exp']*1000 < new Date().valueOf())  {
                    killToken()
                    return navigate('/login') 
                } else {
                    saveToken(payloadRefreshToken.token, payloadRefreshToken.refresh_token)
                    decline_card = await cardApproval( 
                        {
                            approve_status: "2"
                        }
                    )
                }

            } 
             
            const result_decline_card = await decline_card.json() 
            setResponseAPI(result_decline_card.message)  
        } catch(err) {

        } 
    }, [responseAPI])    

    const handleCancelApproveTransaction = useCallback( async function () {
        setResponseAPI('')
        props.handleClose() 
        await new Promise((resolve, reject)=>{
        setTimeout(()=>{
            resolve(setShow(true)) 
        }, 500)
    })
    }, [])  
      
    return (
        <Modal show={props.show} onHide={props.handleClose} className='cardApproval' backdrop='static' keyboard='false'>
            <div>
                <figure>
                    <img src='/images/dashboard/mycard/E-Wallet-pana 1.svg' alt='wallet with crypto coins and fiat money' draggable='false' />
                </figure>
                <div className='transactionCheckoutApprovalTitle'>Transaction checkout approval</div>
                {responseAPI && <div>{responseAPI}</div>}
            </div>
            <div className='group-btn'>
                
                
                {show && <>
                            <Button click={handleDeclineTransaction} styles='declineTransaction'>Decline Transaction</Button>
                            <Button click={handleApproveTransaction} styles='approveTransaction'>Approve Transaction</Button>
                        </>
                } 
                <Button click={handleCancelApproveTransaction} styles='cancelApproveTransaction'>Close</Button> 
                
            </div>
        </Modal> 
      )
}

export default ApproveCard

 