import React from 'react'
import { countries } from '../utils/utils'

const ProfileChunkData = ({imagesrc, imagealt, detailLabel, detailValue}) => {
  
  function findCountryName () {
    for (const country of countries) {
      if(country['abbreviation'] === detailValue) {
        return country['fullName'] 
      }
    }
  }

  return (
    <div className='profile-data-item'>
        <img src={imagesrc} alt={imagealt} />
        <div className='profile-data-item-values-wrapper'>
            <span>{detailLabel}</span>
            <span>{(detailLabel === 'Nationality' || detailLabel === 'Country of residence' || detailLabel === 'Billing Country') ? findCountryName() : detailValue}</span> 
        </div>
    </div>
  )
}

export default ProfileChunkData


