import React, { useCallback } from 'react'
import './CSS/topupsuccess.css' 
import { Modal } from 'react-bootstrap' 
import Button from '../components/Button'
import { useNavigate } from 'react-router-dom' 
import { useActive } from '../context/ActiveContext'

const TopupSuccess = (props) => {
  const navigate = useNavigate()
  const { handleMenuActive, handleMenuParentActive } = useActive() 

  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1]

  const handleOK = useCallback( async function () {
    try {
      if(path === 'topupcard') {
        navigate('/mycard')    
        handleMenuParentActive('My Card', 'Card')  
        handleMenuParentActive('My Card', 'Card') 
      }
      props.handleClose() 
      
      await new Promise((resolve, reject)=>{
        setTimeout(()=>{
            if(props.showTopupResultMsg) {
              resolve(props.showTopupMsgHandler('')) 
            }
        }, 100)
    })
    } catch(err) {

    }
  }, [props.showTopupResultMsg])  

  return (
    <Modal show={props.show} onHide={props.handleClose} className='successfulTopup' backdrop='static' keyboard='false'>
        <div>
            <figure>
                <img src='/images/dashboard/topup/Refund-pana (1) 1.svg' alt='a man sitting in top of a grey fiat wallet, next to it coins with sign of dollar, a phone and ablue card' draggable='false' />
            </figure>
            <div className='sure-to-continue-top-up'>{props.showTopupResultMsg ? 'Your topup is under review' : 'Top up successful'}  </div> 
        </div>
        
        <Button styles= 'try-again-btn okSuccessTopup' click={handleOK}>Ok</Button>               
        
    </Modal> 
  )
}

export default TopupSuccess

